import React, { Component } from "react";
import styled from "styled-components";
import { FastField, FastFieldProps, Formik } from "formik";

import { Button } from "components/button";
import { FormErrors, Label, Input } from "components/form";
import { salesRangeTwoValidationSchema } from "./sales-range-two-validation-schema";
import { Listing } from "store/listing";
import { withTimeTracking, TimeTrackingProps } from "hoc/with-time-tracking";
import { checkIfStatusNeedsUpdating, statusIsMoreAdvanced } from "utils/status-check";

export interface Props {
    className?: string;
    listingID: string;
    totalSalesComparisonTaskTime: number;
    updateListing: (listing: Listing) => void;
    status: Listing["status"];
}

export class SalesRangeTwoForm extends Component<Props & TimeTrackingProps> {
    handleSubmit = (values: { salesValuationTwoMinPrice: number; salesValuationTwoMaxPrice: number }) => {
        const { listingID, updateListing, getElapsedTaskTime, totalSalesComparisonTaskTime, status } = this.props;
        const elapsedTaskTime = getElapsedTaskTime() + totalSalesComparisonTaskTime;
        const salesValuationTwoData: Listing = {
            ID: listingID,
            status: checkIfStatusNeedsUpdating(status, "salesModeration"),
            salesValuationTwoMinPrice: values.salesValuationTwoMinPrice,
            salesValuationTwoMaxPrice: values.salesValuationTwoMaxPrice,
            salesValuationTwoCompletedIn: getElapsedTaskTime() + totalSalesComparisonTaskTime
        };
        statusIsMoreAdvanced(status, "salesModeration")
            ? (salesValuationTwoData.qaSalesValuationTwoCompletedIn = elapsedTaskTime)
            : (salesValuationTwoData.salesValuationTwoCompletedIn = elapsedTaskTime);

        updateListing(salesValuationTwoData);
    };

    render() {
        const { className } = this.props;

        return (
            <Formik
                initialValues={{
                    salesValuationTwoMinPrice: 0,
                    salesValuationTwoMaxPrice: 0
                }}
                validateOnChange={false}
                validateOnBlur={false}
                validationSchema={salesRangeTwoValidationSchema}
                onSubmit={this.handleSubmit}
                render={(props) => (
                    <>
                        {Object.keys(props.errors).length > 0 && (
                            <FormErrors>
                                <ul>
                                    {Object.keys(props.errors).map((error, index) => (
                                        <li key={index}>{props.errors[error]}</li>
                                    ))}
                                </ul>
                            </FormErrors>
                        )}
                        <Wrapper className={className} onSubmit={props.handleSubmit}>
                            <HeadingText>Set the sales range</HeadingText>
                            <FlexContainer>
                                <InputWrapper>
                                    <Label>Minimum</Label>
                                    <FastField
                                        name="salesValuationTwoMinPrice"
                                        render={({ field }: FastFieldProps) => <Input placeholder="£" {...field} />}
                                    />
                                </InputWrapper>
                                <InputWrapper>
                                    <Label>Maximum</Label>
                                    <FastField
                                        name="salesValuationTwoMaxPrice"
                                        render={({ field }: FastFieldProps) => <Input placeholder="£" {...field} />}
                                    />
                                </InputWrapper>
                                <SubmitButton type="submit">Save details</SubmitButton>
                            </FlexContainer>
                        </Wrapper>
                    </>
                )}
            />
        );
    }
}

const Wrapper = styled.form`
    padding: 20px;
    border: 1px solid ${(props) => props.theme.grey400};
`;

const HeadingText = styled(Label)`
    color: ${(props) => props.theme.grey700};
`;

const FlexContainer = styled.div`
    display: flex;
    align-items: center;
`;

const SubmitButton = styled(Button)`
    margin-top: 24px;
`;

const InputWrapper = styled.div`
    position: relative;
    padding: 10px 25px 5px 0;
    margin-right: 20px;
    width: 200px;
`;

export default withTimeTracking(SalesRangeTwoForm);
