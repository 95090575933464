import { Component, ReactNode } from "react";
import { Dispatch } from "redux";
import { connect } from "react-redux";

import { AppState } from "store";
import { Listing, listingSelectors, ListingStatusTypes } from "store/listing";
import { createListingRequest, saveListingRequest } from "store/listing/actions";

interface PropsFromState {
    listings: Listing[];
}

interface PropsFromDispatch {
    addListing: typeof createListingRequest;
    saveListing: typeof saveListingRequest;
}

interface OwnProps {
    listingID?: string;
    status?: ListingStatusTypes;
    children: (props: MappedProps) => ReactNode;
}

type MappedProps = PropsFromState & PropsFromDispatch;

export class ListingContainer extends Component<MappedProps & OwnProps> {
    render() {
        const { children, addListing, saveListing, listings } = this.props;
        return children({ addListing, saveListing, listings });
    }
}

export const mapStateToProps = (state: AppState, ownProps: OwnProps) => {
    let listings: Listing[];

    if (ownProps.listingID) {
        const listing = listingSelectors.getListingByID(state, ownProps.listingID);
        listings = listing ? [listing] : [];
    } else if (ownProps.status) {
        const getListing = listingSelectors.getAllListingsByStatus;
        listings = getListing(state, ownProps.status);
    } else {
        const allListings = listingSelectors.getAllListings(state);
        listings = Object.keys(allListings).map((key) => allListings[key]);
    }

    return {
        listings
    };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
    addListing: (listingID: string, postcode: string, rentalValuationMax: number) =>
        dispatch(createListingRequest(listingID, postcode, rentalValuationMax)),
    saveListing: (listing: Listing) => dispatch(saveListingRequest(listing))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ListingContainer);
