import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useDispatch } from "react-redux";

import withListing from "hoc/with-listing";
import { Listing, saveListingRequest, saveSelectedComps } from "store/listing";
import { AnalysisLayout } from "components/analysis-layout";
import { Heading2 } from "typography";
import RentalModerationForm from "./rental-moderation-form/rental-moderation-form";
import { EvaluateCard } from "components/comparable/evaluate-card";
import { RentalInfo } from "components/comparable/rental-info";
import { Select } from "components/select";
import { Label } from "components/form/label";
import { routePaths } from "routes";
import { useCompsForModeration, TSortOrder, TSortOptions } from "hooks/use-comps-for-moderation";
import { ModerationInfoPanel } from "components/moderation-info-panel";
import { updateRentalComparisonBySourceID } from "store/rental-comparison";

export interface Props {
    className?: string;
    listing: Listing;
    saveListing: typeof saveListingRequest;
}

export const generateLowYield = (price?: number | null): number => {
    return price ? Math.round((price * 0.049) / 12) : 0;
};

export const generateHighYield = (price?: number | null): number => {
    return price ? Math.round((price * 0.05) / 12) : 0;
};

export const getModelRentValue = (
    modelPredictedMonthlyRent?: number | null,
    manualPredictedMonthlyRent?: number | null
): number => {
    if (manualPredictedMonthlyRent) return manualPredictedMonthlyRent;
    if (modelPredictedMonthlyRent) return modelPredictedMonthlyRent;
    return 0;
};

export const RentalModeration: React.FC<Props> = ({ listing, saveListing, className }) => {
    const dispatch = useDispatch();
    const [sort, setSort] = useState<{ by: TSortOptions; order: TSortOrder }>({ by: "monthlyRent", order: "desc" });
    const [displayErrorForComps, setDisplayErrorForComps] = useState<boolean>(false);

    const { allComps, dedupedComps } = useCompsForModeration(listing.ID, "rental", sort.by, sort.order);

    const dismissedComps = dedupedComps.filter((comparable) => comparable.isDismissed);
    const rentalCompsSelected = dedupedComps.filter((comparable) => !comparable.isDismissed);
    const minNumCompsSelected = rentalCompsSelected.length === 2;

    useEffect(() => {
        document.title = "Rental Moderation | Peanut";
    }, []);

    const handleSortChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const selectValues = event.target.value.split("-");
        const [sortByValue, sortOrderValue] = selectValues;

        setSort({ by: sortByValue as TSortOptions, order: sortOrderValue as TSortOrder });
    };

    const handleSaveComps = () => {
        dispatch(saveSelectedComps(allComps));
    };

    const handleRanking = (ID: string | number | undefined, ranking: number) => {
        const rankingCompsIndex = allComps.findIndex((comp) => comp.ID === ID);
        allComps[rankingCompsIndex].ranking = Number(ranking);

        const rankingSelectedIndex = rentalCompsSelected.findIndex((comp) => comp.ID === ID);
        rentalCompsSelected[rankingSelectedIndex].ranking = Number(ranking);
    };

    return listing ? (
        <AnalysisLayout className={className} listing={listing}>
            {displayErrorForComps && (
                <ErrorMessage>
                    There must be a minimum of 2 comparables un-dismissed. To Dismiss this listing, reinstate one that
                    you have previously dismissed
                </ErrorMessage>
            )}

            <ComparablesHeader>
                <SectionHeading>Comparables ({rentalCompsSelected.length})</SectionHeading>
                <div>
                    <Label htmlFor="sort">Sort by</Label>
                    <Select id="sort" onChange={handleSortChange}>
                        <option value="monthlyRent-desc">Rent, High to Low</option>
                        <option value="monthlyRent-asc">Rent, Low to High</option>
                        <option value="distance-desc">Distance, High to Low</option>
                        <option value="distance-asc">Distance, Low to High</option>
                        <option value="totalBedrooms-desc">Bedrooms, High to Low</option>
                        <option value="totalBedrooms-asc">Bedrooms, Low to High</option>
                        <option value="floorArea-desc">Floor area, High to Low</option>
                        <option value="floorArea-asc">Floor area, Low to High</option>
                        <option value="selectedBy-asc">Valuer</option>
                        <option value="rentalDate-desc">Date, Newest to Oldest</option>
                        <option value="rentalDate-asc">Date, Oldest to Newest</option>
                    </Select>
                </div>
            </ComparablesHeader>

            <ComparablesContainer>
                {rentalCompsSelected.map((comp) => (
                    <EvaluateCard
                        key={comp.ID}
                        compId={comp.ID}
                        imageUrls={comp.images?.length ? comp.images : []}
                        floorPlans={comp.floorPlans?.length ? comp.floorPlans : []}
                        listingURL={routePaths.sctTool.replace(":sourceID", comp.sourceID || "")}
                        showRanking={true}
                        existingRanking={comp.ranking}
                        handleRanking={handleRanking}
                        onDismiss={() => {
                            minNumCompsSelected
                                ? setDisplayErrorForComps(true)
                                : dispatch(updateRentalComparisonBySourceID(comp.sourceID, true));
                        }}
                    >
                        <RentalInfo
                            rentalComparison={comp}
                            listing={listing}
                            isValuation1={comp.selectedByValuation1}
                            isValuation2={comp.selectedByValuation2}
                        />
                    </EvaluateCard>
                ))}
            </ComparablesContainer>

            <ListHeading>Dismissed ({dismissedComps.length})</ListHeading>
            <DismissedFade>
                {dismissedComps.map((comparable) => (
                    <EvaluateCard
                        key={comparable.ID}
                        compId={comparable.ID}
                        imageUrls={comparable.images?.length ? comparable.images : []}
                        floorPlans={comparable.floorPlans?.length ? comparable.floorPlans : []}
                        listingURL={comparable.sourceURL || ""}
                        isComplete={comparable.isComplete}
                        handleRanking={handleRanking}
                        existingRanking={comparable.ranking}
                        onReinstate={() => {
                            dispatch(updateRentalComparisonBySourceID(comparable.sourceID, false));
                            setDisplayErrorForComps(false);
                        }}
                    >
                        <RentalInfo rentalComparison={comparable} listing={listing} />
                    </EvaluateCard>
                ))}
            </DismissedFade>
            <StickyModInfoPanel
                val1Min={listing.rentalValuationMinMonthlyRent || 0}
                val1Max={listing.rentalValuationMaxMonthlyRent || 0}
                val2Min={listing.rentalValuationTwoMinMonthlyRent || 0}
                val2Max={listing.rentalValuationTwoMaxMonthlyRent || 0}
                lowYield={generateLowYield(listing.price || 0)}
                highYield={generateHighYield(listing.price || 0)}
                modelPredictedMonthlyRent={getModelRentValue(
                    listing.modelPredictedMonthlyRent,
                    listing.manualPredictedMonthlyRent
                )}
            >
                <RentalModerationForm
                    listingID={listing.ID}
                    updateListing={saveListing}
                    status={listing.status}
                    handleSaveComps={handleSaveComps}
                    rentalComps={rentalCompsSelected}
                />
            </StickyModInfoPanel>
        </AnalysisLayout>
    ) : null;
};

const SectionHeading = styled.h2`
    ${Heading2};
    margin: 30px 0;
    font-weight: 700;
`;

const ComparablesHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const ComparablesContainer = styled.section`
    position: relative;
    z-index: 1;
`;

const StickyModInfoPanel = styled(ModerationInfoPanel)`
    position: sticky;
    bottom: 0;
    z-index: 2;
`;

const ListHeading = styled.h2`
    ${Heading2};
    display: block;
    margin: 48px 0 16px;
`;

const DismissedFade = styled.div`
    opacity: 0.5;
`;

const ErrorMessage = styled.div`
    padding: 24px 16px;
    color: ${({ theme }) => theme.neutral0};
    background: ${({ theme }) => theme.failure};
    border-radius: 4px;
    position: sticky;
    top: 0;
    z-index: 3;
`;

export default withListing(RentalModeration);
