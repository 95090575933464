import React, { Component, ComponentType } from "react";

export interface TimeTrackingProps {
    getElapsedTaskTime: (currentTimeInMS?: number) => number;
}

interface State {
    taskStartTimeInMS: number | null;
}

export const withTimeTracking = <WrappedComponentProps extends object>(
    WrappedComponent: ComponentType<WrappedComponentProps & TimeTrackingProps>
) =>
    class WithTimeTracking extends Component<WrappedComponentProps, State> {
        state = {
            taskStartTimeInMS: null
        };

        setTaskStartTime = (currentTime: number): void => {
            this.setState({ taskStartTimeInMS: currentTime });
        };

        getElapsedTaskTime = (currentTimeInMS: number = Date.now()): number => {
            const { taskStartTimeInMS } = this.state;
            let elapsedTimeInSecs = 0;

            if (taskStartTimeInMS) {
                // The ! is to coerce taskStartTimeInMS to not-null as TS thinks it still might be null, despite the check above
                elapsedTimeInSecs = Math.round((currentTimeInMS - taskStartTimeInMS!) / 1000);
            }

            return elapsedTimeInSecs > 0 ? elapsedTimeInSecs : 0;
        };

        componentDidMount() {
            this.setTaskStartTime(Date.now());
        }

        render() {
            return <WrappedComponent getElapsedTaskTime={this.getElapsedTaskTime} {...this.props} />;
        }
    };
