import React from "react";
import { FastField, FastFieldProps, Formik } from "formik";
import styled from "styled-components";

import { Button } from "components/button";
import { FormErrors, Input, Label } from "components/form";
import { AddressMatchTable, Address } from "../address-match-table";
import { Listing } from "store/listing";
import { addPropertyValidationSchema } from "./add-property-validation-schema";
import { withTimeTracking, TimeTrackingProps } from "hoc/with-time-tracking";
import { checkIfStatusNeedsUpdating, statusIsMoreAdvanced } from "utils/status-check";

interface Props {
    listingID: string;
    addresses: Address[];
    updateListing: (listing: Listing) => void;
    status: Listing["status"];
    postcode: string;
    onReload: (postcode: string) => void;
}

export const AddPropertyForm: React.FC<Props & TimeTrackingProps> = ({
    listingID,
    addresses,
    updateListing,
    getElapsedTaskTime,
    status,
    postcode,
    onReload
}) => {
    const handleSubmit = (values: { UDPRN: string; address: string; postcode: string }) => {
        const { UDPRN, address, postcode } = values;
        const elapsedTaskTime = getElapsedTaskTime(Date.now());
        const addressMatchData: Listing = {
            ID: listingID,
            UDPRN,
            address,
            postcode,
            status: checkIfStatusNeedsUpdating(status, "basicAnalysis")
        };

        statusIsMoreAdvanced(status, "basicAnalysis")
            ? (addressMatchData.qaAddressMatchCompletedIn = elapsedTaskTime)
            : (addressMatchData.addressMatchCompletedIn = elapsedTaskTime);

        updateListing(addressMatchData);
    };

    return (
        <Formik
            initialValues={{ UDPRN: "", address: "", postcode: postcode }}
            onSubmit={handleSubmit}
            validationSchema={addPropertyValidationSchema}
            validateOnChange={false}
            validateOnBlur={false}
        >
            {({ errors, handleSubmit, handleChange, setFieldValue, values }) => (
                <>
                    {Object.keys(errors).length > 0 && (
                        <FormErrors>
                            <ul>
                                {Object.keys(errors).map((error, index) => (
                                    <li key={index}>{errors[error]}</li>
                                ))}
                            </ul>
                        </FormErrors>
                    )}
                    <FormContainer onSubmit={handleSubmit}>
                        <TopContainer>
                            <PostcodeWrapper>
                                <StyledLabel>
                                    <LabelText>Postcode</LabelText>
                                    <FastField name="postcode" value={values.postcode}>
                                        {({ field }: FastFieldProps) => (
                                            <PostcodeInput
                                                {...field}
                                                onChange={handleChange}
                                                data-testid="postcode-input"
                                            />
                                        )}
                                    </FastField>
                                </StyledLabel>
                                <Button
                                    data-testid="reload-address-match"
                                    type="button"
                                    onClick={() => onReload(values.postcode)}
                                >
                                    Reload
                                </Button>
                            </PostcodeWrapper>
                            <Button data-testid="submit-address-match" type="submit">
                                Save
                            </Button>
                        </TopContainer>
                        {addresses.length === 0 ? (
                            <NoResultsMessage data-testid="no-results-message">
                                No results found for this postcode
                            </NoResultsMessage>
                        ) : (
                            <AddressMatchTable
                                addresses={addresses}
                                onSelect={(UDPRN: Address["UDPRN"], address, postcode) => {
                                    setFieldValue("UDPRN", UDPRN);
                                    setFieldValue("address", address);
                                    setFieldValue("postcode", postcode);
                                }}
                            />
                        )}
                    </FormContainer>
                </>
            )}
        </Formik>
    );
};

const FormContainer = styled.form`
    display: flex;
    flex-direction: column;
    align-items: baseline;
    margin: 20px 0;
`;

const TopContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin: 10px 0;
`;

const PostcodeWrapper = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
`;

const StyledLabel = styled(Label)`
    margin: 0;
`;

const LabelText = styled.p`
    max-width: 200px;
    font-weight: 700;
    background-color: ${({ theme }) => theme.grey200};
    padding: 12px;
    margin-bottom: 10px;
`;

const PostcodeInput = styled(Input)`
    margin-right: 10px;
`;

const NoResultsMessage = styled.div`
    font-size: 24px;
    color: ${({ theme }) => theme.failure};
    margin: 24px 0;
`;

export default withTimeTracking(AddPropertyForm);
