import React from "react";

import styled from "styled-components";
import { useAuth } from "react-oidc-context";

import { Button } from "components/button";

interface Props {
    className?: string;
}

export const Login = ({ className }: Props) => {
    document.title = "Login | Peanut";

    const auth = useAuth();

    return (
        <Wrapper className={className}>
            {auth.error && <ErrorMessage>{auth.error.message} .Please try again.</ErrorMessage>}

            <Button onClick={() => auth.signinRedirect()}>Sign in with Google</Button>
        </Wrapper>
    );
};

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 150px;
`;

const ErrorMessage = styled.p`
    font-weight: bold;
    color: red;
    margin-bottom: 10px;
`;
