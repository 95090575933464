import React, { useState } from "react";
import styled from "styled-components";

import { Button, GreyButton } from "components/button";
import { MainWrapper, MainContentWrapper } from "../helper-components";
import { ImageCarousel } from "components/comparable/image-carousel";
import { Label } from "components/form/label";
import { Input } from "components/form/input";

interface Props {
    compId?: string | number;
    children: React.ReactNode;
    imageUrls: string[];
    floorPlans: string[];
    listingURL: string;
    logbookURL?: string;
    onEvaluate?: () => void;
    onDismiss?: () => void;
    onReinstate?: () => void;
    isComplete?: boolean;
    showRanking?: boolean;
    existingRanking?: number | null;
    handleRanking?: (ID: string | number | undefined, ranking: number) => void;
}

export interface CompsRankingProps {
    ID: string | number | undefined;
    ranking: number;
}

export const EvaluateCard = ({
    compId,
    children,
    imageUrls,
    floorPlans,
    onEvaluate,
    onDismiss,
    onReinstate,
    isComplete = false,
    showRanking,
    existingRanking,
    handleRanking,
    listingURL,
}: Props) => {
    const [ranking, setRanking] = useState<number | string | undefined>(existingRanking ? existingRanking : "");
    const handleOnClick = () => {
        window.open(listingURL, "Rightmove Plus Landing Page", "width=1000, height=800");
    };

    const handleRankingField = (e: React.ChangeEvent<HTMLInputElement>) => {
        handleRanking && handleRanking(compId, Number(e.target.value));
        setRanking(e.target.value);
    };

    return (
        <MainWrapper>
            <div>
                {imageUrls?.length ? (
                    <ImageCarousel images={imageUrls} className="evaluate-card-image-gallery" />
                ) : (
                    <NoAvailabilityText>NO IMAGE AVAILABLE</NoAvailabilityText>
                )}
                {floorPlans?.length ? (
                    <FloorPlans>
                        <ImageCarousel
                            images={floorPlans}
                            enableBrowserZoom={true}
                            className="evaluate-card-floorplans-image-gallery"
                        />
                    </FloorPlans>
                ) : (
                    <NoAvailabilityText>NO FLOOR PLAN AVAILABLE</NoAvailabilityText>
                )}
            </div>
            <MainContentWrapper>{children}</MainContentWrapper>
            <ActionsContainer>
                <ActionLinksContainer>
                    <ActionLink onClick={handleOnClick}>Open Listing &#187;</ActionLink>
                </ActionLinksContainer>
                {onEvaluate && !isComplete && (
                    <Button data-testid="evaluate-btn" type="button" onClick={onEvaluate}>
                        Evaluate
                    </Button>
                )}
                {onEvaluate && isComplete && (
                    <OutlinedButton data-testid="re-evaluate-btn" type="button" onClick={onEvaluate}>
                        Re-evaluate
                    </OutlinedButton>
                )}
                {onReinstate && (
                    <StyledGreyButton data-testid="reinstate-btn" type="button" onClick={onReinstate}>
                        Reinstate
                    </StyledGreyButton>
                )}
                {onDismiss && (
                    <StyledGreyButton data-testid="dismiss-btn" type="button" onClick={onDismiss}>
                        Dismiss
                    </StyledGreyButton>
                )}
                {showRanking && (
                    <RankingContainer>
                        <StyledLabel>Ranking</StyledLabel>
                        <StyledInput
                            name="sourceID"
                            value={ranking}
                            onChange={(e: any) => handleRankingField(e)}
                            type="number"
                            pattern="\d*"
                        />
                    </RankingContainer>
                )}

                {isComplete && <CompleteText>&#10003; Evaluation Complete</CompleteText>}
            </ActionsContainer>
        </MainWrapper>
    );
};

const FloorPlans = styled.div`
    margin: 20px 0;
`;

const ActionsContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 210px;
    padding: 16px;
`;

const ActionLinksContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-bottom: 32px;
`;

const ActionLink = styled.a`
    color: ${({ theme }) => theme.coral600};
    font-weight: 600;
    margin: 8px 8px 0;
    text-decoration: none;
    cursor: pointer;
`;

const OutlinedButton = styled(Button)`
    color: ${({ theme }) => theme.success};
    background: #fff;
    border: 2px solid ${({ theme }) => theme.success};
`;

const StyledGreyButton = styled(GreyButton)`
    margin-top: 8px;
`;

const CompleteText = styled.p`
    display: block;
    text-align: center;
    color: ${({ theme }) => theme.grey600};
    margin-top: 32px;
`;

const NoAvailabilityText = styled(CompleteText)`
    color: ${({ theme }) => theme.failure};
    width: 296px;
`;

const RankingContainer = styled.div`
    display: flex;
    align-items: center;
    margin-top: 11px;
`;
const StyledLabel = styled(Label)`
    font-size: 14px;
    font-weight: 700;
    color: ${({ theme }) => theme.grey700};
    margin-right: 12px;
    margin-bottom: 0px;
`;

const StyledInput = styled(Input)`
    width: 49px;
    color: ${({ theme }) => theme.grey700};
    font-size: 14px;
    font-weight: 700;
    ::-webkit-outer-spin-button,
    ::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
`;
