import React, { useEffect } from "react";
import styled from "styled-components";
import { Formik } from "formik";

import { Button } from "components/button";
import { Input } from "components/form/input";
import { Listing } from "store/listing";
import { rejectListingValidationSchema } from "components/reject-listing/reject-form/reject-listing-validation-schema";
import { FormErrors, HorizontalMultiChoiceButton } from "components/form";
import { ListingStatusTypes } from "store/listing/types";
import { withTimeTracking, TimeTrackingProps } from "hoc/with-time-tracking";
import { firstLevelOptions, getSecondLevel, getThirdLevel } from "./reject-modal-options";

export interface Props {
    className?: string;
    listingID: string;
    isShowing: boolean;
    onSubmit: (listing: Listing) => void;
    onCancel: () => void;
    listingStatus: string;
}

export interface FormProps {
    ID: string;
    rejectionNotes: string;
    level1: string;
    level2: string;
    level3: string;
}

export const RejectForm: React.FC<Props & TimeTrackingProps> = ({
    className,
    listingID,
    isShowing,
    onCancel,
    getElapsedTaskTime,
    onSubmit,
    listingStatus,
}) => {
    const showCommentInput = (firstLevelOption: string | undefined, thirdLevelOption: string | undefined) =>
        firstLevelOption === "flag for moderator" || thirdLevelOption;

    useEffect(() => {
        const handleKeyEvent = (event: KeyboardEvent) => {
            if (event.key === "Escape") {
                onCancel();
            }
        };

        window.addEventListener("keydown", handleKeyEvent);
        return () => {
            window.removeEventListener("keydown", handleKeyEvent);
        };
    }, [onCancel]);

    const handleSubmit = (listing: FormProps) => {
        const listingWithHierarchy = {
            ID: listing.ID,
            status: "rejected" as ListingStatusTypes,
            rejectionNotes: listing.rejectionNotes,
            rejectionHierarchy: { level1: listing.level1, level2: listing.level2, level3: listing.level3 },
            [`${listingStatus}CompletedIn`]: getElapsedTaskTime(),
        };
        onSubmit(listingWithHierarchy);
    };

    return isShowing ? (
        <Wrapper className={className}>
            <Overlay />
            <Modal>
                <Formik
                    initialValues={{
                        ID: listingID,
                        level1: "",
                        level2: "",
                        level3: "",
                        rejectionNotes: "",
                    }}
                    validationSchema={rejectListingValidationSchema}
                    validateOnBlur={false}
                    validateOnChange={false}
                    onSubmit={handleSubmit}
                >
                    {(props) => (
                        <>
                            {Object.keys(props.errors).length > 0 && (
                                <FormErrors>
                                    <ul>
                                        {Object.keys(props.errors).map((error, index) => (
                                            <li key={index}>{props.errors[error]}</li>
                                        ))}
                                    </ul>
                                </FormErrors>
                            )}
                            <FormWrapper onSubmit={props.handleSubmit}>
                                <TextWrapper>What is your reason for rejecting this property?</TextWrapper>
                                <MultiChoiceButtonContainer>
                                    <HorizontalMultiChoiceButton
                                        choices={firstLevelOptions}
                                        onChange={(e) => {
                                            props.handleChange(e);
                                            props.setFieldValue("level2", "");
                                            props.setFieldValue("level3", "");
                                            props.setFieldValue("rejectionNotes", "");
                                        }}
                                        selectedValue={props.values.level1}
                                    />
                                </MultiChoiceButtonContainer>
                                {props.values.level1 && (
                                    <MultiChoiceButtonContainer>
                                        <FlexWrapMultiChoiceButton
                                            choices={getSecondLevel(props.values.level1)}
                                            onChange={(e) => {
                                                props.handleChange(e);
                                                props.setFieldValue("level3", "");
                                                props.setFieldValue("rejectionNotes", "");
                                            }}
                                            selectedValue={props.values.level2}
                                        />
                                    </MultiChoiceButtonContainer>
                                )}
                                {props.values.level2 && (
                                    <MultiChoiceButtonContainer>
                                        <FlexWrapMultiChoiceButton
                                            choices={getThirdLevel(props.values.level2)}
                                            onChange={(e) => {
                                                props.handleChange(e);
                                                props.setFieldValue("rejectionNotes", "");
                                            }}
                                            selectedValue={props.values.level3}
                                        />
                                    </MultiChoiceButtonContainer>
                                )}
                                {showCommentInput(props.values.level1, props.values.level3) && (
                                    <StyledInput
                                        name="rejectionNotes"
                                        onChange={props.handleChange}
                                        placeholder="Add your reasoning..."
                                        autoFocus={true}
                                        onKeyUp={(event: any) => {
                                            event.stopPropagation();
                                        }}
                                    />
                                )}

                                <ButtonsWrapper>
                                    <SubmitButton type="submit">Reject</SubmitButton>
                                    <CancelButton as="a" onClick={onCancel}>
                                        Cancel
                                    </CancelButton>
                                </ButtonsWrapper>
                            </FormWrapper>
                        </>
                    )}
                </Formik>
            </Modal>
        </Wrapper>
    ) : null;
};

const Wrapper = styled.div`
    position: relative;
    z-index: 2;
`;

const Modal = styled.div`
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    border-radius: 4px;
    margin: 0 auto;
    width: 100%;
`;

const Overlay = styled.div`
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: ${(props) => props.theme.grey700};
    opacity: 0.9;
`;

const FormWrapper = styled.form`
    padding: 20px 40px 55px 40px;
    display: flex;
    flex-direction: column;
`;

const TextWrapper = styled.p`
    margin: 40px 0 30px 0;
    font-size: 20px;
    text-align: center;
`;

const MultiChoiceButtonContainer = styled.div`
    display: grid;
    justify-content: center;
    margin-top: 30px;
`;

const StyledInput = styled(Input)`
    margin: 25px 0 45px 0;
    width: 80%;
    font-size: 16px;
    padding: 15px;
    align-self: center;
`;

const ButtonsWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 30px;
`;

const SubmitButton = styled(Button)`
    background: ${(props) => props.theme.red500};
    margin-right: 10px;
`;

const CancelButton = styled(Button)`
    margin-left: 10px;
    background: white;
    color: ${(props) => props.theme.red500};
    border: 1px solid ${(props) => props.theme.red500};
`;

const FlexWrapMultiChoiceButton = styled(HorizontalMultiChoiceButton)`
    flex-wrap: wrap;
`;

CancelButton.displayName = "CancelButton";

export default withTimeTracking(RejectForm);
