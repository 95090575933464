import { PropertyType, PropertyStyle, PropertyCondition } from "store/listing/types";

export interface SalesComparison {
    ID: string;
    sourceID?: string;
    listingID?: string;
    operatorID?: string;
    selectedBy?: string;
    comparisonType?: string;
    postcode?: string;
    sourceURL?: string;
    address?: string;
    distance?: string | number;
    propertyType?: PropertyType;
    propertyStyle?: PropertyStyle;
    price?: number;
    priceSource?: SalesType;
    priceDate?: string;
    totalRooms?: number;
    totalSingleBedrooms?: number;
    totalDoubleBedrooms?: number;
    totalBedrooms?: number;
    totalBathrooms?: number;
    floorArea?: number;
    hasOutsideSpace?: boolean | "unknown";
    hasParking?: boolean | "unknown";
    conditionOverall?: PropertyCondition;
    conditionKitchen?: PropertyCondition;
    conditionInterior?: PropertyCondition;
    conditionBathroom?: PropertyCondition;
    conditionExterior?: PropertyCondition;
    notes?: string;
    valuation2Notes?: string;
    valuation2ConditionOverall?: PropertyCondition;
    valuation2ConditionBathroom?: PropertyCondition;
    valuation2ConditionKitchen?: PropertyCondition;
    salesComparisonCompletedIn?: number;
    images?: string[];
    floorPlans?: string[];
    isChosen?: boolean;
    isDismissed?: boolean;
    isComplete?: boolean;
    selected?: boolean;
    ranking?: number | null;
}

export type SalesType = "market" | "uo" | "hmlr";

export const SalesComparisonActionTypes = {
    CREATE_REQUEST: "sales_comparison/CREATE_REQUEST",
    CREATE_SUCCESS: "sales_comparison/CREATE_SUCCESS",
    SAVE_REQUEST: "sales_comparison/SAVE_REQUEST",
    SAVE_SUCCESS: "sales_comparison/SAVE_SUCCESS",
    RESET_WRITE_STATUS: "sales_comparison/RESET_WRITE_STATUS",
    FETCH_BY_LISTING_REQUEST: "sales_comparison/FETCH_BY_LISTING_REQUEST",
    FETCH_SUCCESS: "sales_comparison/FETCH_SUCCESS",
    FETCH_SCT: "salesSCT/FETCH_SCT",
    FETCH_SCT_SUCCESS: "salesSCT/FETCH_SCT_SUCCESS",
    UPDATE: "sales-comparison/UPDATE",
    UPDATE_BY_SOURCE_ID: "sales-comparison/UPDATE_BY_SOURCE_ID",
    COMPLETED: "sales-comparison/COMPLETED",
};

export interface SalesComparisonState {
    readonly byID: { [ID: string]: SalesComparison };
    readonly allIDs: string[];
    readonly totalCompsValTaskTime: number;
    readonly writeComplete?: boolean;
}

export type houseType = "house" | "flat";
export type rentalOrSales = "rental" | "sales";

export interface SCTFetchParams {
    postcode: string;
    sourceID: string;
    listingID: string;
    rentalOrSales: rentalOrSales;
    numBeds: number;
    propertyStyle: PropertyStyle;
    propertyType: houseType;
}
