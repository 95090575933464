import { PropertyType, PropertyStyle, PropertyCondition } from "store/listing/types";

export interface RentalComparison {
    ID: string;
    sourceID?: string;
    listingID?: string;
    operatorID?: string;
    selectedBy?: string;
    comparisonType?: string;
    sourceURL?: string;
    address?: string;
    postcode?: string;
    distance?: string | number;
    propertyType?: PropertyType;
    propertyStyle?: PropertyStyle;
    monthlyRent?: number;
    rentalType?: RentalType;
    rentalDate?: string;
    totalBedrooms?: number;
    totalBathrooms?: number;
    floorArea?: number;
    hasOutsideSpace?: boolean | "unknown";
    hasParking?: boolean | "unknown";
    conditionOverall?: PropertyCondition;
    conditionKitchen?: PropertyCondition;
    conditionInterior?: PropertyCondition;
    conditionBathroom?: PropertyCondition;
    conditionExterior?: PropertyCondition;
    notes?: string;
    valuation2Notes?: string;
    valuation2ConditionOverall?: PropertyCondition;
    valuation2ConditionBathroom?: PropertyCondition;
    valuation2ConditionKitchen?: PropertyCondition;
    rentalComparisonCompletedIn?: number;
    images?: string[];
    floorPlans?: string[];
    isChosen?: boolean;
    isDismissed?: boolean;
    isComplete?: boolean;
    selected?: boolean;
    ranking?: number | null;
}

export type RentalType = "let-agreed" | "listed" | "achieved";

export const RentalComparisonActionTypes = {
    CREATE_REQUEST: "rental_comparison/CREATE_REQUEST",
    CREATE_SUCCESS: "rental_comparison/CREATE_SUCCESS",
    SAVE_REQUEST: "rental_comparison/SAVE_REQUEST",
    SAVE_SUCCESS: "rental_comparison/SAVE_SUCCESS",
    RESET_WRITE_STATUS: "rental_comparison/RESET_WRITE_STATUS",
    FETCH_BY_LISTING_REQUEST: "rental_comparison/FETCH_BY_LISTING_REQUEST",
    FETCH_SUCCESS: "rental_comparison/FETCH_SUCCESS",
    FETCH_SCT: "rentalSCT/FETCH_SCT",
    FETCH_SCT_SUCCESS: "rentalSCT/FETCH_SCT_SUCCESS",
    UPDATE: "rental_comparison/UPDATE",
    UPDATE_BY_SOURCE_ID: "rental/UPDATE_BY_SOURCE_ID",
    COMPLETED: "rental_comparison/COMPLETED",
};

export interface RentalComparisonState {
    readonly byID: { [rentalComparisonID: string]: RentalComparison };
    readonly allIDs: string[];
    readonly totalCompsValTaskTime: number;
    readonly writeComplete?: boolean;
}

export type houseType = "house" | "flat";
export type rentalOrSales = "rental" | "sales";

export interface SCTFetchParams {
    postcode: string;
    sourceID: string;
    listingID: string;
    rentalOrSales: rentalOrSales;
    numBeds: number;
    propertyStyle: PropertyStyle;
    propertyType: houseType;
}
