import { number, object, string } from "yup";

export const rentalComparisonValidationSchema = object({
    distance: string().required("'distance' is required"),
    propertyType: string().required("'type' is required"),
    propertyStyle: string().required("style is required"),
    monthlyRent: number()
        .min(300, "Monthly rent has to be minimum £300")
        .max(3000, "Monthly rent cannot be higher than £3000")
        .required("Monthly rent is required"),
    totalBedrooms: number()
        .min(1, "At least 1 bedroom is required")
        .required("Total number of bedrooms is required"),
    totalBathrooms: number()
        .min(1, "At least 1 bathroom is required")
        .required("Total number of bathrooms is required"),
    rentalDate: string().required("Rental age is required"),
    floorArea: number()
        .min(10, "Floor area must be at least 10 metres squared")
        .required("The floor area is required"),
    conditionOverall: string().required("The overall property condition must be specified"),
    conditionExterior: string().required("The property's exterior condition must be specified"),
    conditionInterior: string().required("The property's interior condition must be specified"),
    conditionBathroom: string().required("The property's bathroom condition must be specified"),
    conditionKitchen: string().required("The property's kitchen condition must be specified"),
    notes: string()
        .min(50, "Please add comments for this property - min 50 characters")
        .required("Please add comments for this property")
});
