import React, { useEffect } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";

import withListing from "hoc/with-listing";
import { Listing, saveListingRequest } from "store/listing";
import { SalesInfo } from "components/comparable/sales-info";
import { EvaluateCard } from "components/comparable/evaluate-card";
import SalesRangeOneForm from "./sales-range-form/sales-range-one-form";
import SalesRangeTwoForm from "./sales-range-form/sales-range-two-form";
import { routePaths } from "routes";
import { useCompsByOperator } from "hooks/use-comps-by-operator";
import { salesComparisonSelectors } from "store/sales-comparison";
import { AnalysisLayout } from "components/analysis-layout";
import { useAuth } from "react-oidc-context";

interface Props {
    listing: Listing;
    saveListing: typeof saveListingRequest;
}

export const getOperatorIDForSalesVal = (listing: Listing, currentOperatorID: string): string => {
    const { status, salesValuationCompletedBy, salesValuationTwoCompletedBy } = listing;

    if (status === "salesValuation") {
        return salesValuationCompletedBy ? salesValuationCompletedBy : currentOperatorID;
    } else {
        return salesValuationTwoCompletedBy ? salesValuationTwoCompletedBy : currentOperatorID;
    }
};

export const SalesValuationSummary: React.FC<Props> = ({ listing, saveListing }) => {
    const auth = useAuth();

    const currentOperatorID = auth.user?.profile.sid || "";
    const totalCompsValTaskTime: number = useSelector(salesComparisonSelectors.getTotalCompValTaskTime);
    const derivedOperatorID = getOperatorIDForSalesVal(listing, currentOperatorID);
    const comps = useCompsByOperator(listing.ID, "sales")[derivedOperatorID];
    const listingStatus = listing.status;

    useEffect(() => {
        const prefix = listingStatus === "salesValuationTwo" ? "Second" : "";
        document.title = `${prefix} Sales Valuation Summary | Peanut`;
    }, [listingStatus]);

    return listing && comps?.length ? (
        <Wrapper>
            <AnalysisLayout listing={listing}>
                {comps?.map((comp) => (
                    <EvaluateCard
                        key={comp.ID}
                        imageUrls={comp.images?.length ? comp.images : []}
                        floorPlans={comp.floorPlans?.length ? comp.floorPlans : []}
                        listingURL={comp.sourceURL || routePaths.sctTool.replace(":sourceID", comp.sourceID || "")}
                    >
                        <SalesInfo salesComparison={comp} listing={listing} />
                    </EvaluateCard>
                ))}
                <Divider />
                {listing.status === "salesValuation" ? (
                    <SalesRangeOneForm
                        listingID={listing.ID}
                        totalSalesComparisonTaskTime={totalCompsValTaskTime}
                        updateListing={saveListing}
                        status={listing.status}
                    />
                ) : (
                    <SalesRangeTwoForm
                        listingID={listing.ID}
                        totalSalesComparisonTaskTime={totalCompsValTaskTime}
                        updateListing={saveListing}
                        status={listing.status}
                    />
                )}
            </AnalysisLayout>
        </Wrapper>
    ) : null;
};

const Wrapper = styled.div`
    width: 45%;
    min-width: 1050px;
`;

const Divider = styled.div`
    margin-top: 15px;
`;

export default withListing(SalesValuationSummary);
