import { FormikHelpers } from "formik";

import { Choice } from "components/form/multi-choice-button";
import { PropertyType, Listing } from "store/listing/types";
import { PropertyTenure } from "store/listing";
import { Theme } from "../config/theme";

type Data = Array<{ [key: string]: any }>;

export const dataToObjectByKey = (data: Data, keyID: string) => {
    if (data.some((item) => item.hasOwnProperty(keyID))) {
        const newObject: { [keyID: string]: any } = {};

        data.forEach((item) => {
            newObject[item[keyID]] = item;
        });

        return newObject;
    } else {
        return data;
    }
};

export const getAllIDsFromData = (data: Data, key: string) => {
    return data.some((item) => item.hasOwnProperty(key)) ? data.map((item) => item[key]) : [];
};

export const filterPropertyStyleByType = (type: PropertyType | undefined | null, style: Choice[] = []) => {
    switch (type?.toLowerCase()) {
        case "house":
            return style.filter((data) => data.extraInfo === "house");
        case "flat":
            return style.filter((data) => data.extraInfo === "flat");
        default:
            return style;
    }
};

export const setUndefinedIfNull = (value: any) => {
    return value === null ? undefined : value;
};

export const setNullIfUnknown = <T>(value: T | "unknown"): T | null => (value === "unknown" ? null : value);

export const setUnknownIfNull = <T>(value: T | null): T | "unknown" => (value === null ? "unknown" : value);

export const penceToPounds = (penceValue: number | null | undefined) => (penceValue ? penceValue / 100 : 0);

export const poundsToPence = (poundValue: number | null | undefined) => (poundValue ? poundValue * 100 : 0);

export const decimalToPercentage = (value: number) => Number((value * 100).toFixed(2));

export const camelCaseToCapitalisedWords = (input: string): string => {
    return input.replace(/([A-Z])/g, " $1").replace(/(^.)/, (letter) => letter.toUpperCase());
};

export const stringToKebabCase = (input: string) => {
    return input
        .replace(/([a-z])([A-Z])/g, "$1-$2")
        .replace(/[\s_]+/g, "-")
        .toLowerCase();
};

export const numberWithCommas = (value: string | number): string => {
    const str = typeof value === "number" ? value.toString() : value;

    const matchedString = str.match(/^-?\d+(?:\.\d{0,2})?/);
    let firstElement;
    if (Array.isArray(matchedString)) {
        firstElement = matchedString[0];
    } else {
        return "";
    }

    const formattedString = firstElement.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return `${formattedString}`;
};

export const valueToCurrency = (value: string | number): string => {
    const formattedString = numberWithCommas(value);
    return `£${formattedString}`;
};

export const propertyDisplayValueDict = (value: string | boolean | undefined | null) => {
    switch (value) {
        case "house":
            return "House";

        case "flat":
            return "Flat";

        case "detached":
            return "Detached";

        case "semi-detached":
            return "Semi Detached";

        case "terraced":
            return "Terraced";

        case "mid-terrace":
            return "Mid Terrace";

        case "end-terrace":
            return "End of terrace";

        case "end-of-terrace":
            return "End of terrace";

        case "purpose-built":
            return "Purpose-built";

        case "converted":
            return "Converted";

        case "let-agreed":
            return "Let Agreed";

        case "listed":
            return "Listed";

        case "market":
            return "Listed";

        case "uo":
            return "Under Offer";

        case "hmlr":
            return "HMLR";

        case "achieved":
            return "Achieved";

        case "true":
            return "Yes";

        case true:
            return "Yes";

        case "false":
            return "No";

        case false:
            return "No";

        default:
            return "Unknown";
    }
};

export const distanceDisplayDict = (value: any): string => {
    if (value === undefined || value === null) {
        return "Unknown";
    }

    if (value === 0) {
        return "Same postcode";
    }

    return value >= 1000 ? `< ${(value / 1000).toFixed(1)}km` : `< ${value}m`;
};

export const needsCompletion = (value: string | number | null | undefined | boolean): boolean => {
    return value === "unknown" || value === undefined;
};

export const visualDistance = (value: string | number | undefined | null): string => {
    if (value === undefined || value === null) {
        return Theme.unknown;
    }

    if (value <= 500) {
        return Theme.matched;
    }

    if (value <= 1000) {
        return Theme.similar;
    }

    if (value > 1000) {
        return Theme.misMatched;
    }

    return Theme.unknown;
};

export const visualRentalStatus = (value: string | undefined | null): string => {
    switch (value) {
        case "achieved":
            return Theme.matched;
        case "let-agreed":
            return Theme.similar;
        case "listed":
            return Theme.misMatched;
        default:
            return Theme.unknown;
    }
};

export const visualSalesStatus = (value: string | undefined | null): string => {
    switch (value) {
        case "hmlr":
            return Theme.matched;
        case "uo":
            return Theme.similar;
        case "market":
            return Theme.misMatched;
        default:
            return Theme.unknown;
    }
};

export const visualComparisonRoomNumbers = (
    rental: number | string | null | undefined,
    listing: number | string | null | undefined,
): string => {
    if (rental === undefined || rental === null || listing === undefined || listing === null) {
        return Theme.unknown;
    }

    rental = typeof rental === "string" ? parseInt(rental) : rental;
    listing = typeof listing === "string" ? parseInt(listing) : listing;

    if (rental === listing) {
        return Theme.matched;
    }

    if (rental === listing + 1 || rental === listing - 1) {
        return Theme.similar;
    }

    if (rental >= listing + 2 || rental <= listing - 2) {
        return Theme.misMatched;
    }

    return Theme.unknown;
};

export const visualComparisonType = (
    rentalType: any,
    rentalStyle: any,
    listingType: any,
    listingStyle: any,
): string => {
    if (
        rentalType === undefined ||
        rentalType === "undefined" ||
        rentalType === null ||
        rentalStyle === undefined ||
        rentalStyle === "undefined" ||
        rentalStyle === null ||
        listingType === undefined ||
        listingType === "undefined" ||
        listingType === null ||
        listingStyle === undefined ||
        listingStyle === "undefined" ||
        listingStyle === null
    ) {
        return Theme.unknown;
    }

    if (
        rentalType.toString().toLowerCase() === listingType.toString().toLowerCase() &&
        listingStyle.startsWith(rentalStyle.toString().toLowerCase())
    ) {
        return Theme.matched;
    }

    if (
        rentalType.toString().toLowerCase() === listingType.toString().toLowerCase() &&
        rentalStyle.toString().toLowerCase() !== listingStyle.toString().toLowerCase()
    ) {
        return Theme.similar;
    }

    if (rentalType.toString().toLowerCase() !== listingType.toString().toLowerCase()) {
        return Theme.misMatched;
    }

    return Theme.unknown;
};

export const visualAge = (age: string | undefined): string => {
    const day = "day";
    const days = "days";
    const month = "month";
    const months = "months";
    const year = "year";
    const years = "years";

    if (age !== undefined) {
        if (age.includes(day) || age.includes(days)) {
            const dayAge = parseInt(age);

            if (dayAge <= 182) {
                return Theme.matched;
            }

            if (dayAge <= 365) {
                return Theme.similar;
            }

            if (dayAge > 365) {
                return Theme.misMatched;
            }

            return Theme.unknown;
        }

        if (age.includes(month) || age.includes(months)) {
            const monthAge = parseInt(age);

            if (monthAge <= 6) {
                return Theme.matched;
            }

            if (monthAge < 12) {
                return Theme.similar;
            }

            if (monthAge >= 12) {
                return Theme.misMatched;
            }

            return Theme.unknown;
        }

        if (age.includes(year) || age.includes(years)) {
            const yearAge = parseInt(age);

            if (yearAge >= 1) {
                return Theme.misMatched;
            }

            return Theme.unknown;
        }
    } else {
        return Theme.unknown;
    }

    return Theme.unknown;
};

export const visualComparisonCondition = (
    listingCondition: string | undefined,
    rentalCondition: string | undefined,
): string => {
    if (
        listingCondition === "unknown" ||
        listingCondition === undefined ||
        listingCondition === "undefined" ||
        rentalCondition === "unknown" ||
        rentalCondition === undefined ||
        rentalCondition === "undefined"
    ) {
        return Theme.unknown;
    }

    if (listingCondition === rentalCondition) {
        return Theme.matched;
    }

    if (listingCondition === "excellent" && rentalCondition === "good") {
        return Theme.similar;
    }

    if (listingCondition === "good" && (rentalCondition === "excellent" || rentalCondition === "okay")) {
        return Theme.similar;
    }

    if (listingCondition === "okay" && (rentalCondition === "good" || rentalCondition === "bad")) {
        return Theme.similar;
    }

    if (listingCondition === "bad" && rentalCondition === "okay") {
        return Theme.similar;
    }

    if (listingCondition === "excellent" && rentalCondition === "okay") {
        return Theme.misMatched;
    }

    if (listingCondition === "excellent" && rentalCondition === "bad") {
        return Theme.misMatched;
    }

    if (listingCondition === "good" && rentalCondition === "bad") {
        return Theme.misMatched;
    }

    if (listingCondition === "okay" && rentalCondition === "excellent") {
        return Theme.misMatched;
    }

    if (listingCondition === "bad" && rentalCondition === "good") {
        return Theme.misMatched;
    }

    if (listingCondition === "bad" && rentalCondition === "excellent") {
        return Theme.misMatched;
    }

    return Theme.unknown;
};

export const visualComparisonOutsideFeature = (
    listingFeature: boolean | string | undefined | null,
    rentalFeature: boolean | string | undefined | null,
): string => {
    if (
        listingFeature === null ||
        listingFeature === undefined ||
        listingFeature === "unknown" ||
        rentalFeature === null ||
        rentalFeature === undefined ||
        rentalFeature === "unknown"
    ) {
        return Theme.unknown;
    } else if (listingFeature.toString() === rentalFeature.toString()) {
        return Theme.matched;
    } else if (listingFeature.toString() !== rentalFeature.toString()) {
        return Theme.similar;
    }

    return Theme.unknown;
};

export const visualComparisonFloorArea = (listingFloorArea: any, rentalFloorArea: any): string => {
    if (
        listingFloorArea === undefined ||
        listingFloorArea === "unknown" ||
        listingFloorArea === null ||
        rentalFloorArea === undefined ||
        rentalFloorArea === "unknown" ||
        rentalFloorArea === null
    ) {
        return Theme.unknown;
    } else if (rentalFloorArea === listingFloorArea) {
        return Theme.matched;
    } else if (
        rentalFloorArea >= listingFloorArea - listingFloorArea * 0.15 &&
        rentalFloorArea <= listingFloorArea + listingFloorArea * 0.15
    ) {
        return Theme.matched;
    } else if (
        (rentalFloorArea >= listingFloorArea - listingFloorArea * 0.25 &&
            rentalFloorArea < listingFloorArea - listingFloorArea * 0.15) ||
        (rentalFloorArea > listingFloorArea + listingFloorArea * 0.15 &&
            rentalFloorArea <= listingFloorArea + listingFloorArea * 0.25)
    ) {
        return Theme.similar;
    } else if (
        rentalFloorArea < listingFloorArea - listingFloorArea * 0.25 ||
        rentalFloorArea > listingFloorArea + listingFloorArea * 0.25
    ) {
        return Theme.misMatched;
    }

    return Theme.unknown;
};

export const yearsRemainingOnLease = (leaseDate?: string | null, leaseLength?: number | "unknown" | null) => {
    const leaseDateYear = leaseDate?.split("-")[0];
    const currentYear = new Date().getFullYear();

    if (!leaseLength || !leaseDateYear || leaseDate === "unknown" || leaseLength === "unknown") {
        return "unknown";
    }

    return Number(leaseDateYear) + Number(leaseLength) - Number(currentYear);
};

export const handleClickUnknown = (field: string, setFieldFn: FormikHelpers<Listing>["setFieldValue"]) => {
    setFieldFn(field, "unknown");
};

export const getLeaseData = (listing: Listing) => [
    {
        displayName: "Service Charge (annual)",
        fieldName: "serviceCharge",
        value: listing.serviceCharge,
    },
    {
        displayName: "Ground Rent (annual)",
        fieldName: "groundRent",
        value: listing.groundRent,
    },
    {
        displayName: "Lease Date",
        fieldName: "leaseDate",
        value: listing.leaseDate,
    },
    {
        displayName: "Lease Length (years)",
        fieldName: "leaseLength",
        value: listing.leaseLength,
    },
    {
        displayName: "Preliminary Years Remaining On Lease",
        fieldName: "preliminaryYearsRemaining",
        value: listing.preliminaryYearsRemaining,
        fieldType: "number",
    },
];

export const tenureIsLeaseholdOrShareOfFreehold = (value?: PropertyTenure) =>
    value === "leasehold" || value === "share-of-freehold";

export const getInitialFormValues = (listing: Listing) =>
    ({
        ID: listing.ID,
        UDPRN: listing.UDPRN,
        status: listing.status,
        propertyType: listing.propertyType?.toLowerCase() || "",
        propertyStyle: listing.propertyStyle || "",
        propertyTenure: listing.propertyTenure || "",
        totalSingleBedrooms: listing.totalSingleBedrooms || 0,
        totalDoubleBedrooms: listing.totalDoubleBedrooms || 0,
        totalBathrooms: listing.totalBathrooms || 0,
        totalWCs: listing.totalWCs || 0,
        floorArea: listing.floorArea || "",
        floorAreaSource: listing.floorAreaSource || "n/a",
        EPCCurrent: listing.EPCCurrent || "",
        EPCPotential: listing.EPCPotential || "",
        hasOutsideSpace: listing.hasOutsideSpace?.toString() || "",
        hasParking: listing.hasParking?.toString() || "",
        councilTaxBand: listing.councilTaxBand || "",
        councilTaxPrice: listing.councilTaxPrice || "",
        age: listing.age || "",
        conditionOverall: listing.conditionOverall || "",
        conditionExterior: listing.conditionExterior || "",
        conditionInterior: listing.conditionInterior || "",
        conditionBathroom: listing.conditionBathroom || "",
        conditionKitchen: listing.conditionKitchen || "",
        conditionNotes: listing.conditionNotes || "",
        serviceCharge: listing.serviceCharge || "",
        leaseDate: listing.leaseDate || "",
        leaseLength: listing.leaseLength || "",
        preliminaryYearsRemaining: listing.preliminaryYearsRemaining || "",
        groundRent: listing.groundRent || "",
    } as Listing);
