import { all, call, put, takeEvery, select } from "redux-saga/effects";
import { push } from "connected-react-router";
import { captureException } from "@sentry/browser";

import { RentalComparison, RentalComparisonActionTypes } from "./types";
import {
    completeRentalValuation,
    fetchRentalComparisonsByListingID,
    fetchRentalComparisonsSuccess,
    fetchSCT,
} from "./actions";
import { showApiError } from "store/ui";
import { penceToPounds, poundsToPence } from "utils/data-helpers";
import { fetchWithAuth } from "utils/fetch-with-auth";
import { rentalComparisonSelectors } from "./reducer";
import { routePaths } from "routes";

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

export const mapIncomingData = (data: any): RentalComparison => ({
    ID: data.ID,
    sourceID: data.sourceID,
    listingID: data.listingID,
    operatorID: data.operatorID,
    selectedBy: data.selectedBy,
    images: data.images,
    floorPlans: data.floorplans,
    comparisonType: data.comparisonType,
    address: data.address,
    postcode: data.postcode,
    distance: data.distanceMeters,
    propertyType: data.propertyType,
    propertyStyle: data.propertyStyle,
    monthlyRent: penceToPounds(data.monthlyRent),
    rentalType: data.rentalType,
    rentalDate: data.rentalDate,
    totalBedrooms: data.totalBedrooms,
    totalBathrooms: data.totalBathrooms,
    hasParking: data.hasParking,
    hasOutsideSpace: data.hasOutsideSpace,
    floorArea: data.floorArea,
    conditionOverall: data.conditionOverall,
    conditionKitchen: data.conditionKitchen,
    conditionInterior: data.conditionInterior,
    conditionBathroom: data.conditionBathroom,
    conditionExterior: data.conditionExterior,
    notes: data.notes,
    ranking: data.ranking,
});

export const mapOutgoingData = (rentalComp: RentalComparison) => ({
    ID: rentalComp.ID,
    sourceID: rentalComp.sourceID?.toString(),
    listingID: rentalComp.listingID,
    operatorID: rentalComp.operatorID,
    comparisonType: rentalComp.comparisonType,
    images: rentalComp.images,
    floorplans: rentalComp.floorPlans,
    address: rentalComp.address,
    postcode: rentalComp.postcode,
    distanceMeters: rentalComp.distance?.toString(),
    propertyType: rentalComp.propertyType,
    propertyStyle: rentalComp.propertyStyle,
    monthlyRent: rentalComp.monthlyRent && poundsToPence(rentalComp.monthlyRent),
    rentalType: rentalComp.rentalType,
    rentalDate: rentalComp.rentalDate,
    totalBedrooms: rentalComp.totalBedrooms,
    totalBathrooms: rentalComp.totalBathrooms,
    floorArea: rentalComp.floorArea,
    hasOutsideSpace: rentalComp.hasOutsideSpace,
    hasParking: rentalComp.hasParking,
    conditionOverall: rentalComp.conditionOverall,
    conditionKitchen: rentalComp.conditionKitchen,
    conditionInterior: rentalComp.conditionInterior,
    conditionBathroom: rentalComp.conditionBathroom,
    conditionExterior: rentalComp.conditionExterior,
    rentalComparisonCompletedIn: rentalComp.rentalComparisonCompletedIn,
    notes: rentalComp.notes,
});

export const mapSCTDataToRentalComparison = (data: any, index?: string | number) => ({
    ID: `${index}-${data.ID}`,
    sourceID: data.ID,
    sourceURL: data.listingURL,
    address: data.address,
    postcode: data.postcode,
    propertyType: data.propertyType || undefined,
    propertyStyle: data.propertyStyle || undefined,
    distance: data.distance === null ? undefined : data.distance,
    rentalType: data.rentalType || undefined,
    rentalDate: data.rentalDate || undefined,
    monthlyRent: data.monthlyRent || undefined,
    floorArea: data.floorArea || undefined,
    totalBathrooms: data.totalBathrooms === null ? undefined : data.totalBathrooms,
    totalBedrooms: data.totalBedrooms === null ? undefined : data.totalBedrooms,
    hasOutsideSpace: data.hasOutsideSpace === null ? undefined : data.hasOutsideSpace,
    hasParking: data.hasParking ? true : undefined,
    images: data.images,
    floorPlans: data.floorplans,
    ranking: data.ranking,
});

export function* handleFetchByListing(action: ReturnType<typeof fetchRentalComparisonsByListingID>) {
    try {
        const response = yield call(
            fetchWithAuth,
            `${API_ENDPOINT}/v1/rental-comparisons?listingID=${action.payload}`,
            { method: "GET" },
        );

        if (response.ok === true) {
            const body = yield response.json();
            const mappedData = body.results
                .map(mapIncomingData)
                .filter((comp: RentalComparison) => comp.comparisonType === "rental");

            yield put(fetchRentalComparisonsSuccess(mappedData));
        } else {
            captureException(
                JSON.stringify({ message: "failed to fetch rental comparisons", status: response.status }),
            );
            const body = yield response.json();
            yield put(showApiError(body.error));
        }
    } catch (error) {
        yield put(showApiError(error));
    }
}

export function* handleFetchSCT({ payload }: ReturnType<typeof fetchSCT>) {
    const params = new URLSearchParams({
        postcode: payload.postcode,
        listingID: payload.listingID,
        rentalOrSales: payload.rentalOrSales,
        numBeds: `${payload.numBeds}`,
        propertyType: payload.propertyType,
        propertyStyle: payload.propertyStyle,
    });

    if (payload.sourceID) {
        params.append("sourceID", payload.sourceID);
    }

    try {
        const response = yield call(fetchWithAuth, `${API_ENDPOINT}/v0/sct?${params.toString()}`, {
            method: "GET",
        });

        if (response.ok) {
            const body = yield response.json();
            const mappedData = body.results.map(mapSCTDataToRentalComparison);

            yield put(fetchRentalComparisonsSuccess(mappedData));
        } else {
            captureException(JSON.stringify({ message: "failed to fetch SCT data", status: response.status }));
            const body = yield response.json();
            yield put(showApiError(body.error));
        }
    } catch (error) {
        yield put(showApiError(error));
    }
}

export function* handleCompletedRentalValuation(action: ReturnType<typeof completeRentalValuation>) {
    const { listingID } = action.payload;
    const rentalComparisons: RentalComparison[] = yield select(rentalComparisonSelectors.getAllCompleted);

    if (rentalComparisons.length > 0) {
        try {
            yield all(
                rentalComparisons.map(function* (rentalComparison) {
                    const mappedRentalComparison = mapOutgoingData(rentalComparison);
                    const response = yield call(fetchWithAuth, `${API_ENDPOINT}/v1/rental-comparisons`, {
                        method: "POST",
                        body: JSON.stringify({ ...mappedRentalComparison, ID: undefined }), // Don't need ID when saving to API
                    });

                    if (!response.ok) {
                        captureException(
                            JSON.stringify({ message: "failed to POST rental comparisons", status: response.status }),
                        );
                        const body = yield response.json();
                        yield put(showApiError(body.error));
                    }
                }),
            );

            yield put(push(routePaths.rentalValuationSummary.replace(":listingID", listingID)));
        } catch (error) {
            yield put(showApiError(error));
        }
    } else {
        yield put(showApiError("No rental comparisons were found"));
    }
}

function* watchFetchByListingRequests() {
    yield takeEvery(RentalComparisonActionTypes.FETCH_BY_LISTING_REQUEST, handleFetchByListing);
}

function* watchFetchSCTRequests() {
    yield takeEvery(RentalComparisonActionTypes.FETCH_SCT, handleFetchSCT);
}

function* watchCompletedRentalValuation() {
    yield takeEvery(RentalComparisonActionTypes.COMPLETED, handleCompletedRentalValuation);
}

function* rentalComparisonSagas() {
    yield all([watchFetchByListingRequests(), watchFetchSCTRequests(), watchCompletedRentalValuation()]);
}

export { rentalComparisonSagas };
