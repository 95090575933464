import { Component, ReactNode } from "react";
import { Dispatch } from "redux";
import { connect } from "react-redux";

import { hideApiError, UISelectors } from "store/ui";
import { AppState } from "store";

interface OwnProps {
    children: (props: MappedProps) => ReactNode;
}

interface PropsFromState {
    isLoading: boolean;
    showApiError: boolean;
    apiErrorMessage: string;
}

interface PropsFromDispatch {
    closeError: typeof hideApiError;
}

type MappedProps = PropsFromState & PropsFromDispatch;

export class UIContainer extends Component<MappedProps & OwnProps> {
    render() {
        const { children, ...rest } = this.props;

        return children({ ...rest });
    }
}

const mapStateToProps = (state: AppState) => ({
    isLoading: UISelectors.getUILoadingState(state),
    showApiError: UISelectors.getApiErrorStatus(state),
    apiErrorMessage: UISelectors.getApiErrorMessage(state)
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    closeError: () => dispatch(hideApiError())
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UIContainer);
