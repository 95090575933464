import { ListingStatusTypes } from "store/listing";

export const statusSlugMap: Array<{ slug: string; status: ListingStatusTypes }> = [
    { slug: "prefilter", status: "prefilter" },
    { slug: "address-match", status: "addressMatch" },
    { slug: "basic-analysis", status: "basicAnalysis" },
    { slug: "rental-valuation", status: "rentalValuation" },
    { slug: "rental-valuation", status: "rentalValuationTwo" },
    { slug: "rental-moderation", status: "rentalModeration" },
    { slug: "sales-valuation", status: "salesValuation" },
    { slug: "sales-valuation", status: "salesValuationTwo" },
    { slug: "sales-moderation", status: "salesModeration" },
    { slug: "reviewed", status: "reviewed" },
    { slug: "rejected", status: "rejected" },
    { slug: "duplicate", status: "duplicate" }
];

export const getListingStatusFromPathname = (pathname: string, searchMap: Array<any>): ListingStatusTypes | null => {
    let status: ListingStatusTypes | null = null;

    searchMap.forEach((currentSearch) => {
        if (pathname.includes(currentSearch.slug)) {
            status = currentSearch.status;
        }
    });

    return status;
};

export const getSlugFromListingStatus = (status: ListingStatusTypes, searchMap: Array<any>): string | null => {
    let pathname: string | null = null;

    searchMap.forEach((currentSearch) => {
        if (currentSearch.status === status) {
            pathname = currentSearch.slug;
        }
    });

    return pathname;
};

export const encodeSpecialChars = (value: string | number | boolean) => {
    return encodeURIComponent(value).replace(/[!'()*]/g, function(c) {
        return "%" + c.charCodeAt(0).toString(16);
    });
};

export const objectToQueryString = (keyPairValues: {
    [key: string]: string | number | boolean | undefined;
}): string => {
    return Object.keys(keyPairValues).reduce((queryString, key) => {
        const prefix = queryString.indexOf("?") === -1 ? "?" : "&";
        const paramValue = keyPairValues[key];

        return key && paramValue ? `${queryString}${prefix}${key}=${encodeSpecialChars(paramValue)}` : queryString;
    }, "");
};

export const generateEPCLink = (address: string, postcode: string) => {
    const regExp = /\b\w\d+[a-z]?(?=,|\s)/;
    const returnedNumber = address.match(regExp);

    const epcLink = "https://epc.opendatacommunities.org/domestic/search";

    if (returnedNumber !== null) {
        return `${epcLink}?address=${returnedNumber[0]}&postcode=${postcode?.replace(" ", "+")}`;
    } else {
        return `${epcLink}?postcode=${postcode?.replace(" ", "+")}`;
    }
};
