import { object, string } from "yup";

export const rejectListingValidationSchema = object({
    level1: string().required("Please give the primary rejection reason"),
    level2: string().test("is-flag-mod-has-slected-level2", `Please give the secondary rejection reason`, function () {
        return this.parent.level1 === "flag for moderator" || this.parent.level2;
    }),
    level3: string().test("is-flag-mod-has-slected-level3", `Please give the tertiary rejection reason`, function () {
        return this.parent.level1 === "flag for moderator" || this.parent.level3;
    }),
    rejectionNotes: string().required("Please add your reasoning"),
});
