import React from "react";

import { RentalComparison } from "store/rental-comparison";
import { Listing } from "store/listing";

import { CardList, CardSubTitle, CardItem, ListContent } from "../helper-components";

import { visualComparisonCondition } from "utils/data-helpers";

export interface IRentalInfoConditionProps {
    rentalComparison: RentalComparison;
    listing: Listing;
    isValuation1?: boolean;
    isValuation2?: boolean;
}

export const RentalInfoCondition: React.FC<IRentalInfoConditionProps> = ({
    rentalComparison,
    listing,
    isValuation1,
    isValuation2,
}) => {
    const hasTwoValuations = isValuation1 && isValuation2;
    return hasTwoValuations ? (
        <ListContent>
            <CardList>
                <li>
                    <CardSubTitle>Overall Condition</CardSubTitle>
                </li>
                <li>
                    <CardItem
                        visualStatus={visualComparisonCondition(
                            listing.conditionOverall,
                            rentalComparison.conditionOverall
                        )}
                    >
                        Valuation 1:
                    </CardItem>{" "}
                    {rentalComparison.conditionOverall || "Unknown"}
                </li>
                <li>
                    <CardItem
                        visualStatus={visualComparisonCondition(
                            listing.conditionOverall,
                            rentalComparison.valuation2ConditionOverall
                        )}
                    >
                        Valuation 2:
                    </CardItem>{" "}
                    {rentalComparison.valuation2ConditionOverall || "Unknown"}
                </li>
            </CardList>
            <CardList>
                <li>
                    <CardSubTitle>Kitchen Condition</CardSubTitle>
                </li>
                <li>
                    <CardItem
                        visualStatus={visualComparisonCondition(
                            listing.conditionKitchen,
                            rentalComparison.conditionKitchen
                        )}
                    >
                        Valuation 1:
                    </CardItem>{" "}
                    {rentalComparison.conditionKitchen || "Unknown"}
                </li>
                <li>
                    <CardItem
                        visualStatus={visualComparisonCondition(
                            listing.conditionKitchen,
                            rentalComparison.valuation2ConditionKitchen
                        )}
                    >
                        Valuation 2:
                    </CardItem>{" "}
                    {rentalComparison.valuation2ConditionKitchen || "Unknown"}
                </li>
            </CardList>
            <CardList>
                <li>
                    <CardSubTitle>Bathroom Condition</CardSubTitle>
                </li>
                <li>
                    <CardItem
                        visualStatus={visualComparisonCondition(
                            listing.conditionBathroom,
                            rentalComparison.conditionBathroom
                        )}
                    >
                        Valuation 1:
                    </CardItem>{" "}
                    {rentalComparison.conditionBathroom || "Unknown"}
                </li>
                <li>
                    <CardItem
                        visualStatus={visualComparisonCondition(
                            listing.conditionBathroom,
                            rentalComparison.valuation2ConditionBathroom
                        )}
                    >
                        Valuation 2:
                    </CardItem>{" "}
                    {rentalComparison.valuation2ConditionBathroom || "Unknown"}
                </li>
            </CardList>
        </ListContent>
    ) : (
        <CardList>
            <li>
                <CardSubTitle>CONDITION</CardSubTitle>
            </li>
            <li>
                <CardItem
                    visualStatus={visualComparisonCondition(
                        listing.conditionOverall,
                        rentalComparison.conditionOverall
                    )}
                >
                    Overall:
                </CardItem>{" "}
                {rentalComparison.conditionOverall || "Unknown"}
            </li>
            <li>
                <CardItem
                    visualStatus={visualComparisonCondition(
                        listing.conditionExterior,
                        rentalComparison.conditionExterior
                    )}
                >
                    Exterior:
                </CardItem>{" "}
                {rentalComparison.conditionExterior || "Unknown"}
            </li>
            <li>
                <CardItem
                    visualStatus={visualComparisonCondition(
                        listing.conditionInterior,
                        rentalComparison.conditionInterior
                    )}
                >
                    Interior:
                </CardItem>{" "}
                {rentalComparison.conditionInterior || "Unknown"}
            </li>
            <li>
                <CardItem
                    visualStatus={visualComparisonCondition(
                        listing.conditionKitchen,
                        rentalComparison.conditionKitchen
                    )}
                >
                    Kitchen:
                </CardItem>{" "}
                {rentalComparison.conditionKitchen || "Unknown"}
            </li>
            <li>
                <CardItem
                    visualStatus={visualComparisonCondition(
                        listing.conditionBathroom,
                        rentalComparison.conditionBathroom
                    )}
                >
                    Bathroom:
                </CardItem>{" "}
                {rentalComparison.conditionBathroom || "Unknown"}
            </li>
        </CardList>
    );
};
