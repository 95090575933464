import React, { Component } from "react";
import styled from "styled-components";

import { Heading3 } from "typography";
import ListingContainer from "containers/listing-container";
import { AddListingForm } from "./add-listing-form";

export interface Props {
    className?: string;
}

export class AddListing extends Component<Props> {
    componentDidMount() {
        document.title = "Add New Listing | Peanut";
    }

    render() {
        const { className } = this.props;

        return (
            <div className={className}>
                <Heading>Add a Listing</Heading>
                <ListingContainer>{({ addListing }) => <AddListingForm onSubmit={addListing} />}</ListingContainer>
                <Link
                    href="https://eu-west-1a.online.tableau.com/#/site/unmortgage/views/PropertyFilteringPrototype1/PropertySummaryOps"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    View Tableau
                </Link>
            </div>
        );
    }
}

const Heading = styled.h2`
    ${Heading3}
    margin-bottom: 30px;
`;

const Link = styled.a`
    color: ${(props) => props.theme.grey700};
`;
