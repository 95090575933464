import { action } from "typesafe-actions";
import { ListingActionTypes, Listing } from "./types";
import { TCompsForModeration } from "hooks/use-comps-for-moderation";

export const fetchListingRequest = (listingID: string) => action(ListingActionTypes.FETCH_REQUEST, listingID);
export const fetchListingSuccess = (data: Listing) => action(ListingActionTypes.FETCH_SUCCESS, data);

export const fetchAllListingsRequest = (apiParams?: { [key: string]: string | number }) =>
    action(ListingActionTypes.FETCH_ALL_REQUEST, apiParams);
export const fetchAllListingsSuccess = (data: Listing[]) => action(ListingActionTypes.FETCH_ALL_SUCCESS, data);

export const saveListingRequest = (data: Listing, leaseholdInfoUpdate?: boolean) =>
    action(ListingActionTypes.SAVE_REQUEST, { data, leaseholdInfoUpdate });
export const saveListingSuccess = (data: Listing) => action(ListingActionTypes.SAVE_SUCCESS, data);

export const saveSelectedComps = (comps: TCompsForModeration) => {
    return action(ListingActionTypes.SAVE_SELECTED_COMPS, comps);
};

export const createListingRequest = (sourceID: string, postcode: string, rentalValuationMax: number) =>
    action(ListingActionTypes.CREATE_REQUEST, {
        sourceID,
        postcode,
        rentalValuationMax,
    });
export const createListingSuccess = (listing: Listing) => action(ListingActionTypes.CREATE_SUCCESS, listing);
