import React, { Component } from "react";
import ReactDOM from "react-dom";
import styled from "styled-components";

import { RejectButton } from "components/reject-listing/reject-button";
import { Listing } from "store/listing";
import RejectForm from "components/reject-listing/reject-form";

export interface Props {
    className?: string;
    listingID: string;
    listingStatus: string;
    handleReject: (listing: Listing) => void;
}

interface State {
    isModalShowing: boolean;
}

export class RejectListing extends Component<Props, State> {
    state = { isModalShowing: false };

    openModal = () => {
        this.setState((prevState) => ({ isModalShowing: true }));
    };

    closeModal = () => {
        this.setState((prevState) => ({ isModalShowing: false }));
    };

    render() {
        const { className, listingID, handleReject, listingStatus } = this.props;
        return (
            <Wrapper className={className}>
                <RejectButton onClick={this.openModal} />
                {ReactDOM.createPortal(
                    <RejectForm
                        listingStatus={listingStatus}
                        listingID={listingID}
                        onSubmit={handleReject}
                        isShowing={this.state.isModalShowing}
                        onCancel={this.closeModal}
                    />,
                    document.getElementById("portal") as HTMLElement
                )}
            </Wrapper>
        );
    }
}

const Wrapper = styled.div`
    position: relative;
`;
