import React from "react";
import { Route, Switch } from "react-router";
import { PrivateRoute } from "components/private-route";
import { ValuationRedirect } from "components/valuation-redirect";

import {
    Home,
    Login,
    AddListing,
    AddressMatch,
    ForReview,
    BasicAnalysis,
    RentalValuation,
    RentalValuationSummary,
    RentalModeration,
    SalesValuation,
    SalesValuationSummary,
    CustomerListingSearch,
    Prefiltering,
    SalesModeration,
} from "pages";
import { StatusRedirect } from "components/status-redirect";

export const routePaths = {
    home: "/",
    login: "/login",
    review: "/for-review",
    listing: "/for-review/:listingID",
    reviewed: "/for-review/:listingID/reviewed",
    rejected: "/for-review/:listingID/rejected",
    duplicate: "/for-review/:listingID/duplicate",
    addListing: "/add",
    prefilter: "/for-review/:listingID/prefilter",
    addressMatch: "/for-review/:listingID/address-match",
    basicAnalysis: "/for-review/:listingID/basic-analysis",
    rentalValuation: "/for-review/:listingID/rental-valuation",
    rentalValuationSummary: "/for-review/:listingID/rental-valuation-summary",
    rentalModeration: "/for-review/:listingID/rental-moderation",
    salesValuation: "/for-review/:listingID/sales-valuation",
    salesValuationSummary: "/for-review/:listingID/sales-valuation-summary",
    salesModeration: "/for-review/:listingID/sales-moderation",
    customerListingSearch: "/customer-listing-search",
    sctTool: "https://www.rightmove.co.uk/rightmoveplus/property/preview/details/id/:sourceID.html",
    greenCo: "https://www.rightmove.co.uk/properties/:sourceID",
    greenCoRent:
        "https://www.rightmove.co.uk/property-to-rent/find.html?locationIdentifier=POSTCODE%5E:sourcePostcodeID&radius=1&maxBedrooms=:bedrooms&minBedrooms=:bedrooms&sortType=2&includeLetAgreed=true&viewType=GRID",
    tableauRent:
        "https://eu-west-1a.online.tableau.com/t/unmortgage/views/Yieldcuves/RentalGraph?:embed=y&:showVizHome=no&:host_url=https%3A%2F%2Feu-west-1a.online.tableau.com%2F&:embed_code_version=3&:tabs=no&:toolbar=no&:showAppBanner=false&:display_spinner=no&iframeSizedToWindow=true&PCD=:outcode&Beds=:numOfBeds&:loadOrderID=0",
    purpleCo: "https://www.zoopla.co.uk/for-sale/details/:sourceID",
};

interface Props {
    isLoggedIn: boolean;
}

export const Routes = ({ isLoggedIn }: Props) => (
    <Switch>
        <Route exact={true} path={routePaths.login} component={Login} />
        <PrivateRoute isLoggedIn={isLoggedIn} exact={true} path={routePaths.listing} component={StatusRedirect} />
        <PrivateRoute isLoggedIn={isLoggedIn} exact={true} path={routePaths.home} component={Home} />
        <PrivateRoute isLoggedIn={isLoggedIn} exact={true} path={routePaths.review} component={ForReview} />
        <PrivateRoute isLoggedIn={isLoggedIn} exact={true} path={routePaths.addListing} component={AddListing} />
        <PrivateRoute isLoggedIn={isLoggedIn} exact={true} path={routePaths.prefilter} component={Prefiltering} />
        <PrivateRoute isLoggedIn={isLoggedIn} exact={true} path={routePaths.addressMatch} component={AddressMatch} />
        <PrivateRoute isLoggedIn={isLoggedIn} exact={true} path={routePaths.basicAnalysis} component={BasicAnalysis} />
        <PrivateRoute
            isLoggedIn={isLoggedIn}
            exact={true}
            path={routePaths.rentalValuation}
            component={() => (
                <ValuationRedirect valuationType="rental">
                    <RentalValuation />
                </ValuationRedirect>
            )}
        />
        <PrivateRoute
            isLoggedIn={isLoggedIn}
            exact={true}
            path={routePaths.rentalValuationSummary}
            component={RentalValuationSummary}
        />
        <PrivateRoute
            isLoggedIn={isLoggedIn}
            exact={true}
            path={routePaths.rentalModeration}
            component={RentalModeration}
        />
        <PrivateRoute
            isLoggedIn={isLoggedIn}
            exact={true}
            path={routePaths.salesValuation}
            component={() => (
                <ValuationRedirect valuationType="sales">
                    <SalesValuation />
                </ValuationRedirect>
            )}
        />
        <PrivateRoute
            isLoggedIn={isLoggedIn}
            exact={true}
            path={routePaths.salesValuationSummary}
            component={SalesValuationSummary}
        />
        <PrivateRoute
            isLoggedIn={isLoggedIn}
            exact={true}
            path={routePaths.reviewed}
            component={() => <h1>This listing has already been reviewed and rental valuated.</h1>}
        />
        <PrivateRoute
            isLoggedIn={isLoggedIn}
            exact={true}
            path={routePaths.duplicate}
            component={() => <h1>This listing is a duplicate.</h1>}
        />
        <PrivateRoute
            isLoggedIn={isLoggedIn}
            exact={true}
            path={routePaths.rejected}
            component={() => <h1>This listing has been previously rejected.</h1>}
        />
        <PrivateRoute
            isLoggedIn={isLoggedIn}
            exact={true}
            path={routePaths.customerListingSearch}
            component={CustomerListingSearch}
        />
        <PrivateRoute
            isLoggedIn={isLoggedIn}
            exact={true}
            path={routePaths.salesModeration}
            component={SalesModeration}
        />
        <Route render={() => <h1>Page not found!</h1>} />
    </Switch>
);
