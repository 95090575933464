import React from "react";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";

export interface Props {
    className?: string;
    images: string[];
}

export const ImageCarousel = ({ className, images }: Props) => {
    const formattedImages = images.map((url) => ({ original: url }));
    return (
        <div className={className}>
            <div>
                <ImageGallery
                    items={formattedImages}
                    showThumbnails={false}
                    showFullscreenButton={false}
                    showPlayButton={false}
                />
            </div>
        </div>
    );
};
