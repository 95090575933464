import { number, object, string } from "yup";

export const salesComparisonValidationSchema = object({
    distance: string().required("Property Style is required"),
    propertyType: string().required("Property type is required"),
    propertyStyle: string().required("Property style is required"),
    totalBedrooms: number()
        .min(1, "Property must have at least 1 bedroom")
        .max(10, "Property cannot have more than 10 bedrooms")
        .required("Number of bedrooms is required"),
    totalBathrooms: number()
        .min(1, "Property must have at least 1 bathroom")
        .max(10, "Property cannot have more than 10 bathrooms")
        .required("Number of bathrooms is required"),
    price: number()
        .min(75000, "Minimum price must be at least £75,000")
        .max(750000, "Maximum price cannot be greater than £750,000")
        .required("Price is required"),
    priceDate: string().required("Price date is required"),
    floorArea: number().required("The floor area is required"),
    conditionOverall: string().required("The overall property condition must be specified"),
    conditionExterior: string().required("The property's exterior condition must be specified"),
    conditionInterior: string().required("The property's interior condition must be specified"),
    conditionBathroom: string().required("The property's bathroom condition must be specified"),
    conditionKitchen: string().required("The property's kitchen condition must be specified"),
    notes: string()
        .min(50, "Please add comments for this property - min 50 characters")
        .required("Please add comments for this property")
});
