import { Listing } from "store/listing";
import { Choice } from "components/form/multi-choice-button";

export const propertyDetailFormData: { [key in keyof Listing]?: Choice[] } = {
    propertyType: [
        { displayName: "House", name: "propertyType", value: "house" },
        { displayName: "Flat", name: "propertyType", value: "flat" },
    ],
    propertyStyle: [
        { displayName: "Detached", name: "propertyStyle", value: "detached", extraInfo: "house" },
        { displayName: "Semi Detached", name: "propertyStyle", value: "semi-detached", extraInfo: "house" },
        { displayName: "Terraced", name: "propertyStyle", value: "terraced", extraInfo: "house" },
        { displayName: "End of terrace", name: "propertyStyle", value: "end-of-terrace", extraInfo: "house" },
        { displayName: "Purpose-built", name: "propertyStyle", value: "purpose-built", extraInfo: "flat" },
        { displayName: "Converted", name: "propertyStyle", value: "converted", extraInfo: "flat" },
    ],
    propertyTenure: [
        { displayName: "Freehold", name: "propertyTenure", value: "freehold" },
        { displayName: "Freehold with estate fee", name: "propertyTenure", value: "freehold-with-estate-fee" },
        { displayName: "Share of Freehold", name: "propertyTenure", value: "share-of-freehold" },
        { displayName: "Leasehold", name: "propertyTenure", value: "leasehold" },
        { displayName: "Unknown", name: "propertyTenure", value: "unknown" },
    ],
    totalDoubleBedrooms: [
        { displayName: "0 double", name: "totalDoubleBedrooms", value: 0 },
        { displayName: "1 double", name: "totalDoubleBedrooms", value: 1 },
        { displayName: "2 double", name: "totalDoubleBedrooms", value: 2 },
        { displayName: "3 double", name: "totalDoubleBedrooms", value: 3 },
        { displayName: "4 double", name: "totalDoubleBedrooms", value: 4 },
        { displayName: "5 double", name: "totalDoubleBedrooms", value: 5 },
    ],
    totalSingleBedrooms: [
        { displayName: "0 single", name: "totalSingleBedrooms", value: 0 },
        { displayName: "1 single", name: "totalSingleBedrooms", value: 1 },
        { displayName: "2 single", name: "totalSingleBedrooms", value: 2 },
        { displayName: "3 single", name: "totalSingleBedrooms", value: 3 },
        { displayName: "4 single", name: "totalSingleBedrooms", value: 4 },
        { displayName: "5 single", name: "totalSingleBedrooms", value: 5 },
    ],
    totalBathrooms: [
        { displayName: "0 bathrooms", name: "totalBathrooms", value: 0 },
        { displayName: "1 bathroom", name: "totalBathrooms", value: 1 },
        { displayName: "2 bathrooms", name: "totalBathrooms", value: 2 },
        { displayName: "3 bathrooms", name: "totalBathrooms", value: 3 },
        { displayName: "4 bathrooms", name: "totalBathrooms", value: 4 },
        { displayName: "5 bathrooms", name: "totalBathrooms", value: 5 },
    ],
    totalWCs: [
        { displayName: "0 WCs", name: "totalWCs", value: 0 },
        { displayName: "1 WC", name: "totalWCs", value: 1 },
        { displayName: "2 WCs", name: "totalWCs", value: 2 },
        { displayName: "3 WCs", name: "totalWCs", value: 3 },
        { displayName: "4 WCs", name: "totalWCs", value: 4 },
        { displayName: "5 WCs", name: "totalWCs", value: 5 },
    ],
    floorAreaSource: [
        { displayName: "n/a", name: "floorAreaSource", value: "n/a" },
        { displayName: "EPC", name: "floorAreaSource", value: "epc" },
        { displayName: "Floorplan", name: "floorAreaSource", value: "floorplan" },
        { displayName: "SCT", name: "floorAreaSource", value: "sct" },
        { displayName: "Listing Description", name: "floorAreaSource", value: "listing-description" },
        { displayName: "Nearby Property", name: "floorAreaSource", value: "nearby-property" },
    ],
    hasOutsideSpace: [
        { displayName: "Y", name: "hasOutsideSpace", value: "true" },
        { displayName: "N", name: "hasOutsideSpace", value: "false" },
        { displayName: "Unk", name: "hasOutsideSpace", value: "unknown" },
    ],
    hasParking: [
        { displayName: "Y", name: "hasParking", value: "true" },
        { displayName: "N", name: "hasParking", value: "false" },
        { displayName: "Unk", name: "hasParking", value: "unknown" },
    ],
    councilTaxBand: [
        { displayName: "A", name: "councilTaxBand", value: "A" },
        { displayName: "B", name: "councilTaxBand", value: "B" },
        { displayName: "C", name: "councilTaxBand", value: "C" },
        { displayName: "D", name: "councilTaxBand", value: "D" },
        { displayName: "E", name: "councilTaxBand", value: "E" },
        { displayName: "F", name: "councilTaxBand", value: "F" },
        { displayName: "G", name: "councilTaxBand", value: "G" },
        { displayName: "H", name: "councilTaxBand", value: "H" },
        { displayName: "Unk", name: "councilTaxBand", value: "unknown" },
    ],
    age: [
        { displayName: "", name: "age", value: "" },
        { displayName: "Period (Pre-1914)", name: "age", value: "Period (Pre-1914)" },
        { displayName: "War/Interwar (1914-1945)", name: "age", value: "War/Interwar (1914-1945)" },
        { displayName: "Post War (1946-1979)", name: "age", value: "Post War (1946-1979)" },
        { displayName: "Pre-2000 Modern (1980-1999)", name: "age", value: "Pre-2000 Modern (1980-1999)" },
        { displayName: "Post-2000 Modern (2000-Today)", name: "age", value: "Post-2000 Modern (2000-Today)" },
    ],
    conditionOverall: [
        { displayName: "Exlnt", name: "conditionOverall", value: "excellent" },
        { displayName: "Good", name: "conditionOverall", value: "good" },
        { displayName: "OK", name: "conditionOverall", value: "okay" },
        { displayName: "Bad", name: "conditionOverall", value: "bad" },
        { displayName: "Unk", name: "conditionOverall", value: "unknown" },
    ],
    conditionExterior: [
        { displayName: "Exlnt", name: "conditionExterior", value: "excellent" },
        { displayName: "Good", name: "conditionExterior", value: "good" },
        { displayName: "OK", name: "conditionExterior", value: "okay" },
        { displayName: "Bad", name: "conditionExterior", value: "bad" },
        { displayName: "Unk", name: "conditionExterior", value: "unknown" },
    ],
    conditionInterior: [
        { displayName: "Exlnt", name: "conditionInterior", value: "excellent" },
        { displayName: "Good", name: "conditionInterior", value: "good" },
        { displayName: "OK", name: "conditionInterior", value: "okay" },
        { displayName: "Bad", name: "conditionInterior", value: "bad" },
        { displayName: "Unk", name: "conditionInterior", value: "unknown" },
    ],
    conditionKitchen: [
        { displayName: "Exlnt", name: "conditionKitchen", value: "excellent" },
        { displayName: "Good", name: "conditionKitchen", value: "good" },
        { displayName: "OK", name: "conditionKitchen", value: "okay" },
        { displayName: "Bad", name: "conditionKitchen", value: "bad" },
        { displayName: "Unk", name: "conditionKitchen", value: "unknown" },
    ],
    conditionBathroom: [
        { displayName: "Exlnt", name: "conditionBathroom", value: "excellent" },
        { displayName: "Good", name: "conditionBathroom", value: "good" },
        { displayName: "OK", name: "conditionBathroom", value: "okay" },
        { displayName: "Bad", name: "conditionBathroom", value: "bad" },
        { displayName: "Unk", name: "conditionBathroom", value: "unknown" },
    ],
};
