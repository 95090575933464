import React from "react";
import styled, { keyframes } from "styled-components";

export interface Props {
    className?: string;
}

export const LoadingIndicator = ({ className }: Props) => (
    <Wrapper className={className}>
        <LoadingIcon />
    </Wrapper>
);

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

const Wrapper = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 3;
`;

const LoadingIcon = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 124px;
    height: 124px;
    background: url("https://storage.googleapis.com/unmortgage-static/peanut-loading.svg") center no-repeat;
    background-size: contain;
    animation: ${rotate} 2s infinite;
`;
