import React, { Component } from "react";
import styled from "styled-components";
import { Formik, FastField, FastFieldProps } from "formik";
import { formatDistanceStrict } from "date-fns";

import { generateEPCLink } from "utils/url-helpers";
import { Button, GreyButton } from "components/button";
import { Input } from "components/form/input";
import { RentalComparison } from "store/rental-comparison/types";
import { Listing } from "store/listing";
import { rentalComparisonFormData as formData } from "./rental-comparison-form-data";
import { rentalComparisonValidationSchema } from "./rental-comparison-validation-schema";
import { Label, HorizontalMultiChoiceButton, FormErrors, FormDatePicker, FormLink } from "components/form";
import { Select } from "components/select";
import { CardSubTitle, CardTitle } from "components/comparable";
import { withTimeTracking, TimeTrackingProps } from "hoc/with-time-tracking";
import {
    needsCompletion,
    visualRentalStatus,
    visualDistance,
    visualComparisonType,
    visualComparisonRoomNumbers,
    visualComparisonFloorArea,
    visualComparisonOutsideFeature,
    visualAge,
    visualComparisonCondition,
} from "utils/data-helpers";

export interface Props {
    listingID: string;
    rentalComparison?: RentalComparison;
    listing: Listing;
    saveRentalComparison: (rentalComparison: RentalComparison) => void;
    onCancel?: () => void;
    onDismiss?: () => void;
}

export class RentalComparisonForm extends Component<Props & TimeTrackingProps> {
    handleSubmit = (values: RentalComparison) => {
        const { saveRentalComparison, getElapsedTaskTime, listingID } = this.props;

        saveRentalComparison({
            ...values,
            listingID,
            rentalComparisonCompletedIn: getElapsedTaskTime(),
            isComplete: true,
        });
    };

    render() {
        const { rentalComparison, onCancel, onDismiss, listing } = this.props;
        const initialValues: RentalComparison = {
            ID: rentalComparison?.ID ?? "",
            listingID: rentalComparison?.listingID,
            comparisonType: "rental",
            images: rentalComparison?.images,
            floorPlans: rentalComparison?.floorPlans,
            address: rentalComparison?.address,
            postcode: rentalComparison?.postcode,
            distance: rentalComparison?.distance ?? "unknown",
            propertyType: rentalComparison?.propertyType,
            propertyStyle: rentalComparison?.propertyStyle ?? "detached",
            monthlyRent: rentalComparison?.monthlyRent,
            rentalType: rentalComparison?.rentalType,
            rentalDate: rentalComparison?.rentalDate,
            totalBedrooms: rentalComparison?.totalBedrooms,
            totalBathrooms: rentalComparison?.totalBathrooms,
            floorArea: rentalComparison?.floorArea,
            hasOutsideSpace: rentalComparison?.hasOutsideSpace ?? "unknown",
            hasParking: rentalComparison?.hasParking ?? "unknown",
            conditionOverall: rentalComparison?.conditionOverall ?? "unknown",
            conditionExterior: rentalComparison?.conditionExterior ?? "unknown",
            conditionInterior: rentalComparison?.conditionInterior ?? "unknown",
            conditionBathroom: rentalComparison?.conditionBathroom ?? "unknown",
            conditionKitchen: rentalComparison?.conditionKitchen ?? "unknown",
            notes: rentalComparison?.notes,
        };

        return (
            <Formik
                onSubmit={this.handleSubmit}
                initialValues={initialValues}
                validateOnChange={false}
                validateOnBlur={false}
                validationSchema={rentalComparisonValidationSchema}
                render={(props) => (
                    <>
                        <CardTitle>{props.values.address || "No Address"}</CardTitle>
                        <FormContainer onSubmit={props.handleSubmit}>
                            <FlexContainer>
                                <FlexItem>
                                    <CardSubTitle>Property</CardSubTitle>
                                    <StyledLabel>
                                        <LabelText
                                            needsCompletion={needsCompletion(props.values.distance)}
                                            visualStatus={visualDistance(props.values.distance)}
                                        >
                                            Distance
                                        </LabelText>
                                        <StyledSelect
                                            name="distance"
                                            value={props.values.distance}
                                            onChange={props.handleChange}
                                        >
                                            {formData.distance &&
                                                formData.distance.map((data) => (
                                                    <option key={data.value} value={data.value}>
                                                        {data.displayName}
                                                    </option>
                                                ))}
                                        </StyledSelect>
                                    </StyledLabel>

                                    <StyledLabel>
                                        <LabelText
                                            visualStatus={visualComparisonType(
                                                props.values.propertyType,
                                                props.values.propertyStyle,
                                                listing.propertyType,
                                                listing.propertyStyle
                                            )}
                                        >
                                            Type
                                        </LabelText>
                                        <InlineHorizontalMultiChoiceButton
                                            choices={formData.propertyType || []}
                                            onChange={props.handleChange}
                                            selectedValue={props.values.propertyType}
                                        />
                                    </StyledLabel>

                                    <StyledLabel>
                                        <LabelText needsCompletion={needsCompletion(props.values.propertyStyle)}>
                                            Style
                                        </LabelText>
                                        <StyledSelect
                                            name="propertyStyle"
                                            value={props.values.propertyStyle}
                                            onChange={props.handleChange}
                                        >
                                            {formData.propertyStyle &&
                                                formData.propertyStyle.map((data) => (
                                                    <option key={data.value} value={data.value}>
                                                        {data.displayName}
                                                    </option>
                                                ))}
                                        </StyledSelect>
                                    </StyledLabel>

                                    <StyledLabel>
                                        <LabelText
                                            needsCompletion={needsCompletion(props.values.totalBedrooms)}
                                            visualStatus={visualComparisonRoomNumbers(
                                                props.values.totalBedrooms,
                                                listing.bedrooms
                                            )}
                                        >
                                            Bedrooms
                                        </LabelText>
                                        <NumberInput
                                            name="totalBedrooms"
                                            value={props.values.totalBedrooms}
                                            onChange={props.handleChange}
                                        />
                                    </StyledLabel>

                                    <StyledLabel>
                                        <LabelText
                                            needsCompletion={needsCompletion(props.values.totalBathrooms)}
                                            visualStatus={visualComparisonRoomNumbers(
                                                props.values.totalBathrooms,
                                                listing.totalBathrooms
                                            )}
                                        >
                                            Bathrooms
                                        </LabelText>
                                        <NumberInput
                                            name="totalBathrooms"
                                            value={props.values.totalBathrooms}
                                            onChange={props.handleChange}
                                        />
                                    </StyledLabel>

                                    <StyledLabel>
                                        <LabelText
                                            needsCompletion={needsCompletion(props.values.floorArea)}
                                            visualStatus={visualComparisonFloorArea(
                                                listing.floorArea,
                                                props.values.floorArea
                                            )}
                                        >
                                            Floor Area
                                        </LabelText>
                                        <FloorAreaInput
                                            name="floorArea"
                                            value={props.values.floorArea}
                                            onChange={props.handleChange}
                                        />
                                        <InputSuffix>
                                            m<sup>2</sup>
                                        </InputSuffix>
                                        <FormLink
                                            href={generateEPCLink(
                                                props.values.address || "",
                                                props.values.postcode || ""
                                            )}
                                            target="_blank"
                                        >
                                            EPC LINK
                                        </FormLink>
                                    </StyledLabel>

                                    <StyledLabel>
                                        <LabelText
                                            needsCompletion={needsCompletion(props.values.hasOutsideSpace)}
                                            visualStatus={visualComparisonOutsideFeature(
                                                listing.hasOutsideSpace,
                                                props.values.hasOutsideSpace
                                            )}
                                        >
                                            Outside
                                        </LabelText>
                                        <InlineHorizontalMultiChoiceButton
                                            choices={formData.hasOutsideSpace || []}
                                            onChange={props.handleChange}
                                            selectedValue={props.values.hasOutsideSpace}
                                        />
                                    </StyledLabel>

                                    <StyledLabel>
                                        <LabelText
                                            needsCompletion={needsCompletion(props.values.hasParking)}
                                            visualStatus={visualComparisonOutsideFeature(
                                                listing.hasParking,
                                                props.values.hasParking
                                            )}
                                        >
                                            Parking
                                        </LabelText>
                                        <InlineHorizontalMultiChoiceButton
                                            choices={formData.hasParking || []}
                                            onChange={props.handleChange}
                                            selectedValue={props.values.hasParking}
                                        />
                                    </StyledLabel>
                                </FlexItem>

                                <FlexItem>
                                    <CardSubTitle>Transaction</CardSubTitle>
                                    <StyledLabel>
                                        <LabelText
                                            needsCompletion={needsCompletion(props.values.rentalType)}
                                            visualStatus={visualRentalStatus(props.values.rentalType)}
                                        >
                                            Status
                                        </LabelText>
                                        <StyledSelect
                                            name="rentalType"
                                            value={props.values.rentalType}
                                            onChange={props.handleChange}
                                        >
                                            {formData.rentalType &&
                                                formData.rentalType.map((data) => (
                                                    <option key={data.value} value={data.value}>
                                                        {data.displayName}
                                                    </option>
                                                ))}
                                        </StyledSelect>
                                    </StyledLabel>

                                    <StyledLabel>
                                        <LabelText
                                            needsCompletion={needsCompletion(props.values.rentalDate)}
                                            visualStatus={visualAge(
                                                formatDistanceStrict(
                                                    new Date(props.values.rentalDate || Date.now()),
                                                    Date.now()
                                                )
                                            )}
                                        >
                                            Age
                                        </LabelText>
                                        <FormDatePicker
                                            name="rentalDate"
                                            value={props.values.rentalDate}
                                            onChange={props.setFieldValue}
                                        />
                                    </StyledLabel>

                                    <StyledLabel>
                                        <LabelText needsCompletion={needsCompletion(props.values.monthlyRent)}>
                                            Price
                                        </LabelText>
                                        <Input
                                            name="monthlyRent"
                                            value={props.values.monthlyRent}
                                            onChange={props.handleChange}
                                        />
                                    </StyledLabel>
                                </FlexItem>

                                <FlexItem>
                                    <CardSubTitle>Condition</CardSubTitle>
                                    {Object.keys(formData).map((key) =>
                                        [
                                            "conditionOverall",
                                            "conditionExterior",
                                            "conditionInterior",
                                            "conditionBathroom",
                                            "conditionKitchen",
                                        ].includes(key) ? (
                                            <React.Fragment key={key}>
                                                <StyledLabel>
                                                    <LabelText
                                                        needsCompletion={needsCompletion(props.values[key])}
                                                        visualStatus={visualComparisonCondition(
                                                            listing[key],
                                                            props.values[key]
                                                        )}
                                                    >
                                                        {key.replace("condition", "")}
                                                    </LabelText>
                                                    <InlineHorizontalMultiChoiceButton
                                                        choices={formData[key]}
                                                        onChange={props.handleChange}
                                                        selectedValue={props.values[key]}
                                                    />
                                                </StyledLabel>
                                            </React.Fragment>
                                        ) : null
                                    )}
                                </FlexItem>
                            </FlexContainer>

                            <NotesContainer>
                                <StyledLabel>
                                    <NotesLabelText needsCompletion={needsCompletion(props.values.notes)}>
                                        Notes (min 50 characters)
                                    </NotesLabelText>
                                    <FastField
                                        name="notes"
                                        value={props.values.notes}
                                        render={({ field }: FastFieldProps) => (
                                            <TextAreaInput
                                                as="textarea"
                                                {...field}
                                                minLength={50}
                                                value={
                                                    field.value ||
                                                    "[Status / Date]\n[RELATIVE Initial Impression]\n[RELATIVE Size / Layout]\n[RELATIVE Condition]\n[RELATIVE Locale]\n[RELATIVE Other]\n[RELATIVE Conclusion]"
                                                }
                                            />
                                        )}
                                    />
                                </StyledLabel>
                                {Object.keys(props.errors).length > 0 && (
                                    <FormErrors>
                                        <ul>
                                            {Object.keys(props.errors).map((error, index) => (
                                                <li key={index}>{props.errors[error]}</li>
                                            ))}
                                        </ul>
                                    </FormErrors>
                                )}
                            </NotesContainer>
                            <Button type="submit">Save</Button>
                            <StyledGreyButton onClick={onCancel} type="button">
                                Cancel
                            </StyledGreyButton>
                            <StyledGreyButton onClick={onDismiss} type="button">
                                Dismiss
                            </StyledGreyButton>
                        </FormContainer>
                    </>
                )}
            />
        );
    }
}

const FormContainer = styled.form`
    margin: 0 0 40px;
`;

const FlexContainer = styled.div`
    display: flex;
`;

const FlexItem = styled.div`
    margin-right: 24px;
`;

const NumberInput = styled(Input)`
    width: 153px;
`;

const InputSuffix = styled.span`
    text-transform: lowercase;
    sup {
        vertical-align: super;
        font-size: 10px;
    }
`;

const StyledSelect = styled(Select)`
    margin: 0;
`;

const StyledLabel = styled(Label)`
    display: block;
    margin: 8px 0;
`;

const LabelText = styled.span<{ needsCompletion?: boolean; visualStatus?: string }>`
    &::before {
        content: " ";
        background: ${(props) => props.visualStatus};
        display: inline-block;
        width: 12px;
        height: 12px;
        position: relative;
        margin-right: 4px;
    }

    display: inline-block;
    min-width: 80px;
    margin: 8px 16px 4px 0;

    ${(props) =>
        props.needsCompletion === true &&
        `
        border: 2px solid #2ccda9;
        padding: 2px;
        border-radius: 4px;
        width: 100%;
        background-color: #d3f1ea;
    `}
`;

const FloorAreaInput = styled(Input)`
    width: 125px;
    margin-right: 8px;
`;

const TextAreaInput = styled(Input)`
    display: block;
    padding: 8px;
    width: 600px;
    height: 150px;
`;

const NotesContainer = styled.div`
    margin: 8px 0;
`;

const NotesLabelText = styled(LabelText)<{ needsCompletion?: boolean }>`
    padding-top: 8px;
    vertical-align: top;

    ${(props) =>
        props.needsCompletion === true &&
        `
        border: 2px solid #2ccda9;
        padding: 2px;
        border-radius: 4px;
        width: 600px;
    `}
`;

const InlineHorizontalMultiChoiceButton = styled(HorizontalMultiChoiceButton)`
    display: inline-flex;
`;

const StyledGreyButton = styled(GreyButton)`
    margin-left: 8px;
`;

export default withTimeTracking(RentalComparisonForm);
