export interface ListingOptionalFields {
    sourceID?: string;
    purplecoID?: string;
    greencoID?: string;
    sourcePostcodeID?: string;
    UDPRN?: string;
    UPRN?: string;
    status?: ListingStatusTypes;
    title?: string;
    address?: string;
    postcode?: string;
    outcode?: string;
    longitude?: string;
    latitude?: string;
    mainImage?: string;
    imageUrls?: string[];
    propertyType?: PropertyType;
    propertyStyle?: PropertyStyle;
    propertyTenure?: PropertyTenure;
    price?: number;
    priceValuationNotes?: string;
    predictedMonthlyRent?: number;
    modelPredictedMonthlyRent?: number;
    manualPredictedMonthlyRent?: number;
    rentalValuationNotes?: string;
    rejectReason?: string;
    rejectionNotes?: string;
    rejectionHierarchy?: {
        level1: string;
        level2: string;
        level3: string;
    };
    rejectedCompletedIn?: number;
    floorNumber?: string;
    numberOfFloors?: number;
    bedrooms?: number;
    totalRooms?: number;
    totalSingleBedrooms?: number;
    totalDoubleBedrooms?: number;
    totalBathrooms?: number;
    totalWCs?: number;
    floorArea?: number | "unknown";
    floorAreaSource?: string;
    hasOutsideSpace?: boolean | "unknown";
    hasParking?: boolean | "unknown";
    councilTaxBand?: string;
    councilTaxPrice?: number | "unknown";
    age?: BuildingAgeType;
    EPCCurrent?: number | "unknown";
    EPCPotential?: number | "unknown";
    conditionOverall?: PropertyCondition;
    conditionKitchen?: PropertyCondition;
    conditionInterior?: PropertyCondition;
    conditionBathroom?: PropertyCondition;
    conditionExterior?: PropertyCondition;
    conditionNotes?: string;
    isOverallGood?: boolean | null;
    isKitchenGood?: boolean | null;
    isBathroomGood?: boolean | null;
    isExteriorGood?: boolean | null;
    isLocationGood?: boolean | null;
    isFloorPlanGood?: boolean | null;
    isRentSensible?: boolean | null;
    rentalYield?: number;
    prefilterCompletedIn?: number;
    addressMatchCompletedIn?: number;
    basicAnalysisCompletedIn?: number;

    rentalValuationCompletedIn?: number;
    rentalValuationCompletedBy?: string;
    rentalValuationMinMonthlyRent?: number;
    rentalValuationMaxMonthlyRent?: number;

    rentalValuationTwoCompletedIn?: number;
    rentalValuationTwoCompletedBy?: string;
    rentalValuationTwoMinMonthlyRent?: number;
    rentalValuationTwoMaxMonthlyRent?: number;

    rentalModerationCompletedIn?: number;
    rentalModerationCompletedBy?: string;
    moderatedMinMonthlyRent?: number;
    moderatedMaxMonthlyRent?: number;
    rentalModerationNotes?: string;

    salesValuationMinPrice?: number;
    salesValuationMaxPrice?: number;
    salesValuationCompletedIn?: number;
    salesValuationCompletedBy?: string;

    salesValuationTwoMinPrice?: number;
    salesValuationTwoMaxPrice?: number;
    salesValuationTwoCompletedIn?: number;
    salesValuationTwoCompletedBy?: string;

    salesModerationCompletedIn?: number;
    salesModerationCompletedBy?: string;
    moderatedMinPrice?: number;
    moderatedMaxPrice?: number;
    salesModerationNotes?: string;

    qaPrefilterCompletedIn?: number;
    qaAddressMatchCompletedIn?: number;
    qaBasicAnalysisCompletedIn?: number;
    qaRentalValuationCompletedIn?: number;
    qaRentalValuationTwoCompletedIn?: number;
    qaRentalModerationCompletedIn?: number;
    qaSalesValuationCompletedIn?: number;
    qaSalesValuationTwoCompletedIn?: number;
    qaSalesModerationCompletedIn?: number;

    serviceCharge?: number | "unknown" | null;
    leaseDate?: string | "unknown" | null;
    leaseLength?: number | "unknown" | null;
    preliminaryYearsRemaining?: number | "unknown" | null;
    groundRent?: number | "unknown" | null;
    ranking?: number | null;
}

export interface Listing extends ListingOptionalFields {
    ID: string;
}

export type ListingStatusTypes =
    | "new"
    | "prefilter"
    | "addressMatch"
    | "basicAnalysis"
    | "rentalValuation"
    | "rentalValuationTwo"
    | "rentalModeration"
    | "salesValuation"
    | "salesValuationTwo"
    | "salesModeration"
    | "reviewed"
    | "rejected"
    | "duplicate";

export type PropertyTenure = "freehold" | "share-of-freehold" | "leasehold" | "unknown";
export type PropertyType = "house" | "flat";
export type PropertyStyle =
    | "detached"
    | "semi-detached"
    | "terraced"
    | "end-of-terrace"
    | "purpose-built"
    | "converted";
export type PropertyCondition = "excellent" | "good" | "okay" | "bad" | "unknown";

export const ListingActionTypes = {
    FETCH_ALL_REQUEST: "listing/FETCH_ALL_REQUEST",
    FETCH_ALL_SUCCESS: "listing/FETCH_ALL_SUCCESS",
    FETCH_REQUEST: "listing/FETCH_REQUEST",
    FETCH_SUCCESS: "listing/FETCH_SUCCESS",
    SAVE_REQUEST: "listing/SAVE_REQUEST",
    SAVE_SUCCESS: "listing/SAVE_SUCCESS",
    CREATE_REQUEST: "listing/CREATE_REQUEST",
    CREATE_SUCCESS: "listing/CREATE_SUCCESS",
    SAVE_SELECTED_COMPS: "listing/SAVE_SELECTED_COMPS",
};

export interface ListingState {
    readonly byID: { [listingID: string]: Listing };
    readonly allIDs: string[];
}

type BuildingAgeType =
    | "Period (Pre-1914)"
    | "War/Interwar (1914-1945)"
    | "Post War (1946-1979)"
    | "Pre-2000 Modern (1980-1999)"
    | "Post-2000 Modern (2000-Today)";
