import styled from "styled-components";

import { Body, Heading3 } from "typography";

export const ListContent = styled.div`
    display: flex;
    flex-direction: column;
`;

export const MainWrapper = styled.section`
    display: flex;
    justify-content: space-between;
    border-radius: 4px;
    background: ${({ theme }) => theme.grey200};
    margin: 8px 0;
`;

export const MainContentWrapper = styled.div`
    min-width: 548px;
    padding: 16px;
    flex-grow: 1;
`;

export const CardContentWrapper = styled.div`
    display: flex;
    justify-content: flex-start;
    padding-right: 16px;
`;

export const CardTitle = styled.h2`
    ${Heading3};
    margin-bottom: 16px;
`;

export const CardSubTitle = styled.h3`
    font-weight: 600;
    margin-bottom: 16px;
`;

export const CardList = styled.ul`
    margin-right: 24px;

    li {
        font-size: 16px;
        margin: 8px 0;
    }
`;

export const CardItem = styled.span<{ visualStatus?: string }>`
    &::before {
        content: " ";
        background: ${(props) => props.visualStatus};
        display: inline-block;
        width: 12px;
        height: 12px;
        position: relative;
        margin-right: 4px;
    }

    display: inline;
    min-width: 110px;
    color: ${({ theme }) => theme.grey600};
`;

export const ValuationLabel = styled.span`
    display: inline-block;
    background: ${({ theme }) => theme.grey700};
    padding: 10px 8px;
    border-radius: ${({ theme }) => theme.radius1}px;
    color: #ffffff;
    font-size: 14px;
    font-weight: 700;
    margin: 0 8px 16px 0;
`;

export const NotesWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

export const CardNoteWrapper = styled.div`
    white-space: normal;
    overflow-wrap: break-word;
    flex: 50%;
    width: 50%;
`;

export const ValuationHeader = styled.h2`
    color: #8e9393;
    font-weight: 700;
`;

export const CardNotes = styled.p`
    ${Body};
    margin: 6px 15px 6px 0;
    max-width: 560px;
    white-space: pre-wrap;
`;

export const NotesHeader = styled(CardSubTitle)`
    margin-top: 15px;
`;
