import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useDispatch } from "react-redux";

import withListing from "hoc/with-listing";
import { Listing, saveListingRequest, saveSelectedComps } from "store/listing";
import { AnalysisLayout } from "components/analysis-layout";
import { Heading2 } from "typography";
import { SalesModerationForm } from "./sales-moderation-form";
import { EvaluateCard } from "components/comparable/evaluate-card";
import { SalesInfo } from "components/comparable/sales-info";
import { ModerationInfoPanel } from "components/moderation-info-panel";
import { TSortOptions, TSortOrder, useCompsForModeration } from "hooks/use-comps-for-moderation";
import { routePaths } from "routes";
import { Label } from "components/form/label";
import { Select } from "components/select";
import { updateSalesComparisonBySourceID } from "store/sales-comparison";

export interface Props {
    className?: string;
    listing: Listing;
    saveListing: typeof saveListingRequest;
}

export const generateSalesTargetYield = (
    monthlyRent: number | null | undefined,
    targetYieldPercent: number
): number => {
    const yieldPercentInDecimals = targetYieldPercent / 100;
    const annualRent = (monthlyRent || 0) * 12;
    return monthlyRent ? Math.round(annualRent / yieldPercentInDecimals) : 0;
};

export const SalesModeration: React.FC<Props> = ({ listing, saveListing, className }) => {
    const dispatch = useDispatch();
    const [sort, setSort] = useState<{ by: TSortOptions; order: TSortOrder }>({ by: "price", order: "desc" });
    const [displayErrorForComps, setDisplayErrorForComps] = useState<boolean>(false);
    const { allComps, dedupedComps } = useCompsForModeration(listing.ID, "sales", sort.by, sort.order);

    const dismissedComps = dedupedComps.filter((comparable) => comparable.isDismissed);
    const salesCompsSelected = dedupedComps.filter((comparable) => !comparable.isDismissed);

    const minNumCompsSelected = salesCompsSelected.length === 2;

    useEffect(() => {
        document.title = "Sales Moderation | Peanut";
    }, []);

    const handleSortChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const selectValues = event.target.value.split("-");
        const [sortByValue, sortOrderValue] = selectValues;

        setSort({ by: sortByValue as TSortOptions, order: sortOrderValue as TSortOrder });
    };

    const handleSaveComps = () => {
        dispatch(saveSelectedComps(allComps));
    };

    const handleRanking = (ID: string | number | undefined, ranking: number) => {
        const rankingCompsIndex = allComps.findIndex((comp) => comp.ID === ID);
        allComps[rankingCompsIndex].ranking = Number(ranking);

        const rankingSelectedIndex = salesCompsSelected.findIndex((comp) => comp.ID === ID);
        salesCompsSelected[rankingSelectedIndex].ranking = Number(ranking);
    };

    return listing ? (
        <AnalysisLayout className={className} listing={listing}>
            {displayErrorForComps && (
                <ErrorMessage>
                    There must be a minimum of 2 comparables un-dismissed. To Dismiss this listing, reinstate one that
                    you have previously dismissed
                </ErrorMessage>
            )}

            <ComparablesHeader>
                <SectionHeading>Comparables ({salesCompsSelected.length})</SectionHeading>
                <div>
                    <Label htmlFor="sort">Sort by</Label>
                    <Select id="sort" onChange={handleSortChange}>
                        <option value="price-desc">Price, High to Low</option>
                        <option value="price-asc">Price, Low to High</option>
                        <option value="distance-desc">Distance, High to Low</option>
                        <option value="distance-asc">Distance, Low to High</option>
                        <option value="totalBedrooms-desc">Bedrooms, High to Low</option>
                        <option value="totalBedrooms-asc">Bedrooms, Low to High</option>
                        <option value="floorArea-desc">Floor area, High to Low</option>
                        <option value="floorArea-asc">Floor area, Low to High</option>
                        <option value="selectedBy-asc">Valuer</option>
                        <option value="priceDate-desc">Date, Newest to Oldest</option>
                        <option value="priceDate-asc">Date, Oldest to Newest</option>
                    </Select>
                </div>
            </ComparablesHeader>
            <ComparablesContainer>
                {salesCompsSelected.map((comp) => (
                    <EvaluateCard
                        key={comp.ID}
                        compId={comp.ID}
                        imageUrls={comp.images?.length ? comp.images : []}
                        floorPlans={comp.floorPlans?.length ? comp.floorPlans : []}
                        listingURL={routePaths.sctTool.replace(":sourceID", comp.sourceID || "")}
                        showRanking={true}
                        existingRanking={comp.ranking}
                        handleRanking={handleRanking}
                        onDismiss={() => {
                            minNumCompsSelected
                                ? setDisplayErrorForComps(true)
                                : dispatch(updateSalesComparisonBySourceID(comp.sourceID, true));
                        }}
                    >
                        <SalesInfo
                            salesComparison={comp}
                            listing={listing}
                            isValuation1={comp.selectedByValuation1}
                            isValuation2={comp.selectedByValuation2}
                        />
                    </EvaluateCard>
                ))}
            </ComparablesContainer>

            <ListHeading>Dismissed ({dismissedComps.length})</ListHeading>
            <DismissedFade>
                {dismissedComps.map((comparable) => (
                    <EvaluateCard
                        key={comparable.ID}
                        imageUrls={comparable.images?.length ? comparable.images : []}
                        floorPlans={comparable.floorPlans?.length ? comparable.floorPlans : []}
                        listingURL={comparable.sourceURL || ""}
                        isComplete={comparable.isComplete}
                        onReinstate={() => {
                            dispatch(updateSalesComparisonBySourceID(comparable.sourceID, false));
                            setDisplayErrorForComps(false);
                        }}
                    >
                        <SalesInfo salesComparison={comparable} listing={listing} />
                    </EvaluateCard>
                ))}
            </DismissedFade>

            <StickyModInfoPanel
                val1Min={listing.salesValuationMinPrice || 0}
                val1Max={listing.salesValuationMaxPrice || 0}
                val2Min={listing.salesValuationTwoMinPrice || 0}
                val2Max={listing.salesValuationTwoMaxPrice || 0}
                lowYield={generateSalesTargetYield(listing.moderatedMaxMonthlyRent, 4.9)}
                highYield={generateSalesTargetYield(listing.moderatedMaxMonthlyRent, 5)}
            >
                <SalesModerationForm
                    listingID={listing.ID}
                    updateListing={saveListing}
                    status={listing.status}
                    handleSaveComps={handleSaveComps}
                    salesComps={salesCompsSelected}
                />
            </StickyModInfoPanel>
        </AnalysisLayout>
    ) : null;
};

const SectionHeading = styled.h2`
    ${Heading2};
    margin: 30px 0;
    font-weight: 500;
`;

const ComparablesHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const ComparablesContainer = styled.section`
    position: relative;
    z-index: 1;
`;

const StickyModInfoPanel = styled(ModerationInfoPanel)`
    position: sticky;
    bottom: 0;
    z-index: 2;
`;

const ListHeading = styled.h2`
    ${Heading2};
    display: block;
    margin: 48px 0 16px;
`;

const DismissedFade = styled.div`
    opacity: 0.5;
`;

const ErrorMessage = styled.div`
    padding: 24px 16px;
    color: ${({ theme }) => theme.neutral0};
    background: ${({ theme }) => theme.failure};
    border-radius: 4px;
    position: sticky;
    top: 0;
    z-index: 3;
`;

export default withListing(SalesModeration);
