import React, { Component } from "react";
import styled from "styled-components";

import { Heading3 } from "typography";

interface Props {
    className?: string;
}

export class ForReview extends Component<Props> {
    componentDidMount() {
        document.title = "For Review | Peanut";
    }

    render() {
        const { className } = this.props;
        return (
            <Wrapper className={className}>
                <Heading>Listings for Review - Disabled for now</Heading>
            </Wrapper>
        );
    }
}

const Wrapper = styled.div`
    max-width: 100%;
    overflow-x: scroll;
`;

const Heading = styled.h2`
    ${Heading3};
    margin-bottom: 30px;
`;

export default ForReview;
