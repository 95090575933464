import { Component, ReactNode } from "react";
import { Dispatch } from "redux";
import { connect } from "react-redux";

import { CustomerListing, CustomerListingFilter } from "store/customer-listing";
import { fetchCustomerListings } from "store/customer-listing/actions";
import { customerListingSelectors } from "store/customer-listing/reducer";
import { AppState } from "store";

export interface OwnProps {
    children: (props: MappedProps) => ReactNode;
}

interface PropsFromState {
    customerListings: CustomerListing[];
}

interface PropsFromDispatch {
    searchCustomerListings: typeof fetchCustomerListings;
}

type MappedProps = PropsFromState & PropsFromDispatch;

export class CustomerListingContainer extends Component<MappedProps & OwnProps> {
    render() {
        const { children, ...rest } = this.props;
        return children({ ...rest });
    }
}

const mapStateToProps = (state: AppState) => ({
    customerListings: customerListingSelectors.getAllCustomerListings(state)
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    searchCustomerListings: (filters: CustomerListingFilter) => dispatch(fetchCustomerListings(filters))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CustomerListingContainer);
