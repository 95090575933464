import { action } from "typesafe-actions";

import { SalesComparison, SalesComparisonActionTypes, SCTFetchParams } from "./types";

export const createSalesComparison = (salesComparison: SalesComparison) =>
    action(SalesComparisonActionTypes.CREATE_REQUEST, salesComparison);

export const createSalesComparisonSuccess = (salesComparison: SalesComparison) =>
    action(SalesComparisonActionTypes.CREATE_SUCCESS, salesComparison);

export const saveSalesComparison = (salesComparison: SalesComparison) =>
    action(SalesComparisonActionTypes.SAVE_REQUEST, salesComparison);

export const saveSalesComparisonSuccess = () => action(SalesComparisonActionTypes.SAVE_SUCCESS);

export const fetchSalesComparisonsByListingID = (listingID: string) =>
    action(SalesComparisonActionTypes.FETCH_BY_LISTING_REQUEST, listingID);

export const fetchSalesComparisonsSuccess = (salesComparison: SalesComparison[]) =>
    action(SalesComparisonActionTypes.FETCH_SUCCESS, salesComparison);

export const fetchSCT = (fetchParams: SCTFetchParams) => action(SalesComparisonActionTypes.FETCH_SCT, fetchParams);

export const updateSalesComparison = (salesComparison: SalesComparison) =>
    action(SalesComparisonActionTypes.UPDATE, salesComparison);

export const updateSalesComparisonBySourceID = (sourceID: string | undefined, isDismissed: boolean | undefined) =>
    action(SalesComparisonActionTypes.UPDATE_BY_SOURCE_ID, { sourceID, isDismissed });

export const completeSalesValuation = (listingID: string, totalTaskTime: number) =>
    action(SalesComparisonActionTypes.COMPLETED, { listingID, totalTaskTime });
