import React, { Component } from "react";
import { RouteComponentProps } from "react-router";

import styled from "styled-components";

import withListing from "hoc/with-listing";
import ListingContainer from "containers/listing-container";
import PrefilteringForm from "./prefiltering-form";
import RejectForm from "components/reject-listing/reject-form";
import { AnalysisLayout } from "components/analysis-layout";

interface RouteParams {
    listingID: string;
}

interface State {
    showRejectOverlay: boolean;
    rejectReason: string;
}

export class Prefiltering extends Component<RouteComponentProps<RouteParams>, State> {
    state = {
        showRejectOverlay: false,
        rejectReason: "",
    };

    handleOnRejectListingButton = (ID: string, currentQuestion: string) => {
        this.setState({ showRejectOverlay: true, rejectReason: this.getRejectReason(currentQuestion) });
    };

    handleOnClose = () => {
        this.setState({ showRejectOverlay: false });
    };

    getRejectReason = (field: string): string => {
        switch (field) {
            case "isFloorPlanGood":
                return "other";
            case "isLocationGood":
                return "location";
            default:
                return "condition";
        }
    };

    render() {
        const { listingID } = this.props.match.params;
        return (
            <>
                <ListingContainer listingID={listingID}>
                    {({ listings, saveListing }) => (
                        <>
                            {listings[0] ? (
                                <AnalysisLayout listing={listings[0]}>
                                    <FormContainer>
                                        <PrefilteringForm
                                            listingID={listingID}
                                            status={listings[0].status}
                                            isAutoAddressMatched={
                                                // BE says a super short completion time means it was auto-address matched
                                                !!(
                                                    listings[0].addressMatchCompletedIn &&
                                                    listings[0].addressMatchCompletedIn < 2
                                                )
                                            }
                                            onSubmit={saveListing}
                                            onReject={this.handleOnRejectListingButton}
                                        />
                                        {this.state.showRejectOverlay && (
                                            <RejectForm
                                                isShowing={this.state.showRejectOverlay}
                                                listingID={listingID}
                                                onSubmit={saveListing}
                                                onCancel={this.handleOnClose}
                                                listingStatus="prefilter"
                                            />
                                        )}
                                    </FormContainer>
                                </AnalysisLayout>
                            ) : null}
                        </>
                    )}
                </ListingContainer>
            </>
        );
    }
}

const FormContainer = styled.div`
    width: 40%;
`;

export default withListing(Prefiltering);
