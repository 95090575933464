import styled from "styled-components";

import { Body } from "typography";

export const Button = styled.button`
    ${Body};
    text-align: center;
    cursor: pointer;
    border: none;
    border-radius: 3px;
    padding: 10px 20px;
    min-width: 120px;
    color: #fff;
    background: ${(props) => props.theme.success};
`;

export const DangerButton = styled(Button)`
    background: ${(props) => props.theme.failure};
`;

export const GreyButton = styled(Button)`
    background: ${({ theme }) => theme.grey600};
`;

export const SecondaryButton = styled(Button)`
    background: ${({ theme }) => theme.coral500};
`;

export const TetriaryButton = styled(Button)`
    background: #fff;
    color: ${({ theme }) => theme.success};
    border: 1px solid ${({ theme }) => theme.success};
`;
