import React, { ChangeEvent } from "react";
import styled from "styled-components";
import { format } from "date-fns";
import { Formik } from "formik";

import { FormDatePicker, Input } from "components/form";
import { TetriaryButton, Button } from "components/button";
import { NotesHeader } from "components/comparable";
import { getLeaseData, yearsRemainingOnLease, handleClickUnknown, getInitialFormValues } from "./data-helpers";
import { Listing } from "store/listing";

interface InputForDateOrUnknownValueProps {
    value: string;
    fieldName: string;
    setFieldValue: (field: string, value: string) => void;
    fieldType: string;
}

export const InputForDateOrUnknownValue = ({
    value,
    fieldName,
    setFieldValue,
    fieldType,
}: InputForDateOrUnknownValueProps) =>
    value === "unknown" ? (
        <Input name={fieldName} value={value} type={fieldType} onChange={() => setFieldValue(fieldName, "")} />
    ) : (
        <FormDatePicker name={fieldName} value={value} onChange={setFieldValue} />
    );

export const inputWithUnknownButton = (
    data: { displayName: string; fieldName: string; value?: string | number | null; fieldType?: string },
    setFieldValue: (field: string, value: string) => void,
    handleChange: (event: ChangeEvent<HTMLInputElement>) => void,
    handleClickUnknown: (fieldName: string, setFieldValue: (field: string, value: string) => void) => void
) => (
    <FlexWrapper>
        {data.fieldName === "leaseDate" ? (
            <InputForDateOrUnknownValue
                fieldName={data.fieldName}
                value={typeof data.value === "string" ? data.value : ""}
                setFieldValue={setFieldValue}
                fieldType={data.fieldType ? data.fieldType : ""}
            />
        ) : (
            <Input
                name={data.fieldName}
                type={data.fieldType ? data.fieldType : ""}
                value={data.value ?? ""}
                onChange={handleChange}
            />
        )}

        <UnknownButton
            type="button"
            data-testid="set-unknown"
            onClick={() => handleClickUnknown(data.fieldName, setFieldValue)}
            isSet={data.value === "unknown"}
        >
            Unknown
        </UnknownButton>
    </FlexWrapper>
);

export const editLeaseholdFields = (
    listing: Listing,
    updateListing: (values: Listing, isLeaseholdInfo?: boolean) => void
) => (
    <Formik
        initialValues={getInitialFormValues(listing)}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={() => {}}
    >
        {({ values, handleChange, setFieldValue }) => {
            const showPreliminaryYears = Boolean(
                values.leaseDate === "unknown" || values.leaseLength === "unknown" || !values.leaseLength
            );
            const preliminaryYearsData = getLeaseData(values).find(
                (data) => data.fieldName === "preliminaryYearsRemaining"
            );
            return (
                <>
                    {getLeaseData(values).map(
                        (data) =>
                            data.fieldName !== "preliminaryYearsRemaining" && (
                                <LeaseInfo key={data.fieldName}>
                                    <LeaseInfoTitle>{data.displayName}</LeaseInfoTitle>
                                    {inputWithUnknownButton(data, setFieldValue, handleChange, handleClickUnknown)}
                                </LeaseInfo>
                            )
                    )}
                    {showPreliminaryYears && preliminaryYearsData ? (
                        <LeaseInfo key={preliminaryYearsData.fieldName}>
                            <LeaseInfoTitle>{preliminaryYearsData.displayName}</LeaseInfoTitle>
                            {inputWithUnknownButton(
                                preliminaryYearsData,
                                setFieldValue,
                                handleChange,
                                handleClickUnknown
                            )}
                        </LeaseInfo>
                    ) : (
                        <>
                            <LeaseInfoTitle>Remaining Years On Lease</LeaseInfoTitle>
                            {yearsRemainingOnLease(values.leaseDate, values.leaseLength)}
                        </>
                    )}

                    <LeaseholdButtonContainer>
                        <Button type="button" onClick={() => updateListing(values, true)}>
                            Update Leasehold Information
                        </Button>
                    </LeaseholdButtonContainer>
                </>
            );
        }}
    </Formik>
);

export const readOnlyLeaseholdFields = (listing: Listing) => {
    const showPreliminaryYears = Boolean(
        listing.leaseDate === "unknown" || listing.leaseLength === "unknown" || !listing.leaseLength
    );
    const preliminaryYearsData = getLeaseData(listing).find((data) => data.fieldName === "preliminaryYearsRemaining");
    return (
        <>
            {getLeaseData(listing).map(
                (data) =>
                    data.fieldName !== "preliminaryYearsRemaining" && (
                        <LeaseInfo key={data.fieldName}>
                            <LeaseInfoTitle>{data.displayName}</LeaseInfoTitle>
                            {data.fieldName === "leaseDate" && data.value && data.value !== "unknown"
                                ? format(new Date(data.value), "dd/MM/yyyy")
                                : data.value}
                        </LeaseInfo>
                    )
            )}
            {showPreliminaryYears && preliminaryYearsData ? (
                <LeaseInfo key={preliminaryYearsData.fieldName}>
                    <LeaseInfoTitle>{preliminaryYearsData.displayName}</LeaseInfoTitle>
                    {preliminaryYearsData.value}
                </LeaseInfo>
            ) : (
                <LeaseInfo>
                    <LeaseInfoTitle>Remaining Years On Lease Length </LeaseInfoTitle>
                    {yearsRemainingOnLease(listing.leaseDate, listing.leaseLength)}
                </LeaseInfo>
            )}
        </>
    );
};

const FlexWrapper = styled.div`
    display: flex;
`;

const UnknownButton = styled(TetriaryButton)<{ isSet: boolean }>`
    min-width: initial;
    padding: 8px 20px;
    margin-left: 8px;
    align-self: flex-end;
    color: ${({ theme, isSet }) => (isSet ? "#fff" : theme.success)};
    background: ${({ theme, isSet }) => (isSet ? theme.success : "inherit")};
`;

const LeaseInfoTitle = styled(NotesHeader)`
    margin-bottom: 4px;
    color: ${({ theme }) => theme.grey600};
`;

const LeaseInfo = styled.span`
    margin-bottom: 14px;
    color: ${({ theme }) => theme.grey600};
    font-size: 16px;
`;

const LeaseholdButtonContainer = styled.div`
    padding-top: 15px;
`;
