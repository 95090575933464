import React from "react";
import styled from "styled-components";

export interface IModerationInfoPanel {
    className?: string;
    val1Min: number;
    val1Max: number;
    val2Min: number;
    val2Max: number;
    lowYield: number;
    highYield: number;
    modelPredictedMonthlyRent?: number;
}

export const ModerationInfoPanel: React.FC<IModerationInfoPanel> = ({
    className,
    val1Min,
    val1Max,
    val2Min,
    val2Max,
    lowYield,
    highYield,
    modelPredictedMonthlyRent,
    children,
}) => {
    return (
        <Wrapper className={className}>
            <InfoWrapper>
                <div>
                    <InfoHeading>Valuation 1</InfoHeading>
                    <InfoItem>
                        <InfoSubItem>
                            <InfoSubHeading>Min</InfoSubHeading>
                            <InfoValue>£{val1Min.toLocaleString()}</InfoValue>
                        </InfoSubItem>
                        <InfoSubItem>
                            <InfoSubHeading>Max</InfoSubHeading>
                            <InfoValue>£{val1Max.toLocaleString()}</InfoValue>
                        </InfoSubItem>
                    </InfoItem>
                </div>
                <div>
                    <InfoHeading>Valuation 2</InfoHeading>
                    <InfoItem>
                        <InfoSubItem>
                            <InfoSubHeading>Min</InfoSubHeading>
                            <InfoValue>£{val2Min.toLocaleString()}</InfoValue>
                        </InfoSubItem>
                        <InfoSubItem>
                            <InfoSubHeading>Max</InfoSubHeading>
                            <InfoValue>£{val2Max.toLocaleString()}</InfoValue>
                        </InfoSubItem>
                    </InfoItem>
                </div>
                {/* Model Rent is for Rental Moderation */}
                {/* Yields are for Sales Moderation */}
                {modelPredictedMonthlyRent || modelPredictedMonthlyRent === 0 ? (
                    <div>
                        <InfoHeading>Model Rent</InfoHeading>
                        <InfoItem>
                            <InfoSubItem>
                                <InfoSubHeading>Value</InfoSubHeading>
                                <InfoValue>£{modelPredictedMonthlyRent.toLocaleString()}</InfoValue>
                            </InfoSubItem>
                        </InfoItem>
                    </div>
                ) : (
                    <div>
                        <InfoHeading>Yields</InfoHeading>
                        <InfoItem>
                            <InfoSubItem>
                                <InfoSubHeading>4.9%</InfoSubHeading>
                                <InfoValue>£{lowYield.toLocaleString()}</InfoValue>
                            </InfoSubItem>
                            <InfoSubItem>
                                <InfoSubHeading>5.0%</InfoSubHeading>
                                <InfoValue>£{highYield.toLocaleString()}</InfoValue>
                            </InfoSubItem>
                        </InfoItem>
                    </div>
                )}
            </InfoWrapper>
            <div>{children}</div>
        </Wrapper>
    );
};

const Wrapper = styled.section`
    display: flex;
    justify-content: center;
    align-items: flex-start;
    background: #535b5b;
`;

const InfoWrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    padding: 20px 8px;
    color: #ffffff;
    width: 460px;
`;

const InfoHeading = styled.h3`
    font-size: 14px;
    color: #ffffff;
    margin-bottom: 12px;
`;

const InfoSubHeading = styled.h4`
    font-size: 14px;
    color: ${({ theme }) => theme.grey500};
    margin-bottom: 6px;
`;

const InfoValue = styled.p`
    font-size: 14px;
    color: #ffffff;
`;

const InfoItem = styled.div`
    display: flex;
    justify-content: space-between;
    width: 120px;
    margin-right: 16px;
`;

const InfoSubItem = styled.div`
    margin-right: 8px;
`;
