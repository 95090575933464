import styled from "styled-components";

import { Body } from "typography";

export const FormLink = styled.a`
    ${Body};
    display: block;
    margin-top: 10px;
    text-decoration: none;
    cursor: pointer;
    color: ${(props) => props.theme.coral500};
`;
