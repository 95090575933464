import React, { Fragment, useState } from "react";
import styled from "styled-components";

import { Body } from "typography";
import { Button } from "components/button";
import { generateEPCLink } from "utils/url-helpers";

export type Address = {
    UDPRN: string;
    display: string;
    postcode: string;
};

interface Props {
    addresses: Address[];
    onSelect?: (selectedUDPRN: Address["UDPRN"], address: string, postcode: string) => void;
}

export const AddressMatchTable: React.FC<Props> = ({ addresses, onSelect }) => {
    const [selectedUDPRN, setSelectedUDPRN] = useState<Address["UDPRN"] | null>(null);

    const handleClick = (UDPRN: Address["UDPRN"], address: string, postcode: string) => {
        if (selectedUDPRN === UDPRN) {
            setSelectedUDPRN(null);
            onSelect && onSelect("", "", "");
        } else {
            setSelectedUDPRN(UDPRN);
            onSelect && onSelect(UDPRN, address, postcode);
        }
    };

    return (
        <Wrapper data-testid="address-match-table">
            <HeaderCell>UDPRN</HeaderCell>
            <HeaderCell>Address</HeaderCell>
            <HeaderCell>EPC Link</HeaderCell>
            <HeaderCell />
            {addresses.map(({ UDPRN, display, postcode }) => (
                <Fragment key={UDPRN}>
                    <Cell data-testid={`udprn-${UDPRN}`}>{UDPRN}</Cell>
                    <Cell data-testid={`address-${UDPRN}`}>{display}</Cell>
                    <Cell>
                        <StyledLink
                            data-testid={`epc-link-${UDPRN}`}
                            href={generateEPCLink(display, postcode)}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Open EPC Link
                        </StyledLink>
                    </Cell>
                    <Cell data-testid={`action-${UDPRN}`}>
                        <StyledButton
                            data-testid={`select-button-${UDPRN}`}
                            onClick={() => handleClick(UDPRN, display, postcode)}
                            isSelected={selectedUDPRN === UDPRN}
                            type="button"
                        >
                            {selectedUDPRN === UDPRN ? "Selected" : "Select"}
                        </StyledButton>
                    </Cell>
                </Fragment>
            ))}
        </Wrapper>
    );
};

const Wrapper = styled.div`
    ${Body};
    display: grid;
    grid-template-columns: 1fr 3fr 3fr 1fr;
    grid-gap: 4px;
`;

const Cell = styled.div`
    padding: 12px;
`;

const HeaderCell = styled(Cell)`
    background: ${({ theme }) => theme.grey200};
    font-weight: bold;
`;

const StyledLink = styled.a`
    text-decoration: none;
    color: ${({ theme }) => theme.success};
`;

const StyledButton = styled(Button)<{ isSelected: boolean }>`
    background-color: ${({ theme, isSelected }) => (isSelected ? theme.coral500 : theme.grey600)};
    outline: none;
`;
