import React from "react";
import styled from "styled-components";
import { NavLink } from "react-router-dom";
import { Button } from "components/button";

import PeanutLogo from "components/media/svg/peanut.svg";

export interface Props {
    className?: string;
    navItems: Array<{ name: string; path: string }>;
    logo: {
        name: string;
        path: string;
    };
    isLoggedIn: boolean;
    logout: () => void;
}

export const Header = ({ className, navItems, logo, logout, isLoggedIn }: Props) =>
    isLoggedIn ? (
        <Wrapper className={className}>
            <NavLink to={logo.path} title={logo.name}>
                <Logo />
            </NavLink>
            <StyledUl>
                {navItems.map((item, index) => (
                    <StyledLi key={index}>
                        <StyledNavLink to={item.path}>{item.name}</StyledNavLink>
                    </StyledLi>
                ))}
            </StyledUl>
            <StyledLogoutButton onClick={logout}>Logout</StyledLogoutButton>
        </Wrapper>
    ) : null;

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    background: ${(props) => props.theme.grey200};
    padding: 20px;
`;

const Logo = styled.span`
    background: url(${PeanutLogo}) center center no-repeat;
    background-size: cover;
    width: 45px;
    height: 45px;
    margin-right: 20px;
    display: inline-block;
`;

const StyledUl = styled.ul`
    display: flex;
`;

const StyledLi = styled.li`
    padding: 0 15px;
`;

const activeClassName = "active";
const StyledNavLink = styled(NavLink).attrs({ activeClassName })`
    font-size: 16px;
    text-decoration: none;
    color: ${(props) => props.theme.coral500};

    &:hover,
    &.${activeClassName} {
        color: ${(props) => props.theme.grey700};
    }
`;

const StyledLogoutButton = styled(Button)`
    margin-left: auto;
`;
