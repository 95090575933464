import styled from "styled-components";
import { Body } from "typography";

export const Label = styled.label`
    ${Body};
    display: inline-block;
    margin: 0 5px 5px 0;
    text-transform: capitalize;

    input,
    textarea {
        margin-top: 5px;
    }
`;
