import { object, string } from "yup";

export const prefilteringFormValidationSchema = object({
    isKitchenGood: string()
        .oneOf(["true", "false", "unknown"], "You must select a valid option")
        .required("You must choose an option for the Kitchen"),
    isBathroomGood: string()
        .oneOf(["true", "false", "unknown"], "You must select a valid option")
        .required("You must choose an option for the Bathroom"),
    isExteriorGood: string()
        .oneOf(["true", "false", "unknown"], "You must select a valid option")
        .required("You must choose an option for the Exterior"),
    isOverallGood: string()
        .oneOf(["true", "false", "unknown"], "You must select a valid option")
        .required("You must choose an option for the Overall condition"),
    isFloorPlanGood: string()
        .oneOf(["true", "false", "unknown"], "You must select a valid option")
        .required("You must choose an option for the Floorplan"),
    isLocationGood: string()
        .oneOf(["true", "false", "unknown"], "You must select a valid option")
        .required("You must choose an option for the Location")
});
