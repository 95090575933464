import React, { useEffect } from "react";
import styled from "styled-components";

import { Heading3 } from "typography";
import withListing from "hoc/with-listing";
import { AnalysisLayout } from "components/analysis-layout";
import PropertyDetailsForm from "./property-details-form";
import { Listing, saveListingRequest } from "store/listing";

interface Props {
    className?: string;
    listing: Listing;
    saveListing: typeof saveListingRequest;
}

export const BasicAnalysis: React.FC<Props> = ({ listing, saveListing, className }) => {
    useEffect(() => {
        document.title = "Basic Analysis | Peanut";
    }, []);

    return listing ? (
        <AnalysisLayout className={className} listing={listing}>
            <SectionHeading>Add property details</SectionHeading>
            <PropertyDetailsForm listing={listing} updateListing={saveListing} />
        </AnalysisLayout>
    ) : null;
};

const SectionHeading = styled.h3`
    ${Heading3};
    margin-top: 30px;
    font-weight: 700;
`;

export default withListing(BasicAnalysis);
