import { FormProps } from "./listing-search-form";
import { Choice } from "components/form/multi-choice-button";

export const listingSearchFormData: { [key in keyof FormProps]?: Choice[] } = {
    searchType: [
        { displayName: "Pre-filtering", name: "searchType", value: "prefilter" },
        { displayName: "Post-filtering", name: "searchType", value: "postfilter" }
    ],
    numOfAdults: [
        { displayName: "Single", name: "numOfAdults", value: 1 },
        { displayName: "Couple", name: "numOfAdults", value: 2 }
    ],
    children0to1: [
        { displayName: "0", name: "children0to1", value: 0 },
        { displayName: "1", name: "children0to1", value: 1 },
        { displayName: "2", name: "children0to1", value: 2 },
        { displayName: "3", name: "children0to1", value: 3 }
    ],
    children2to4: [
        { displayName: "0", name: "children2to4", value: 0 },
        { displayName: "1", name: "children2to4", value: 1 },
        { displayName: "2", name: "children2to4", value: 2 },
        { displayName: "3", name: "children2to4", value: 3 }
    ],
    children5to11: [
        { displayName: "0", name: "children5to11", value: 0 },
        { displayName: "1", name: "children5to11", value: 1 },
        { displayName: "2", name: "children5to11", value: 2 },
        { displayName: "3", name: "children5to11", value: 3 }
    ],
    children12to16: [
        { displayName: "0", name: "children12to16", value: 0 },
        { displayName: "1", name: "children12to16", value: 1 },
        { displayName: "2", name: "children12to16", value: 2 },
        { displayName: "3", name: "children12to16", value: 3 }
    ]
};
