import React from "react";
import { Route, RouteProps, Redirect } from "react-router";
import { routePaths } from "routes";

export interface Props extends RouteProps {
    isLoggedIn: boolean;
    component: React.ComponentType<any>;
}

export const PrivateRoute = ({ component: Component, isLoggedIn, ...rest }: Props) => (
    <Route
        {...rest}
        render={(props) => {
            return isLoggedIn ? <Component {...props} /> : <Redirect to={{ pathname: routePaths.login }} />;
        }}
    />
);
