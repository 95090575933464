import React from "react";
import { formatDistanceStrict } from "date-fns";

import { RentalComparison } from "store/rental-comparison";
import { Listing } from "store/listing";
import { RentalInfoCondition } from "./rental-info-condition";
import {
    CardContentWrapper,
    CardList,
    CardSubTitle,
    CardTitle,
    CardItem,
    CardNotes,
    ValuationLabel,
    ValuationHeader,
    NotesWrapper,
    NotesHeader,
    CardNoteWrapper,
} from "../helper-components";
import {
    distanceDisplayDict,
    propertyDisplayValueDict,
    visualRentalStatus,
    visualDistance,
    visualComparisonRoomNumbers,
    visualComparisonType,
    visualAge,
    visualComparisonOutsideFeature,
    visualComparisonFloorArea,
} from "utils/data-helpers";

export interface IRentalInfoProps {
    rentalComparison: RentalComparison;
    listing: Listing;
    isValuation1?: boolean;
    isValuation2?: boolean;
}

export const RentalInfo: React.FC<IRentalInfoProps> = ({ rentalComparison, listing, isValuation1, isValuation2 }) => {
    return (
        <>
            {isValuation1 && <ValuationLabel>Valuation 1</ValuationLabel>}
            {isValuation2 && <ValuationLabel>Valuation 2</ValuationLabel>}
            <CardTitle>{rentalComparison.address}</CardTitle>
            <CardContentWrapper>
                <CardList>
                    <li>
                        <CardSubTitle>PROPERTY</CardSubTitle>
                    </li>
                    <li>
                        <CardItem visualStatus={visualDistance(rentalComparison.distance)}>Distance:</CardItem>{" "}
                        {distanceDisplayDict(rentalComparison.distance)}
                    </li>
                    <li>
                        <CardItem
                            visualStatus={visualComparisonType(
                                rentalComparison.propertyType,
                                rentalComparison.propertyStyle,
                                listing.propertyType,
                                listing.propertyStyle
                            )}
                        >
                            Type:
                        </CardItem>{" "}
                        {propertyDisplayValueDict(rentalComparison.propertyType)}
                    </li>
                    <li>
                        <CardItem>Style:</CardItem> {propertyDisplayValueDict(rentalComparison.propertyStyle)}
                    </li>
                    <li>
                        <CardItem
                            visualStatus={visualComparisonRoomNumbers(rentalComparison.totalBedrooms, listing.bedrooms)}
                        >
                            Beds:
                        </CardItem>{" "}
                        {rentalComparison.totalBedrooms || "Unknown"}
                    </li>
                    <li>
                        <CardItem
                            visualStatus={visualComparisonRoomNumbers(
                                rentalComparison.totalBathrooms,
                                listing.totalBathrooms
                            )}
                        >
                            Baths:
                        </CardItem>{" "}
                        {rentalComparison.totalBathrooms || "Unknown"}
                    </li>
                    <li>
                        <CardItem
                            visualStatus={visualComparisonFloorArea(listing.floorArea, rentalComparison.floorArea)}
                        >
                            FloorArea:
                        </CardItem>{" "}
                        {rentalComparison.floorArea || "Unknown"}
                    </li>
                    <li>
                        <CardItem
                            visualStatus={visualComparisonOutsideFeature(
                                listing.hasOutsideSpace,
                                rentalComparison.hasOutsideSpace
                            )}
                        >
                            Outside:
                        </CardItem>{" "}
                        {propertyDisplayValueDict(rentalComparison.hasOutsideSpace)}
                    </li>
                    <li>
                        <CardItem
                            visualStatus={visualComparisonOutsideFeature(
                                listing.hasParking,
                                rentalComparison.hasParking
                            )}
                        >
                            Parking:
                        </CardItem>{" "}
                        {propertyDisplayValueDict(rentalComparison.hasParking)}
                    </li>
                </CardList>
                <CardList>
                    <li>
                        <CardSubTitle>TRANSACTION</CardSubTitle>
                    </li>
                    <li>
                        <CardItem visualStatus={visualRentalStatus(rentalComparison.rentalType)}>Status:</CardItem>{" "}
                        {propertyDisplayValueDict(rentalComparison.rentalType)}
                    </li>
                    <li>
                        <CardItem
                            visualStatus={visualAge(
                                formatDistanceStrict(new Date(rentalComparison.rentalDate || Date.now()), Date.now())
                            )}
                        >
                            Age:
                        </CardItem>{" "}
                        {formatDistanceStrict(new Date(rentalComparison.rentalDate || Date.now()), Date.now())}
                    </li>
                    <li>
                        <CardItem>Price:</CardItem> £{rentalComparison.monthlyRent}
                    </li>
                </CardList>
                <RentalInfoCondition
                    rentalComparison={rentalComparison}
                    listing={listing}
                    isValuation1={isValuation1}
                    isValuation2={isValuation2}
                />
            </CardContentWrapper>
            <NotesHeader>Notes</NotesHeader>
            <NotesWrapper>
                {rentalComparison.notes ? (
                    <CardNoteWrapper>
                        {isValuation1 ? (
                            <ValuationHeader>Valuation 1</ValuationHeader>
                        ) : (
                            <ValuationHeader>Valuation 2</ValuationHeader>
                        )}
                        <CardNotes>{rentalComparison.notes || ""}</CardNotes>
                    </CardNoteWrapper>
                ) : null}
                {rentalComparison.valuation2Notes ? (
                    <CardNoteWrapper>
                        <ValuationHeader>Valuation 2</ValuationHeader>
                        <CardNotes>{rentalComparison.valuation2Notes || ""}</CardNotes>
                    </CardNoteWrapper>
                ) : null}
            </NotesWrapper>
        </>
    );
};
