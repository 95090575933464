import React, { useEffect, useState } from "react";
import { useParams } from "react-router";

import withListing from "hoc/with-listing";
import { AnalysisLayout } from "components/analysis-layout";
import AddPropertyForm from "./add-property-form";
import { Address } from "./address-match-table";
import { useDispatch, useSelector } from "react-redux";
import { Listing, listingSelectors, saveListingRequest } from "store/listing";
import { AppState } from "store";
import { showApiError } from "store/ui";

type AddressApiData = {
    udprn: number;
    display: string;
    subBuilding: string;
    buildingName: string;
    buildingNumber: string;
    thoroughfare: string;
    town: string;
    postcode: string;
    country: string;
};

const mapApiDataToAddress = (apiData: AddressApiData): Address => ({
    UDPRN: apiData.udprn.toString(),
    display: apiData.display,
    postcode: apiData.postcode,
});

export const AddressMatch = () => {
    const { listingID } = useParams<{ listingID: string }>();
    const dispatch = useDispatch();
    const listing: Listing = useSelector((state: AppState) => listingSelectors.getListingByID(state, listingID));
    const [addresses, setAddresses] = useState<Address[]>([]);
    const { postcode, status } = listing;

    const [selectedPostcode, setSelectedPostcode] = useState<string | undefined>(postcode);

    document.title = "Address Match | Peanut";

    useEffect(() => {
        (async () => {
            if (selectedPostcode) {
                try {
                    const URLFriendlyPostcode = selectedPostcode.replace(/\s/g, "+");
                    const response = await fetch(
                        `${process.env.REACT_APP_API_ENDPOINT}/v0/addresses?postcode=${URLFriendlyPostcode}`,
                        {
                            method: "GET",
                        }
                    );
                    if (response.ok) {
                        const data = await response.json();
                        setAddresses((data.results as AddressApiData[]).map(mapApiDataToAddress));
                    } else {
                        dispatch(showApiError(`Failed to get addresses for postcode: ${selectedPostcode}`));
                    }
                } catch (error) {
                    dispatch(
                        showApiError(`An error occurred while fetching addresses for postcode: ${selectedPostcode}`)
                    );
                }
            }
        })();
    }, [selectedPostcode, dispatch]);

    return listing ? (
        <AnalysisLayout listing={listing}>
            <AddPropertyForm
                listingID={listingID || ""}
                addresses={addresses}
                updateListing={(listing: Listing) => dispatch(saveListingRequest(listing))}
                status={status}
                postcode={listing.postcode ? listing.postcode : ""}
                onReload={setSelectedPostcode}
            />
        </AnalysisLayout>
    ) : null;
};

export default withListing(AddressMatch);
