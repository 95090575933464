import { Reducer } from "redux";
import unique from "array-unique";
import { createSelector } from "reselect";

import { SalesComparison, SalesComparisonActionTypes, SalesComparisonState } from "./types";
import { dataToObjectByKey, getAllIDsFromData } from "utils/data-helpers";
import { globaliseSelectors } from "utils/globalise-selectors";

const initialState: SalesComparisonState = {
    byID: {},
    allIDs: [],
    totalCompsValTaskTime: 0,
    writeComplete: false,
};

const reducer: Reducer<SalesComparisonState> = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case SalesComparisonActionTypes.CREATE_REQUEST:
            return {
                ...state,
                writeComplete: false,
            };

        case SalesComparisonActionTypes.CREATE_SUCCESS:
            return {
                ...state,
                byID: {
                    ...state.byID,
                    [payload.ID]: action.payload,
                },
                allIDs: unique([...state.allIDs, payload.ID]),
                writeComplete: true,
            };

        case SalesComparisonActionTypes.SAVE_REQUEST:
            return {
                ...state,
                writeComplete: false,
            };

        case SalesComparisonActionTypes.SAVE_SUCCESS:
            return {
                ...state,
                writeComplete: true,
            };

        case SalesComparisonActionTypes.FETCH_SUCCESS:
            return {
                ...state,
                byID: {
                    ...dataToObjectByKey(payload, "ID"),
                },
                allIDs: [...getAllIDsFromData(payload, "ID")],
            };

        case SalesComparisonActionTypes.UPDATE:
            return state.byID[payload.ID]
                ? {
                      ...state,
                      byID: {
                          ...state.byID,
                          [payload.ID]: {
                              ...state.byID[payload.ID],
                              ...payload,
                          },
                      },
                  }
                : state;

        case SalesComparisonActionTypes.UPDATE_BY_SOURCE_ID:
            return {
                ...state,
                byID: {
                    ...dataToObjectByKey(
                        Object.values(state.byID).map((element) =>
                            element.sourceID === payload.sourceID
                                ? { ...element, isDismissed: payload.isDismissed }
                                : element
                        ),
                        "ID"
                    ),
                },
            };

        case SalesComparisonActionTypes.COMPLETED:
            return {
                ...state,
                totalCompsValTaskTime: payload.totalTaskTime,
            };

        default:
            return state;
    }
};

export const getAllSalesComparisons = (state: SalesComparisonState) => state.byID;

export const getAllSalesComparisonsAsArray = (state: SalesComparisonState): SalesComparison[] =>
    Object.keys(state.byID).map((key) => state.byID[key]);

export const getAllDismissed = (state: SalesComparisonState): SalesComparison[] =>
    Object.values(state.byID).filter((sc) => sc.isDismissed);

export const getAllNonDismissed = (state: SalesComparisonState): SalesComparison[] =>
    Object.values(state.byID).filter((sc) => !sc.isDismissed);

export const getAllCompleted = (state: SalesComparisonState): SalesComparison[] =>
    Object.values(state.byID).filter((sc) => sc.isComplete && !sc.isDismissed);

export const getSalesComparisonsByListingID = createSelector(
    (state: SalesComparisonState, valuationID: string) => valuationID,
    getAllSalesComparisons,
    (listingID, salesComps) => {
        return Object.keys(salesComps)
            .filter((key) => salesComps[key].listingID === listingID)
            .map((key) => salesComps[key]);
    }
);

export const getSalesComparisonsByListingAndOperator = (
    state: SalesComparisonState,
    listingID: string,
    operatorID: string
) => {
    return Object.keys(state.byID)
        .filter((key) => state.byID[key].listingID === listingID)
        .filter((key) => state.byID[key].operatorID === operatorID)
        .map((key) => state.byID[key]);
};

export const getSalesComparisonsByValuation = (state: SalesComparisonState, listingID: string, operatorID: string) =>
    operatorID
        ? getSalesComparisonsByListingAndOperator(state, listingID, operatorID)
        : getSalesComparisonsByListingID(state, listingID);

export const getTotalCompValTaskTime = (state: SalesComparisonState) => state.totalCompsValTaskTime;

export const getWriteCompleteStatus = (state: SalesComparisonState) => state.writeComplete;

export const salesComparisonSelectors = globaliseSelectors(
    {
        getAllSalesComparisons,
        getAllSalesComparisonsAsArray,
        getAllDismissed,
        getAllNonDismissed,
        getAllCompleted,
        getSalesComparisonsByListingID,
        getSalesComparisonsByValuation,
        getTotalCompValTaskTime,
        getWriteCompleteStatus,
    },
    "salesComparisons"
);

export { reducer as salesComparisonReducer };
