export const launchAreas = [
    "Aylesbury",
    "Basingstoke",
    "Bournemouth",
    "Bracknell",
    "Brentwood",
    "Brighton",
    "Bristol",
    "Bromley",
    "Broxbourne",
    "Chatham",
    "Chelmsford",
    "Chigwell",
    "Colchester",
    "Croydon",
    "Dartford",
    "Farnborough",
    "Grays",
    "Guildford",
    "Harrogate",
    "Hemel Hempstead",
    "Hertford",
    "Leamington Spa",
    "Leeds",
    "Leighton Buzzard",
    "Milton Keynes",
    "Oxford",
    "Oxfordshire",
    "Reading",
    "Redhill",
    "Romford",
    "Seaford",
    "Solihull",
    "South Manchester",
    "Southampton",
    "Southend",
    "Staines",
    "Stockport",
    "Sutton",
    "Trafford",
    "Weybridge",
    "Woking",
    "Worthing"
];
