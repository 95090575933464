import { RentalComparison } from "store/rental-comparison/types";
import { Choice } from "components/form/multi-choice-button";

export const rentalComparisonFormData: { [key in keyof RentalComparison]?: Choice[] } = {
    propertyType: [
        { displayName: "House", name: "propertyType", value: "house" },
        { displayName: "Flat", name: "propertyType", value: "flat" }
    ],
    propertyStyle: [
        { displayName: "Detached", name: "propertyStyle", value: "detached" },
        { displayName: "Semi Detached", name: "propertyStyle", value: "semi-detached" },
        { displayName: "Terraced", name: "propertyStyle", value: "terraced" },
        { displayName: "Mid Terrace", name: "propertyStyle", value: "mid-terrace" },
        { displayName: "End of terrace", name: "propertyStyle", value: "end-terrace" },
        { displayName: "Purpose-built", name: "propertyStyle", value: "purpose-built" },
        { displayName: "Converted", name: "propertyStyle", value: "converted" }
    ],
    distance: [
        { displayName: "Unknown", name: "distance", value: "unknown" },
        { displayName: "Same postcode", name: "distance", value: "0" },
        { displayName: "< 100m", name: "distance", value: "100" },
        { displayName: "< 200m", name: "distance", value: "200" },
        { displayName: "< 300m", name: "distance", value: "300" },
        { displayName: "< 400m", name: "distance", value: "400" },
        { displayName: "< 500m", name: "distance", value: "500" },
        { displayName: "< 600m", name: "distance", value: "600" },
        { displayName: "< 700m", name: "distance", value: "700" },
        { displayName: "< 800m", name: "distance", value: "800" },
        { displayName: "< 900m", name: "distance", value: "900" },
        { displayName: "< 1km", name: "distance", value: "1000" },
        { displayName: "< 1.5km", name: "distance", value: "1500" },
        { displayName: "< 2km", name: "distance", value: "2000" },
        { displayName: "< 3km", name: "distance", value: "3000" },
        { displayName: "< 4km", name: "distance", value: "4000" },
        { displayName: "< 5km", name: "distance", value: "5000" }
    ],
    rentalType: [
        { displayName: "Let Agreed", name: "rentalType", value: "let-agreed" },
        { displayName: "Listed", name: "rentalType", value: "listed" },
        { displayName: "Achieved", name: "rentalType", value: "achieved" }
    ],
    hasOutsideSpace: [
        { displayName: "Y", name: "hasOutsideSpace", value: "true" },
        { displayName: "N", name: "hasOutsideSpace", value: "false" },
        { displayName: "Unk", name: "hasOutsideSpace", value: "unknown" }
    ],
    hasParking: [
        { displayName: "Y", name: "hasParking", value: "true" },
        { displayName: "N", name: "hasParking", value: "false" },
        { displayName: "Unk", name: "hasParking", value: "unknown" }
    ],
    conditionOverall: [
        { displayName: "Exlnt", name: "conditionOverall", value: "excellent" },
        { displayName: "Good", name: "conditionOverall", value: "good" },
        { displayName: "OK", name: "conditionOverall", value: "okay" },
        { displayName: "Bad", name: "conditionOverall", value: "bad" },
        { displayName: "Unk", name: "conditionOverall", value: "unknown" }
    ],
    conditionExterior: [
        { displayName: "Exlnt", name: "conditionExterior", value: "excellent" },
        { displayName: "Good", name: "conditionExterior", value: "good" },
        { displayName: "OK", name: "conditionExterior", value: "okay" },
        { displayName: "Bad", name: "conditionExterior", value: "bad" },
        { displayName: "Unk", name: "conditionExterior", value: "unknown" }
    ],
    conditionInterior: [
        { displayName: "Exlnt", name: "conditionInterior", value: "excellent" },
        { displayName: "Good", name: "conditionInterior", value: "good" },
        { displayName: "OK", name: "conditionInterior", value: "okay" },
        { displayName: "Bad", name: "conditionInterior", value: "bad" },
        { displayName: "Unk", name: "conditionInterior", value: "unknown" }
    ],
    conditionKitchen: [
        { displayName: "Exlnt", name: "conditionKitchen", value: "excellent" },
        { displayName: "Good", name: "conditionKitchen", value: "good" },
        { displayName: "OK", name: "conditionKitchen", value: "okay" },
        { displayName: "Bad", name: "conditionKitchen", value: "bad" },
        { displayName: "Unk", name: "conditionKitchen", value: "unknown" }
    ],
    conditionBathroom: [
        { displayName: "Exlnt", name: "conditionBathroom", value: "excellent" },
        { displayName: "Good", name: "conditionBathroom", value: "good" },
        { displayName: "OK", name: "conditionBathroom", value: "okay" },
        { displayName: "Bad", name: "conditionBathroom", value: "bad" },
        { displayName: "Unk", name: "conditionBathroom", value: "unknown" }
    ]
};
