import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import * as Sentry from "@sentry/browser";
import { AuthProvider, AuthProviderProps } from "react-oidc-context";
import { WebStorageStateStore } from "oidc-client-ts";

import configureStore from "./configure-store";
import { getInitialState } from "store";

import App from "./app";

const history = createBrowserHistory();
const initialState = getInitialState();
const store = configureStore(history, initialState);
const oidcConfig: AuthProviderProps = {
    authority: process.env.REACT_APP_OIDC_AUTHORITY || "",
    client_id: process.env.REACT_APP_OIDC_CLIENT_ID || "",
    redirect_uri: window.location.origin,
    onSigninCallback: () => {
        // Remove the specific query param that causes state errors on redirect
        const currentURL = new URL(window.location.href);
        const unusedQueryParams = ["state", "session_state", "code"];
        unusedQueryParams.forEach((unusedQuery) => {
            currentURL.searchParams.delete(unusedQuery);
        });

        window.history.replaceState({}, document.title, currentURL.href);
    },
    userStore: new WebStorageStateStore({ store: window.localStorage }),
};

if (process.env.REACT_APP_SENTRY_DSN) {
    Sentry.init({
        dsn: process.env.REACT_APP_SENTRY_DSN,
        environment: process.env.REACT_APP_ENV,
    });
}

ReactDOM.render(
    <AuthProvider {...oidcConfig}>
        <App store={store} history={history} />
    </AuthProvider>,
    document.getElementById("root") as HTMLElement,
);
