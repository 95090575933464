import { number, object, string, mixed } from "yup";

const numberRegex = new RegExp(/^[-+]?(\d+|Infinity)$/);

export const propertyDetailsValidationSchema = object({
    propertyType: string().required("The property type is required"),
    propertyStyle: string().required("The property style is required"),
    propertyTenure: string().required("The property tenure is required"),
    totalSingleBedrooms: number().test({
        test: function (value) {
            const doubleBedsValue = this.parent.totalDoubleBedrooms;
            return value !== 0 || doubleBedsValue !== 0;
        },
        message: "Single bedrooms cannot be 0 when double bedrooms is also 0",
    }),
    totalDoubleBedrooms: number().test({
        test: function (value) {
            const singleBedsValue = this.parent.totalSingleBedrooms;
            return value !== 0 || singleBedsValue !== 0;
        },
        message: "Double bedrooms cannot be 0 when single bedrooms is also 0",
    }),
    totalBathrooms: number().min(1, "The number of bathrooms cannot be 0"),
    floorArea: mixed().test({
        name: "floorArea",
        test: (value) => value === "unknown" || numberRegex.test(value),
        message: "The floor area must be a number or 'unknown'",
    }),
    EPCCurrent: mixed().test({
        name: "epcCurrent",
        test: (value) => value === "unknown" || numberRegex.test(value),
        message: "The current EPC must be a number or 'unknown'",
    }),
    EPCPotential: mixed().test({
        name: "epcPotential",
        test: (value) => value === "unknown" || numberRegex.test(value),
        message: "The potential EPC must be a number or 'unknown'",
    }),
    hasOutsideSpace: mixed().oneOf(["true", "false", "unknown"]).required("The Outside Space field is required"),
    hasParking: mixed().oneOf(["true", "false", "unknown"]).required("The Parking field is required"),
    councilTaxBand: string().required("The council tax band is required"),
    councilTaxPrice: mixed().test({
        name: "councilTaxPrice",
        test: (value) => value === "unknown" || numberRegex.test(value),
        message: "The council tax price must be a number or 'unknown'",
    }),
    age: string().required("The build age is required"),
    conditionOverall: string().required("The overall condition must be rated"),
    conditionInterior: string().required("The interior condition must be rated"),
    conditionExterior: string().required("The exterior condition must be rated"),
    conditionKitchen: string().required("The kitchen condition must be rated"),
    conditionBathroom: string().required("The bathrooms condition must be rated"),
});
