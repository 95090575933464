import React, { Component } from "react";
import styled from "styled-components";

import { statusOrder } from "config/status-order";
import { StatusStages } from "../status-stages";
import { RejectListing } from "components/reject-listing/reject-listing";
import { Listing } from "store/listing";

export interface Props {
    className?: string;
    listingID: string;
    listingStatus: string;
    saveListing: (listing: Listing) => void;
}

export class StatusNavigation extends Component<Props> {
    handleRejection = (listing: Listing) => {
        const { saveListing } = this.props;

        saveListing(listing);
    };

    render() {
        const { className, listingID, listingStatus } = this.props;

        return (
            <Wrapper className={className}>
                <StatusStages listingID={listingID} listingStatus={listingStatus} statuses={statusOrder} />
                <RejectListing
                    listingStatus={listingStatus}
                    listingID={listingID}
                    handleReject={this.handleRejection}
                />
            </Wrapper>
        );
    }
}

const Wrapper = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
`;
