export interface CustomerListing {
    ID: string;
    sourceID: string;
    title: string;
    address: string;
    postcode: string;
    mainImage: string;
    imageUrls: string[];
    price: number;
    totalSingleBedrooms: number;
    totalDoubleBedrooms: number;
    totalBathrooms: number;
    totalWCs: number;
    predictedMonthlyRent: number;
    buyOutMonth: number;
    ownershipPercentage: number;
    IRR: number;
    rentalYield: number;
    status: string;
}

export interface CustomerListingFilter {
    launchArea?: string;
    searchType: string;
    income1: number;
    income2?: number;
    postcode?: string;
    distanceRadiusMetres?: number;
    deposit: number;
    numOfAdults: number;
    children0to1: number;
    children2to4: number;
    children5to11: number;
    children12to16: number;
}

export const CustomerListingActionTypes = {
    FETCH_BY_CUSTOMER_DETAILS: "customer_listing/FETCH_BY_CUSTOMER_DETAILS",
    FETCH_SUCCESS: "customer_listing/FETCH_SUCCESS"
};

export interface CustomerListingState {
    readonly byID: { [customerListingID: string]: CustomerListing };
    readonly allIDs: string[];
}
