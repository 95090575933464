import { Listing } from "store/listing";

export const getStatusValue = (status: Listing["status"]) => {
    switch (status) {
        case "prefilter":
            return 1;
        case "addressMatch":
            return 2;
        case "basicAnalysis":
            return 3;
        case "rentalValuation":
            return 4;
        case "rentalValuationTwo":
            return 5;
        case "rentalModeration":
            return 6;
        case "salesValuation":
            return 7;
        case "salesValuationTwo":
            return 8;
        case "salesModeration":
            return 9;
        case "reviewed":
            return 10;
        default:
            return 0;
    }
};

export const checkIfStatusNeedsUpdating = (currentStatus: Listing["status"], nextStatus: Listing["status"]) => {
    const currentStatusValue = getStatusValue(currentStatus);
    const nextStatusValue = getStatusValue(nextStatus);

    return currentStatusValue >= nextStatusValue ? currentStatus : nextStatus;
};

export const statusIsMoreAdvanced = (currentStatus: Listing["status"], nextStatus: Listing["status"]) => {
    const currentStatusValue = getStatusValue(currentStatus);
    const nextStatusValue = getStatusValue(nextStatus);

    return currentStatusValue >= nextStatusValue;
};
