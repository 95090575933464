import React from "react";
import { formatDistanceStrict } from "date-fns";

import { SalesComparison } from "store/sales-comparison";
import { Listing } from "store/listing";
import {
    CardContentWrapper,
    CardList,
    CardSubTitle,
    CardTitle,
    CardItem,
    CardNotes,
    ValuationLabel,
    NotesWrapper,
    ValuationHeader,
    NotesHeader,
    CardNoteWrapper,
} from "../helper-components";
import {
    distanceDisplayDict,
    propertyDisplayValueDict,
    visualSalesStatus,
    visualDistance,
    visualComparisonRoomNumbers,
    visualComparisonType,
    visualAge,
    visualComparisonOutsideFeature,
    visualComparisonFloorArea,
} from "utils/data-helpers";
import { RentalInfoCondition } from "../rental-info/rental-info-condition";

export interface ISalesInfoProps {
    salesComparison: SalesComparison;
    listing: Listing;
    isValuation1?: boolean;
    isValuation2?: boolean;
}

export const SalesInfo: React.FC<ISalesInfoProps> = ({ salesComparison, listing, isValuation1, isValuation2 }) => {
    return (
        <>
            {isValuation1 && <ValuationLabel>Valuation 1</ValuationLabel>}
            {isValuation2 && <ValuationLabel>Valuation 2</ValuationLabel>}
            <CardTitle>{salesComparison.address}</CardTitle>
            <CardContentWrapper>
                <CardList>
                    <li>
                        <CardSubTitle>PROPERTY</CardSubTitle>
                    </li>
                    <li>
                        <CardItem visualStatus={visualDistance(salesComparison.distance)}>Distance:</CardItem>{" "}
                        {distanceDisplayDict(salesComparison.distance)}
                    </li>
                    <li>
                        <CardItem
                            visualStatus={visualComparisonType(
                                salesComparison.propertyType,
                                salesComparison.propertyStyle,
                                listing.propertyType,
                                listing.propertyStyle
                            )}
                        >
                            Type:
                        </CardItem>{" "}
                        {propertyDisplayValueDict(salesComparison.propertyType)}
                    </li>
                    <li>
                        <CardItem>Style:</CardItem> {propertyDisplayValueDict(salesComparison.propertyStyle)}
                    </li>
                    <li>
                        <CardItem
                            visualStatus={visualComparisonRoomNumbers(salesComparison.totalBedrooms, listing.bedrooms)}
                        >
                            Beds:
                        </CardItem>{" "}
                        {salesComparison.totalBedrooms || "Unknown"}
                    </li>
                    <li>
                        <CardItem
                            visualStatus={visualComparisonRoomNumbers(
                                salesComparison.totalBathrooms,
                                listing.totalBathrooms
                            )}
                        >
                            Baths:
                        </CardItem>{" "}
                        {salesComparison.totalBathrooms || "Unknown"}
                    </li>
                    <li>
                        <CardItem
                            visualStatus={visualComparisonFloorArea(listing.floorArea, salesComparison.floorArea)}
                        >
                            FloorArea:
                        </CardItem>{" "}
                        {salesComparison.floorArea || "Unknown"}
                    </li>
                    <li>
                        <CardItem
                            visualStatus={visualComparisonOutsideFeature(
                                listing.hasOutsideSpace,
                                salesComparison.hasOutsideSpace
                            )}
                        >
                            Outside:
                        </CardItem>{" "}
                        {propertyDisplayValueDict(salesComparison.hasOutsideSpace)}
                    </li>
                    <li>
                        <CardItem
                            visualStatus={visualComparisonOutsideFeature(
                                listing.hasParking,
                                salesComparison.hasParking
                            )}
                        >
                            Parking:
                        </CardItem>{" "}
                        {propertyDisplayValueDict(salesComparison.hasParking)}
                    </li>
                </CardList>
                <CardList>
                    <li>
                        <CardSubTitle>TRANSACTION</CardSubTitle>
                    </li>
                    <li>
                        <CardItem visualStatus={visualSalesStatus(salesComparison.priceSource)}>Status:</CardItem>{" "}
                        {propertyDisplayValueDict(salesComparison.priceSource)}
                    </li>
                    <li>
                        <CardItem
                            visualStatus={visualAge(
                                formatDistanceStrict(new Date(salesComparison.priceDate || Date.now()), Date.now())
                            )}
                        >
                            Age:
                        </CardItem>{" "}
                        {formatDistanceStrict(new Date(salesComparison.priceDate || Date.now()), Date.now())}
                    </li>
                    <li>
                        <CardItem>Price:</CardItem> £{salesComparison.price}
                    </li>
                </CardList>
                <RentalInfoCondition
                    rentalComparison={salesComparison}
                    listing={listing}
                    isValuation1={isValuation1}
                    isValuation2={isValuation2}
                />
            </CardContentWrapper>
            <NotesHeader>Notes</NotesHeader>
            <NotesWrapper>
                {salesComparison.notes ? (
                    <CardNoteWrapper>
                        {isValuation1 ? (
                            <ValuationHeader>Valuation 1</ValuationHeader>
                        ) : (
                            <ValuationHeader>Valuation 2</ValuationHeader>
                        )}
                        <CardNotes>{salesComparison.notes || ""}</CardNotes>
                    </CardNoteWrapper>
                ) : null}
                {salesComparison.valuation2Notes ? (
                    <CardNoteWrapper>
                        <ValuationHeader>Valuation 2</ValuationHeader>
                        <CardNotes>{salesComparison.valuation2Notes || ""}</CardNotes>
                    </CardNoteWrapper>
                ) : null}
            </NotesWrapper>
        </>
    );
};
