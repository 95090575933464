import { Listing } from "store/listing";
import { Choice } from "components/form/multi-choice-button";

export const generateOptions = (name: string): Choice[] => {
    return [
        { displayName: "Yes", keyHint: "1", name, value: true },
        { displayName: "No", keyHint: "2", name, value: false },
        { displayName: "Unknown", keyHint: "3", name, value: "unknown" }
    ];
};

export const prefilteringFormData: { [key in keyof Listing]?: { label: string; options: Choice[] } } = {
    isExteriorGood: { label: "Is the exterior good enough?", options: generateOptions("isExteriorGood") },
    isKitchenGood: { label: "Is the kitchen good enough?", options: generateOptions("isKitchenGood") },
    isBathroomGood: { label: "Is the bathroom good enough?", options: generateOptions("isBathroomGood") },
    isOverallGood: { label: "Is the overall good enough?", options: generateOptions("isOverallGood") },
    isFloorPlanGood: { label: "Is the floorplan good enough?", options: generateOptions("isFloorPlanGood") },
    isLocationGood: { label: "Is the location good enough?", options: generateOptions("isLocationGood") }
};
