import React, { Component } from "react";
import styled from "styled-components";
import { Formik } from "formik";

import { Button } from "components/button";
import { Input } from "components/form/input";
import { Heading3, Heading4 } from "typography";
import { listingSearchFormValidationSchema } from "./listing-search-form-validation-schema";
import { FormErrors, HorizontalMultiChoiceButton } from "components/form";
import { listingSearchFormData } from "./listing-search-form-data";
import { launchAreas } from "config/launch-areas";
import { Select } from "components/select";

export interface Props {
    className?: string;
    onSubmit: (props: FormProps) => void;
}

export interface FormProps {
    launchArea: string;
    postcode: string;
    radius: number;
    searchType: string;
    numOfAdults: number;
    income1: number;
    income2: number;
    deposit: number;
    children0to1: number;
    children2to4: number;
    children5to11: number;
    children12to16: number;
}

export class ListingSearchForm extends Component<Props> {
    handleSubmit = (values: FormProps) => {
        this.props.onSubmit(values);
    };

    render() {
        return (
            <Formik
                initialValues={{
                    launchArea: "",
                    postcode: "",
                    searchType: "prefilter",
                    numOfAdults: 1,
                    radius: 0,
                    income1: 0,
                    income2: 0,
                    deposit: 0,
                    children0to1: 0,
                    children2to4: 0,
                    children5to11: 0,
                    children12to16: 0
                }}
                onSubmit={this.handleSubmit}
                validationSchema={listingSearchFormValidationSchema}
                validateOnChange={false}
                validateOnBlur={false}
                render={(props) => (
                    <>
                        {Object.keys(props.errors).length > 0 && (
                            <FormErrors>
                                <ul>
                                    {Object.keys(props.errors).map((error, index) => (
                                        <li key={index}>{props.errors[error]}</li>
                                    ))}
                                </ul>
                            </FormErrors>
                        )}
                        <FormContainer onSubmit={props.handleSubmit}>
                            <Headline>Location, Location, Location</Headline>
                            <InputWrapper>
                                <Label>Launch Area</Label>
                                <Select name="launchArea" onChange={props.handleChange}>
                                    <option value="">----</option>
                                    {launchAreas.map((area, index) => (
                                        <option value={area} key={index}>
                                            {area}
                                        </option>
                                    ))}
                                </Select>
                            </InputWrapper>
                            <TwoColInputWrapper>
                                <StyledLabel>
                                    <Label>Postcode</Label>
                                    <StyledInput name="postcode" onChange={props.handleChange} />
                                </StyledLabel>
                                <Label>
                                    <Label>Distance (metres)</Label>
                                    <StyledInput name="radius" onChange={props.handleChange} />
                                </Label>
                            </TwoColInputWrapper>
                            <Headline>Search Type</Headline>
                            <InputWrapper>
                                <Label>
                                    <HorizontalMultiChoiceButton
                                        choices={listingSearchFormData.searchType || []}
                                        onChange={props.handleChange}
                                        selectedValue={props.values.searchType}
                                    />
                                </Label>
                            </InputWrapper>
                            <Headline>Customer details</Headline>
                            <TwoColInputWrapper>
                                <StyledLabel>
                                    <LabelHeading>Status</LabelHeading>
                                    <HorizontalMultiChoiceButton
                                        choices={listingSearchFormData.numOfAdults || []}
                                        onChange={props.handleChange}
                                        selectedValue={props.values.numOfAdults}
                                    />
                                </StyledLabel>
                                <Label>
                                    <Label>Deposit</Label>
                                    <Input name="deposit" onChange={props.handleChange} />
                                </Label>
                            </TwoColInputWrapper>
                            <TwoColInputWrapper>
                                <StyledLabel>
                                    <Label>Income 1</Label>
                                    <StyledInput name="income1" onChange={props.handleChange} />
                                </StyledLabel>
                                <Label>
                                    <Label>Income 2</Label>
                                    <StyledInput name="income2" onChange={props.handleChange} />
                                </Label>
                            </TwoColInputWrapper>
                            <InputWrapper>
                                <Label>
                                    <LabelHeading>Children Aged 0 - 1</LabelHeading>
                                    <HorizontalMultiChoiceButton
                                        choices={listingSearchFormData.children0to1 || []}
                                        onChange={props.handleChange}
                                        selectedValue={props.values.children0to1}
                                    />
                                </Label>
                            </InputWrapper>
                            <InputWrapper>
                                <Label>
                                    <LabelHeading>Children Aged 2 - 4</LabelHeading>
                                    <HorizontalMultiChoiceButton
                                        choices={listingSearchFormData.children2to4 || []}
                                        onChange={props.handleChange}
                                        selectedValue={props.values.children2to4}
                                    />
                                </Label>
                            </InputWrapper>
                            <InputWrapper>
                                <Label>
                                    <LabelHeading>Children Aged 5 - 11</LabelHeading>
                                    <HorizontalMultiChoiceButton
                                        choices={listingSearchFormData.children5to11 || []}
                                        onChange={props.handleChange}
                                        selectedValue={props.values.children5to11}
                                    />
                                </Label>
                            </InputWrapper>
                            <InputWrapper>
                                <Label>
                                    <LabelHeading>Children Aged 12 - 16</LabelHeading>
                                    <HorizontalMultiChoiceButton
                                        choices={listingSearchFormData.children12to16 || []}
                                        onChange={props.handleChange}
                                        selectedValue={props.values.children12to16}
                                    />
                                </Label>
                            </InputWrapper>
                            <StyledButton type="submit">Search</StyledButton>
                        </FormContainer>
                    </>
                )}
            />
        );
    }
}

const Headline = styled.p`
    ${Heading3};
    margin: 20px 0 10px 10px;
    color: ${(props) => props.theme.grey700};
`;

const FormContainer = styled.form`
    display: flex;
    flex-direction: column;
`;

const Label = styled.div`
    ${Heading4};
    font-weight: 500;
    color: ${(props) => props.theme.grey600};
`;

const StyledLabel = styled(Label)`
    margin-right: 30px;
    min-width: 145px;
`;

const LabelHeading = styled(Label)`
    margin-bottom: 11px;
`;

const InputWrapper = styled.div`
    display: flex;
    flex-direction: row;
    width: 180px;
    margin: 10px 15px 10px 10px;
`;

const TwoColInputWrapper = styled.div`
    display: flex;
    width: 320px;
    margin: 10px 15px 10px 10px;
`;

const StyledButton = styled(Button)`
    margin: 20px 0 0 10px;
    width: 100px;
`;

const StyledInput = styled(Input)`
    width: 100%;
`;

StyledButton.displayName = "SearchButton";
