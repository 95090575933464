import React, { Component } from "react";
import ReactDOM from "react-dom";
import styled from "styled-components";

import { ErrorModal } from "../error-modal";

export interface Props {
    className?: string;
    errorMessage: string;
    onClose: () => void;
    isShowing?: boolean;
}

export class Error extends Component<Props> {
    render() {
        const { className, errorMessage, onClose, isShowing } = this.props;

        return (
            <Wrapper className={className}>
                {ReactDOM.createPortal(
                    <ErrorModal onClose={onClose} isShowing={isShowing} errorMessage={errorMessage} />,
                    document.getElementById("portal") as HTMLElement
                )}
            </Wrapper>
        );
    }
}

const Wrapper = styled.div`
    position: relative;
`;
