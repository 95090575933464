import React from "react";
import styled from "styled-components";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";

import LeftArrow from "components/media/svg/carousel-left-arrow.svg";
import RightArrow from "components/media/svg/carousel-right-arrow.svg";

export interface Props {
    className?: string;
    images: string[];
    enableBrowserZoom?: boolean;
}

export const ImageCarousel = ({ className, enableBrowserZoom, images }: Props) => {
    const formattedImages = images.map((url) => ({ original: url }));

    return (
        <Wrapper className={className} enableBrowserZoom={enableBrowserZoom}>
            <Container>
                <ImageGallery
                    items={formattedImages}
                    showThumbnails={false}
                    showFullscreenButton={true}
                    showPlayButton={false}
                    useBrowserFullscreen={false}
                    slideInterval={0}
                    slideDuration={0}
                />
            </Container>
        </Wrapper>
    );
};

const Container = styled.div``;

const Wrapper = styled.div<{ enableBrowserZoom?: boolean }>`
    width: 296px;

    .fullscreen .image-gallery-slide img {
        width: auto;
        margin: 0 auto;
        display: block;
        height: 100vh;
    }

    .image-gallery-fullscreen-button {
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 70%;
        height: 100%;

        &.active:before,
        :before {
            content: "";
            padding: 0;
        }
    }

    ${(props) =>
        props.enableBrowserZoom &&
        `
        .fullscreen .image-gallery-slide img {
            height: 100%;
        }

        .fullscreen .image-gallery-slides {
            overflow-y: scroll;
            height: 100vh;
        }

        .fullscreen .image-gallery-fullscreen-button {
            display: none;
        }

    `}

    .image-gallery-left-nav,
    .image-gallery-right-nav {
        width: 25px;
        height: 40%;
        padding: 0;

        &.active::before,
        ::before {
            content: " ";
        }
    }

    .fullscreen {
        .image-gallery-left-nav,
        .image-gallery-right-nav {
            width: 40px;
        }
    }

    .image-gallery-svg {
        display: none;
    }

    .image-gallery-left-nav {
        background: url(${LeftArrow}) 0 50% no-repeat;
    }

    .image-gallery-right-nav {
        background: url(${RightArrow}) 100% 50% no-repeat;
    }
`;
