import { number, object, string, array } from "yup";

export const salesModerationValidationSchema = object({
    moderatedMinPrice: number().min(500, "Please enter an appropriate min price").required("The min price is required"),
    moderatedMaxPrice: number().min(500, "Please enter an appropriate max price").required("The max price is required"),
    salesModerationNotes: string()
        .min(50, "Your comment needs to be a minimum of 50 characters")
        .required("Please fill in the comments field"),
    rankings: array()
        .test(
            "hasRankings",
            "You must enter a rank for all non-dismissed comps",
            (value) => Array.isArray(value) && value.every(Boolean)
        )
        .test(
            "hasAtLeast3Comps",
            "Please select 3 comps to bring forward",
            (value) => Array.isArray(value) && value.length >= 3
        )
        .test(
            "hasNoMoreThan3Comps",
            "You can only select 3 comps to bring forward. Dismiss any unwanted comps",
            (value) => Array.isArray(value) && value.length <= 3
        ),
});
