import { Reducer } from "redux";

import { CustomerListingState, CustomerListingActionTypes } from "./types";
import { dataToObjectByKey, getAllIDsFromData } from "utils/data-helpers";
import { globaliseSelectors } from "utils/globalise-selectors";

const initialState: CustomerListingState = {
    byID: {},
    allIDs: []
};

const reducer: Reducer<CustomerListingState> = (state = initialState, action) => {
    switch (action.type) {
        case CustomerListingActionTypes.FETCH_SUCCESS:
            return {
                ...state,
                byID: dataToObjectByKey(action.payload, "ID"),
                allIDs: getAllIDsFromData(action.payload, "ID")
            };

        default:
            return state;
    }
};

export { reducer as customerListingReducer };

export const getAllCustomerListings = (state: CustomerListingState) => {
    return Object.keys(state.byID).map((key) => state.byID[key]);
};

export const customerListingSelectors = globaliseSelectors(
    {
        getAllCustomerListings
    },
    "customerListings"
);
