import React from "react";
import styled from "styled-components";

import { Body } from "typography";

interface Props extends React.SelectHTMLAttributes<HTMLSelectElement> {
    className?: string;
    children: React.ReactNode;
}

export const Select = ({ className, children, ...rest }: Props) => (
    <SelectWrapper className={className}>
        <StyledSelect {...rest}>{children}</StyledSelect>
    </SelectWrapper>
);

const SelectWrapper = styled.div`
    display: inline-block;
    position: relative;
    margin: 5px 5px 5px 0;
    background: #fff;
    border: 1px solid ${(props) => props.theme.grey400};
    border-radius: 3px;

    ::after {
        position: absolute;
        content: "\\25BC";
        pointer-events: none;
        top: 55%;
        right: 10px;
        transform: translateY(-50%);
        font-size: 11px;
        color: ${(props) => props.theme.grey500};
    }
`;

const StyledSelect = styled.select`
    ${Body};
    width: 100%;
    padding: 8px 30px 8px 10px;
    font-size: inherit;
    background: inherit;
    border: none;
    appearance: none;
`;
