import React, { Fragment } from "react";
import styled from "styled-components/macro";

import { MultiChoiceButton, Choice } from "components/form/multi-choice-button";

export interface Props {
    className?: string;
    choices: Choice[];
    selectedValue?: any;
    onChange: (event: any) => void;
}

type MultiChoiceValueTypes = string | boolean | number | null | undefined;

export const getIsSelected = (selectedValue: MultiChoiceValueTypes, choiceValue: MultiChoiceValueTypes): boolean => {
    if (selectedValue === undefined || choiceValue === undefined) {
        return false;
    } else if (selectedValue === null || choiceValue === null) {
        return selectedValue === choiceValue;
    } else {
        return selectedValue.toString() === choiceValue.toString();
    }
};

export const HorizontalMultiChoiceButton = ({ className, choices, selectedValue, onChange }: Props) => {
    return (
        <Wrapper className={className}>
            {choices.map((choice, index) => {
                const isSelected = getIsSelected(selectedValue, choice.value);
                return (
                    <Fragment key={index}>
                        <StyledMultiChoiceButton
                            radioButtonName={choice.name}
                            radioButtonValue={choice.value}
                            onChange={onChange}
                            isSelected={isSelected}
                        >
                            <ButtonText isSelected={isSelected}>
                                {choice.displayName}
                                {choice.keyHint && <sup>{choice.keyHint}</sup>}
                            </ButtonText>
                        </StyledMultiChoiceButton>
                    </Fragment>
                );
            })}
        </Wrapper>
    );
};

const Wrapper = styled.div`
    display: flex;
`;

const StyledMultiChoiceButton = styled(MultiChoiceButton)<{ isSelected?: boolean }>`
    padding: 8px 16px;
    display: inline-block;
    text-align: center;
    border-radius: 0;
    border-right-width: 0px;

    :first-of-type,
    :first-of-type::before {
        border-radius: ${(props) => props.theme.multiChoiceBorderRadius}px 0 0
            ${(props) => props.theme.multiChoiceBorderRadius}px;
    }
    :last-of-type,
    :last-of-type::after {
        border-right-width: 1px;
        border-radius: 0 ${(props) => props.theme.multiChoiceBorderRadius}px
            ${(props) => props.theme.multiChoiceBorderRadius}px 0;
    }
    ::before,
    ::after {
        height: 100%;
        border-top: ${(props) => props.theme.multiChoiceBorderWidth}px solid ${(props) => props.theme.grey600};
        border-bottom: ${(props) => props.theme.multiChoiceBorderWidth}px solid ${(props) => props.theme.grey600};
    }
    ::before {
        top: -${(props) => props.theme.multiChoiceBorderWidth}px;
        left: 0;
    }
    :after {
        bottom: -${(props) => props.theme.multiChoiceBorderWidth}px;
        right: -${(props) => props.theme.multiChoiceBorderWidth}px;
    }
    ${(props) =>
        props.isSelected &&
        `
        border-top-color: ${props.theme.success};
        border-bottom-color: ${props.theme.success};
        ::before,
        ::after {
            border-top-color: ${props.theme.success};
            border-bottom-color: ${props.theme.success};
        }
        ::before {
            border-left-color: ${props.theme.success};
        }
        ::after {
            border-right-color: ${props.theme.success};
        }
    `}
`;

StyledMultiChoiceButton.displayName = "MultiChoiceButton";

const ButtonText = styled.span<{ isSelected?: boolean }>`
    width: 100%;
    pointer-events: none;
    display: block;
    color: inherit;
    letter-spacing: 0;
    text-align: center;
    z-index: 2;
    sup {
        vertical-align: super;
    }
`;
