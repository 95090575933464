export const UIActionTypes = {
    SHOW_LOADING: "UI/SHOW_LOADING",
    HIDE_LOADING: "UI/HIDE_LOADING",
    SHOW_API_ERROR: "UI/SHOW_API_ERROR",
    HIDE_API_ERROR: "UI/HIDE_API_ERROR"
};

export interface UIState {
    isLoading: boolean;
    showApiError: boolean;
    apiErrorMessage: string;
}
