import React from "react";
import { Formik } from "formik";
import styled from "styled-components";
import { Button, TetriaryButton } from "components/button";
import { Input } from "components/form/input";
import { Select } from "components/select";

import { Listing } from "store/listing";
import { Body, Heading3 } from "typography";
import { Choice } from "components/form/multi-choice-button";
import { HorizontalMultiChoiceButton } from "components/form/horizontal-multi-choice-button";
import { FormErrors, FormLink, Label } from "components/form";
import { Info } from "components/info";
import { propertyDetailFormData } from "./property-details-form-data";
import { propertyDetailsValidationSchema } from "./property-details-validation-schema";
import {
    filterPropertyStyleByType,
    yearsRemainingOnLease,
    handleClickUnknown,
    getInitialFormValues,
    getLeaseData,
    tenureIsLeaseholdOrShareOfFreehold,
} from "utils/data-helpers";
import { inputWithUnknownButton } from "utils/styles";
import { withTimeTracking, TimeTrackingProps } from "hoc/with-time-tracking";
import { generateEPCLink } from "utils/url-helpers";
import { checkIfStatusNeedsUpdating, statusIsMoreAdvanced } from "utils/status-check";

interface Props {
    listing: Listing;
    updateListing: (listing: Listing) => void;
}

export const PropertyDetailsForm: React.FC<Props & TimeTrackingProps> = ({
    listing,
    updateListing,
    getElapsedTaskTime,
}) => {
    const handleOnSubmit = (values: Listing) => {
        const elapsedTaskTime = getElapsedTaskTime();
        const basicAnalysisData: Listing = {
            ...values,
            status: checkIfStatusNeedsUpdating(values.status, "rentalValuation"),
        };

        statusIsMoreAdvanced(values.status, "rentalValuation")
            ? (basicAnalysisData.qaBasicAnalysisCompletedIn = elapsedTaskTime)
            : (basicAnalysisData.basicAnalysisCompletedIn = elapsedTaskTime);

        updateListing(basicAnalysisData);
    };

    return (
        <Formik
            initialValues={getInitialFormValues(listing)}
            onSubmit={handleOnSubmit}
            validateOnChange={false}
            validateOnBlur={false}
            validationSchema={propertyDetailsValidationSchema}
        >
            {({ errors, values, handleSubmit, handleChange, setFieldValue }) => {
                const showPreliminaryYears = Boolean(
                    values.leaseDate === "unknown" || values.leaseLength === "unknown" || !values.leaseLength
                );
                const preliminaryYearsData = getLeaseData(values).find(
                    (data) => data.fieldName === "preliminaryYearsRemaining"
                );
                return (
                    <FormContainer onSubmit={handleSubmit}>
                        <FirstSection>
                            {Object.keys(propertyDetailFormData).map((key) =>
                                ["propertyType", "propertyStyle", "propertyTenure"].includes(key) ? (
                                    <InputWrapper key={key}>
                                        <GroupLabel>{key}</GroupLabel>
                                        <HorizontalMultiChoiceButton
                                            choices={
                                                key === "propertyStyle"
                                                    ? filterPropertyStyleByType(
                                                          values.propertyType,
                                                          propertyDetailFormData.propertyStyle
                                                      )
                                                    : propertyDetailFormData[key]
                                            }
                                            onChange={handleChange}
                                            selectedValue={values[key]}
                                        />
                                    </InputWrapper>
                                ) : null
                            )}
                        </FirstSection>
                        {tenureIsLeaseholdOrShareOfFreehold(values.propertyTenure) && (
                            <StyledFormGrid>
                                {getLeaseData(values).map(
                                    (data) =>
                                        data.fieldName !== "preliminaryYearsRemaining" && (
                                            <InputWrapper key={data.fieldName}>
                                                <BlockLabel>
                                                    {data.displayName}

                                                    {inputWithUnknownButton(
                                                        data,
                                                        setFieldValue,
                                                        handleChange,
                                                        handleClickUnknown
                                                    )}
                                                </BlockLabel>
                                            </InputWrapper>
                                        )
                                )}
                                {showPreliminaryYears && preliminaryYearsData ? (
                                    <InputWrapper key={preliminaryYearsData.fieldName}>
                                        <BlockLabel>
                                            {preliminaryYearsData.displayName}

                                            {inputWithUnknownButton(
                                                preliminaryYearsData,
                                                setFieldValue,
                                                handleChange,
                                                handleClickUnknown
                                            )}
                                        </BlockLabel>
                                    </InputWrapper>
                                ) : (
                                    <>
                                        <BlockLabel>Remaining Years On Lease</BlockLabel>
                                        {yearsRemainingOnLease(values.leaseDate, values.leaseLength)}
                                    </>
                                )}
                            </StyledFormGrid>
                        )}
                        <FormGrid>
                            <GridItem>
                                Rooms
                                <Info title="Room Guidance">
                                    <ul>
                                        <ListingItem>
                                            &lt; 5 m<SuperText>2</SuperText> non-habitable
                                        </ListingItem>
                                        <ListingItem>
                                            &lt; 10 m<SuperText>2</SuperText> single bedroom
                                        </ListingItem>
                                        <ListingItem>
                                            &gt; 10 m<SuperText>2</SuperText> double bedroom
                                        </ListingItem>
                                    </ul>
                                </Info>
                                <InputWrapper>
                                    <Label>
                                        <GroupLabel>Bedrooms</GroupLabel>
                                        {Object.keys(propertyDetailFormData).map((key) =>
                                            ["totalDoubleBedrooms", "totalSingleBedrooms"].includes(key) ? (
                                                <BlockSelect
                                                    key={key}
                                                    name={key}
                                                    onChange={handleChange}
                                                    value={values[key]}
                                                >
                                                    {propertyDetailFormData[key] &&
                                                        propertyDetailFormData[key].map((data: Choice) => (
                                                            <option key={data.value} value={data.value}>
                                                                {data.displayName}
                                                            </option>
                                                        ))}
                                                </BlockSelect>
                                            ) : null
                                        )}
                                    </Label>
                                </InputWrapper>
                                <InputWrapper>
                                    <Label>
                                        Amenities
                                        {Object.keys(propertyDetailFormData).map((key) =>
                                            ["totalBathrooms", "totalWCs"].includes(key) ? (
                                                <BlockSelect
                                                    key={key}
                                                    name={key}
                                                    onChange={handleChange}
                                                    value={values[key]}
                                                >
                                                    {propertyDetailFormData[key] &&
                                                        propertyDetailFormData[key].map((data: Choice) => (
                                                            <option key={data.value} value={data.value}>
                                                                {data.displayName}
                                                            </option>
                                                        ))}
                                                </BlockSelect>
                                            ) : null
                                        )}
                                    </Label>
                                </InputWrapper>
                            </GridItem>

                            <GridItem>
                                Area
                                <InputWrapper>
                                    <BlockLabel>
                                        Floor (m<SuperText>2</SuperText>)
                                        <FlexWrapper>
                                            <StyledTextInput
                                                name="floorArea"
                                                value={values.floorArea}
                                                onChange={handleChange}
                                            />
                                            <UnknownButton
                                                type="button"
                                                data-testid="set-unknown"
                                                onClick={() => handleClickUnknown("floorArea", setFieldValue)}
                                                isSet={values.floorArea === "unknown"}
                                            >
                                                Unk
                                            </UnknownButton>
                                        </FlexWrapper>
                                    </BlockLabel>
                                </InputWrapper>
                                <InputWrapper>
                                    <BlockLabel>
                                        Floor Area Source
                                        <BlockSelect
                                            name="floorAreaSource"
                                            value={values.floorAreaSource}
                                            onChange={handleChange}
                                        >
                                            {propertyDetailFormData["floorAreaSource"] &&
                                                propertyDetailFormData["floorAreaSource"].map((data: Choice) => (
                                                    <option key={data.value} value={data.value}>
                                                        {data.displayName}
                                                    </option>
                                                ))}
                                        </BlockSelect>
                                    </BlockLabel>
                                </InputWrapper>
                                {Object.keys(propertyDetailFormData).map((key) =>
                                    ["hasOutsideSpace", "hasParking"].includes(key) ? (
                                        <InputWrapper key={key}>
                                            <GroupLabel>{key.replace("has", "")}</GroupLabel>
                                            <HorizontalMultiChoiceButton
                                                choices={propertyDetailFormData[key]}
                                                onChange={handleChange}
                                                selectedValue={values[key]}
                                            />
                                        </InputWrapper>
                                    ) : null
                                )}
                            </GridItem>

                            <GridItem>
                                EPC
                                <FormLink
                                    href={generateEPCLink(listing.address || "", listing.postcode || "")}
                                    target="_blank"
                                >
                                    Get EPC Data &gt;
                                </FormLink>
                                <InputWrapper>
                                    <Label>
                                        Current EPC
                                        <FlexWrapper>
                                            <StyledTextInput
                                                name="EPCCurrent"
                                                value={values.EPCCurrent}
                                                onChange={handleChange}
                                            />
                                            <UnknownButton
                                                type="button"
                                                data-testid="set-unknown"
                                                onClick={() => handleClickUnknown("EPCCurrent", setFieldValue)}
                                                isSet={values.EPCCurrent === "unknown"}
                                            >
                                                Unk
                                            </UnknownButton>
                                        </FlexWrapper>
                                    </Label>
                                </InputWrapper>
                                <InputWrapper>
                                    <Label>
                                        Potential EPC
                                        <FlexWrapper>
                                            <StyledTextInput
                                                name="EPCPotential"
                                                value={values.EPCPotential}
                                                onChange={handleChange}
                                            />
                                            <UnknownButton
                                                type="button"
                                                data-testid="set-unknown"
                                                onClick={() => handleClickUnknown("EPCPotential", setFieldValue)}
                                                isSet={values.EPCPotential === "unknown"}
                                            >
                                                Unk
                                            </UnknownButton>
                                        </FlexWrapper>
                                    </Label>
                                </InputWrapper>
                            </GridItem>

                            <GridItem>
                                Misc
                                <InputWrapper>
                                    <GroupLabel>Council tax band</GroupLabel>
                                    <HorizontalMultiChoiceButton
                                        choices={propertyDetailFormData.councilTaxBand || []}
                                        onChange={handleChange}
                                        selectedValue={values.councilTaxBand}
                                    />
                                    <FormLink
                                        href={`http://www.mycounciltax.org.uk/results?postcode=${listing.postcode?.replace(
                                            " ",
                                            "+"
                                        )}&search=Search`}
                                        target="_blank"
                                    >
                                        Search &gt;
                                    </FormLink>
                                </InputWrapper>
                                <InputWrapper>
                                    <BlockLabel>
                                        Council Tax Annual Price
                                        <FlexWrapper>
                                            <StyledTextInput
                                                name="councilTaxPrice"
                                                value={values.councilTaxPrice}
                                                onChange={handleChange}
                                            />
                                            <UnknownButton
                                                type="button"
                                                data-testid="set-unknown"
                                                onClick={() => handleClickUnknown("councilTaxPrice", setFieldValue)}
                                                isSet={values.councilTaxPrice === "unknown"}
                                            >
                                                Unk
                                            </UnknownButton>
                                        </FlexWrapper>
                                    </BlockLabel>
                                </InputWrapper>
                                <InputWrapper>
                                    <BlockLabel>
                                        Age
                                        <BlockSelect name="age" onChange={handleChange} value={values.age}>
                                            {propertyDetailFormData.age &&
                                                propertyDetailFormData.age.map((data) => (
                                                    <option key={data.value} value={data.value}>
                                                        {data.displayName}
                                                    </option>
                                                ))}
                                        </BlockSelect>
                                    </BlockLabel>
                                    <FormLink
                                        href="https://maps.cdrc.ac.uk/#/metrics/dwellingage/default/BTTTFFT/10/-0.1500/51.5200/"
                                        target="_blank"
                                    >
                                        Search &gt;
                                    </FormLink>
                                </InputWrapper>
                            </GridItem>
                        </FormGrid>
                        <HorizontalDivider />
                        <SectionHeading>Review property condition</SectionHeading>
                        <FlexWrapper>
                            <div>
                                {Object.keys(propertyDetailFormData).map((key) =>
                                    [
                                        "conditionOverall",
                                        "conditionInterior",
                                        "conditionExterior",
                                        "conditionKitchen",
                                        "conditionBathroom",
                                    ].includes(key) ? (
                                        <InputWrapper key={key}>
                                            <GroupLabel>{key.replace("condition", "")}</GroupLabel>
                                            <HorizontalMultiChoiceButton
                                                choices={propertyDetailFormData[key]}
                                                onChange={handleChange}
                                                selectedValue={values[key]}
                                            />
                                        </InputWrapper>
                                    ) : null
                                )}
                            </div>
                            <InputWrapper>
                                <BlockLabel>
                                    Condition Notes
                                    <TextAreaInput
                                        name="conditionNotes"
                                        as="textarea"
                                        onChange={handleChange}
                                        value={values.conditionNotes || "[Layout]\n[Parking]\n[Locale]\n[Other info]"}
                                    />
                                </BlockLabel>
                            </InputWrapper>
                        </FlexWrapper>
                        {Object.keys(errors).length > 0 && (
                            <FormErrors>
                                <ul data-testid="errors">
                                    {Object.keys(errors).map((error, index) => (
                                        <li key={index}>{errors[error]}</li>
                                    ))}
                                </ul>
                            </FormErrors>
                        )}
                        <StyledButton type="submit">Save details</StyledButton>
                    </FormContainer>
                );
            }}
        </Formik>
    );
};

const FormContainer = styled.form`
    margin: 15px 0 40px;
`;

const FirstSection = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
`;

const FormGrid = styled.div`
    display: grid;
    grid-template-columns: 1fr 2fr;
    margin-bottom: 20px;
`;

const GridItem = styled.div`
    padding: 10px 25px;

    &:nth-of-type(2n + 1) {
        padding-left: 0;
        border-right: 1px solid ${({ theme }) => theme.grey400};
    }
`;

const InputWrapper = styled.div`
    position: relative;
    padding: 10px 25px 5px 0;
`;

const GroupLabel = styled.div`
    ${Body};
    margin: 0 5px 5px 0;
    text-transform: capitalize;
`;

const BlockLabel = styled(Label)`
    display: block;
`;

const SuperText = styled.sup`
    vertical-align: super;
    font-size: 10px;
`;

const UnknownButton = styled(TetriaryButton)<{ isSet: boolean }>`
    min-width: initial;
    padding: 8px 20px;
    margin-left: 8px;
    align-self: flex-end;
    color: ${({ theme, isSet }) => (isSet ? "#fff" : theme.success)};
    background: ${({ theme, isSet }) => (isSet ? theme.success : "inherit")};
`;

const BlockSelect = styled(Select)`
    display: block;
    max-width: 175px;
`;

const StyledTextInput = styled(Input)`
    display: block;
    width: 100px;
`;

const StyledButton = styled(Button)`
    margin: 16px 0;
`;

const FlexWrapper = styled.div`
    display: flex;
`;

const TextAreaInput = styled(Input)`
    display: block;
    padding: 15px;
    width: 600px;
    height: 363px;
`;

const SectionHeading = styled.h3`
    ${Heading3};
    margin-top: 30px;
    font-weight: 700;
`;

const HorizontalDivider = styled.hr`
    border-top: 1px solid ${({ theme }) => theme.grey400};
`;

const ListingItem = styled.li`
    padding: 5px 0;
`;

const StyledFormGrid = styled(FormGrid)`
    display: flex;
    flex-direction: column;
`;

export default withTimeTracking(PropertyDetailsForm);
