import { Reducer } from "redux";

import { UIActionTypes, UIState } from "store/ui/types";
import { globaliseSelectors } from "utils/globalise-selectors";

const initialState: UIState = {
    isLoading: false,
    showApiError: false,
    apiErrorMessage: ""
};

const reducer: Reducer<UIState> = (state = initialState, action) => {
    switch (action.type) {
        case UIActionTypes.SHOW_LOADING:
            return {
                ...state,
                isLoading: true,
                showApiError: false
            };

        case UIActionTypes.HIDE_LOADING:
            return {
                ...state,
                isLoading: false
            };

        case UIActionTypes.SHOW_API_ERROR:
            return {
                ...state,
                isLoading: false,
                showApiError: true,
                apiErrorMessage: action.payload
            };

        case UIActionTypes.HIDE_API_ERROR:
            return {
                ...state,
                showApiError: false,
                apiErrorMessage: ""
            };

        default:
            return state;
    }
};

export { reducer as UIReducer };

export const getUILoadingState = (state: UIState) => state.isLoading;

export const getApiErrorStatus = (state: UIState) => state.showApiError;

export const getApiErrorMessage = (state: UIState) => state.apiErrorMessage;

export const UISelectors = globaliseSelectors(
    {
        getUILoadingState,
        getApiErrorStatus,
        getApiErrorMessage
    },
    "ui"
);
