import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Store } from "redux";
import { Provider } from "react-redux";
import { History } from "history";
import { ConnectedRouter } from "connected-react-router";
import { ThemeProvider } from "styled-components";
import { useAuth, hasAuthParams } from "react-oidc-context";

import { Theme } from "./config/theme";
import { AppState } from "./store";
import { Routes, routePaths } from "./routes";
import UIContainer from "containers/ui-container";
import { Header } from "components/header";
import { Error } from "components/error";
import { LoadingIndicator } from "components/loading-indicator";
import ScrollToTop from "./scroll-to-top";
import { GlobalStyles } from "components/global-styles";
import { ErrorBoundary } from "components/error-boundary/error-boundary";

const logo = { name: "Home", path: routePaths.home };

const navItems = [
    { name: "For Review", path: routePaths.review },
    { name: "Add", path: routePaths.addListing },
    { name: "Search Filtered Listings", path: routePaths.customerListingSearch },
];

interface DispatchProps {
    [key: string]: any;
}

interface OwnProps {
    store: Store<AppState>;
    history: History;
}

type Props = DispatchProps & OwnProps;

const App = ({ store, history }: Props) => {
    const auth = useAuth();
    const [hasTriedSignin, setHasTriedSignin] = useState(false);

    useEffect(() => {
        if (!hasAuthParams() && !auth.isAuthenticated && !auth.activeNavigator && !auth.isLoading && !hasTriedSignin) {
            auth.signinRedirect();
            setHasTriedSignin(true);
        }
    }, [auth, hasTriedSignin]);

    const handleLogout = () => {
        auth.removeUser();
        setHasTriedSignin(true);
    };

    if (auth.isLoading) {
        return <LoadingIndicator />;
    }

    return (
        <Provider store={store}>
            <ConnectedRouter history={history}>
                <ScrollToTop>
                    <ThemeProvider theme={Theme}>
                        <GlobalStyles />

                        <Header
                            navItems={navItems}
                            logo={logo}
                            logout={handleLogout}
                            isLoggedIn={auth.isAuthenticated}
                        />

                        <PageWrapper>
                            <ErrorBoundary>
                                <Routes isLoggedIn={auth.isAuthenticated} />
                            </ErrorBoundary>
                        </PageWrapper>

                        <UIContainer>
                            {({ showApiError, apiErrorMessage, closeError }) => (
                                <Error isShowing={showApiError} errorMessage={apiErrorMessage} onClose={closeError} />
                            )}
                        </UIContainer>

                        <UIContainer>{({ isLoading }) => (isLoading ? <LoadingIndicator /> : null)}</UIContainer>
                    </ThemeProvider>
                </ScrollToTop>
            </ConnectedRouter>
        </Provider>
    );
};

export default App;

const PageWrapper = styled.div`
    padding: 20px 32px;
`;
