import { all, call, put, select, takeEvery } from "redux-saga/effects";
import { push } from "connected-react-router";
import { captureException } from "@sentry/browser";

import { SalesComparison, SalesComparisonActionTypes } from "./types";
import {
    completeSalesValuation,
    fetchSalesComparisonsByListingID,
    fetchSalesComparisonsSuccess,
    fetchSCT,
} from "./actions";
import { showApiError } from "store/ui";
import { penceToPounds, poundsToPence } from "utils/data-helpers";
import { fetchWithAuth } from "utils/fetch-with-auth";
import { salesComparisonSelectors } from "./reducer";
import { routePaths } from "routes";

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

export const mapIncomingData = (data: any): SalesComparison => ({
    ID: data.ID,
    sourceID: data.sourceID,
    listingID: data.listingID,
    comparisonType: data.comparisonType,
    operatorID: data.operatorID,
    selectedBy: data.selectedBy,
    address: data.address,
    postcode: data.postcode,
    distance: data.distanceMeters,
    propertyType: data.propertyType,
    propertyStyle: data.propertyStyle,
    price: penceToPounds(data.price),
    priceSource: data.priceSource,
    priceDate: data.priceDate,
    totalRooms: data.totalRooms,
    totalSingleBedrooms: data.totalSingleBedrooms,
    totalDoubleBedrooms: data.totalDoubleBedrooms,
    totalBathrooms: data.totalBathrooms,
    floorArea: data.floorArea,
    hasOutsideSpace: data.hasOutsideSpace,
    hasParking: data.hasParking,
    conditionOverall: data.conditionOverall,
    conditionKitchen: data.conditionKitchen,
    conditionInterior: data.conditionInterior,
    conditionBathroom: data.conditionBathroom,
    conditionExterior: data.conditionExterior,
    images: data.images,
    floorPlans: data.floorplans,
    notes: data.notes,
    ranking: data.ranking,
});

export const mapOutgoingData = (salesComp: SalesComparison) => ({
    ID: salesComp.ID,
    sourceID: salesComp.sourceID?.toString(),
    listingID: salesComp.listingID,
    operatorID: salesComp.operatorID,
    comparisonType: salesComp.comparisonType,
    address: salesComp.address,
    distanceMeters: salesComp.distance?.toString(),
    propertyType: salesComp.propertyType,
    propertyStyle: salesComp.propertyStyle,
    price: salesComp.price && poundsToPence(salesComp.price),
    priceSource: salesComp.priceSource,
    priceDate: salesComp.priceDate,
    totalSingleBedrooms: salesComp.totalSingleBedrooms,
    totalDoubleBedrooms: salesComp.totalDoubleBedrooms,
    totalBedrooms: salesComp.totalBedrooms,
    totalBathrooms: salesComp.totalBathrooms,
    floorArea: salesComp.floorArea,
    hasOutsideSpace: salesComp.hasOutsideSpace,
    hasParking: salesComp.hasParking,
    images: salesComp.images,
    floorplans: salesComp.floorPlans,
    conditionOverall: salesComp.conditionOverall,
    conditionKitchen: salesComp.conditionKitchen,
    conditionInterior: salesComp.conditionInterior,
    conditionBathroom: salesComp.conditionBathroom,
    conditionExterior: salesComp.conditionExterior,
    notes: salesComp.notes,
    ranking: salesComp.ranking,
});

export const mapSCTDataToSalesComparison = (data: any, index?: string | number) => ({
    ID: `${index}-${data.ID}`,
    sourceID: data.ID,
    sourceURL: data.listingURL,
    address: data.address,
    postcode: data.postcode,
    propertyType: data.propertyType || undefined,
    propertyStyle: data.propertyStyle || undefined,
    distance: data.distance === null ? undefined : data.distance,
    priceDate: data.soldDate || undefined,
    priceSource: data.saleType || undefined,
    price: data.soldPrice || undefined,
    floorArea: data.floorArea || undefined,
    totalBathrooms: data.totalBathrooms === null ? undefined : data.totalBathrooms,
    totalBedrooms: data.totalBedrooms === null ? undefined : data.totalBedrooms,
    hasOutsideSpace: data.hasOutsideSpace === null ? undefined : data.hasOutsideSpace,
    hasParking: data.hasParking ? true : undefined,
    images: data.images,
    floorPlans: data.floorplans,
});

export function* handleFetchByListing(action: ReturnType<typeof fetchSalesComparisonsByListingID>) {
    try {
        const response = yield call(
            fetchWithAuth,
            `${API_ENDPOINT}/v1/rental-comparisons?listingID=${action.payload}`,
            { method: "GET" },
        );

        if (response.ok === true) {
            const body = yield response.json();
            const mappedData = body.results
                .map(mapIncomingData)
                .filter((comp: SalesComparison) => comp.comparisonType === "sales");

            yield put(fetchSalesComparisonsSuccess(mappedData));
        } else {
            captureException(JSON.stringify({ message: "failed to GET rental comparisons", status: response.status }));
            const body = yield response.json();
            yield put(showApiError(body.error));
        }
    } catch (error) {
        yield put(showApiError(error));
    }
}

export function* handleFetchSCT({ payload }: ReturnType<typeof fetchSCT>) {
    const params = new URLSearchParams({
        postcode: payload.postcode,
        listingID: payload.listingID,
        rentalOrSales: payload.rentalOrSales,
        numBeds: `${payload.numBeds}`,
        propertyType: payload.propertyType,
        propertyStyle: payload.propertyStyle,
    });

    if (payload.sourceID) {
        params.append("sourceID", payload.sourceID);
    }

    try {
        const response = yield call(fetchWithAuth, `${API_ENDPOINT}/v0/sct?${params.toString()}`, {
            method: "GET",
        });

        if (response.ok) {
            const body = yield response.json();
            const mappedData = body.results.map(mapSCTDataToSalesComparison);

            yield put(fetchSalesComparisonsSuccess(mappedData));
        } else {
            captureException(JSON.stringify({ message: "failed to fetch STC data", status: response.status }));
            const body = yield response.json();
            yield put(showApiError(body.error));
        }
    } catch (error) {
        yield put(showApiError(error));
    }
}

export function* handleCompletedSalesValuation(action: ReturnType<typeof completeSalesValuation>) {
    const { listingID } = action.payload;
    const salesComparisons: SalesComparison[] = yield select(salesComparisonSelectors.getAllCompleted);
    if (salesComparisons.length > 0) {
        try {
            yield all(
                salesComparisons.map(function* (salesComparison) {
                    const mappedSalesComparison = mapOutgoingData(salesComparison);
                    const response = yield call(fetchWithAuth, `${API_ENDPOINT}/v1/rental-comparisons`, {
                        method: "POST",
                        body: JSON.stringify({ ...mappedSalesComparison, ID: undefined }), // Don't need ID when saving to API
                    });

                    if (!response.ok) {
                        captureException(
                            JSON.stringify({ message: "failed to POST sales valuation", status: response.status }),
                        );
                        const body = yield response.json();
                        yield put(showApiError(body.error));
                    }
                }),
            );

            yield put(push(routePaths.salesValuationSummary.replace(":listingID", listingID)));
        } catch (error) {
            yield put(showApiError(error));
        }
    } else {
        yield put(showApiError("No sales comparisons were found"));
    }
}

function* watchFetchByListingRequests() {
    yield takeEvery(SalesComparisonActionTypes.FETCH_BY_LISTING_REQUEST, handleFetchByListing);
}

function* watchFetchSCTRequests() {
    yield takeEvery(SalesComparisonActionTypes.FETCH_SCT, handleFetchSCT);
}

function* watchCompletedSalesValuation() {
    yield takeEvery(SalesComparisonActionTypes.COMPLETED, handleCompletedSalesValuation);
}

function* salesComparisonSagas() {
    yield all([watchFetchByListingRequests(), watchFetchSCTRequests(), watchCompletedSalesValuation()]);
}

export { salesComparisonSagas };
