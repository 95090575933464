import React from "react";
import styled from "styled-components";
import { useDispatch } from "react-redux";

import { Listing, saveListingRequest } from "store/listing";
import ListingContainer from "containers/listing-container";
import { StatusNavigation } from "components/status-navigation";
import { MainListingInfoCard } from "components/comparable";

export interface IAnalysisLayoutProps {
    className?: string;
    /** The listing to display */
    listing: Listing;
    /** Whether or not the listing should be automatically opened */
    autoOpenListing?: boolean;
}

export const AnalysisLayout: React.FC<IAnalysisLayoutProps> = ({
    className,
    listing,
    children,
    autoOpenListing = true,
}) => {
    const dispatch = useDispatch();

    return (
        <Wrapper className={className}>
            <LeftSideWrapper>
                <ListingContainer>
                    {({ saveListing }) => (
                        <StatusNavigation
                            listingID={listing.ID}
                            listingStatus={listing.status || "prefilter"}
                            saveListing={saveListing}
                        />
                    )}
                </ListingContainer>
                <MainListingInfoCard
                    listing={listing}
                    autoOpenListing={autoOpenListing}
                    updateListing={(listing: Listing, leaseholdInfoUpdate?: boolean) =>
                        dispatch(saveListingRequest(listing, leaseholdInfoUpdate))
                    }
                />
                {children}
            </LeftSideWrapper>
        </Wrapper>
    );
};

const Wrapper = styled.div`
    display: flex;
`;

const LeftSideWrapper = styled.div`
    width: 50%;
    min-width: 1050px;
`;
