import React, { Component } from "react";
import styled from "styled-components";
import { Button } from "components/button";

import { Body } from "typography";

export interface Props {
    className?: string;
    isShowing?: boolean;
    errorMessage: string;
    onClose: () => void;
}

export class ErrorModal extends Component<Props> {
    render() {
        const { className, isShowing, errorMessage, onClose } = this.props;
        return isShowing ? (
            <Wrapper className={className}>
                <Overlay />
                <Modal>
                    <ErrorText>Error: {errorMessage}</ErrorText>
                    <CloseButton onClick={onClose}>Close</CloseButton>
                </Modal>
            </Wrapper>
        ) : null;
    }
}

const Wrapper = styled.div`
    position: fixed;
    z-index: 2;
`;

const Overlay = styled.div`
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: ${(props) => props.theme.grey700};
    opacity: 0.9;
`;

const Modal = styled.div`
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    border-radius: 4px;
    margin: 0 auto;
    padding: 15px;
`;

const ErrorText = styled.p`
    ${Body};
    text-align: center;
`;

const CloseButton = styled(Button)`
    display: block;
    margin: 15px auto 0;
    background-color: ${(props) => props.theme.failure};
`;

CloseButton.displayName = "CloseButton";
