import { all, put, takeLatest } from "redux-saga/effects";
import { hideLoadingIndicator, showLoadingIndicator } from "store/ui/actions";
import { ListingActionTypes } from "store/listing";
import { RentalComparisonActionTypes } from "store/rental-comparison";
import { CustomerListingActionTypes } from "store/customer-listing/types";
import { SalesComparisonActionTypes } from "../sales-comparison";

export function* handleApiRequests() {
    yield put(showLoadingIndicator());
}

export function* handleApiSuccess() {
    yield put(hideLoadingIndicator());
}

function* watchApiRequests() {
    yield takeLatest(
        [
            ListingActionTypes.FETCH_REQUEST,
            ListingActionTypes.FETCH_ALL_REQUEST,
            ListingActionTypes.SAVE_REQUEST,
            ListingActionTypes.CREATE_REQUEST,
            ListingActionTypes.SAVE_SELECTED_COMPS,
            RentalComparisonActionTypes.FETCH_BY_LISTING_REQUEST,
            RentalComparisonActionTypes.CREATE_REQUEST,
            RentalComparisonActionTypes.SAVE_REQUEST,
            RentalComparisonActionTypes.FETCH_SCT,
            CustomerListingActionTypes.FETCH_BY_CUSTOMER_DETAILS,
            SalesComparisonActionTypes.FETCH_BY_LISTING_REQUEST,
            SalesComparisonActionTypes.CREATE_REQUEST,
            SalesComparisonActionTypes.SAVE_REQUEST,
            SalesComparisonActionTypes.FETCH_SCT,
        ],
        handleApiRequests,
    );
}

function* watchApiSuccess() {
    yield takeLatest(
        [
            ListingActionTypes.FETCH_SUCCESS,
            ListingActionTypes.FETCH_ALL_SUCCESS,
            ListingActionTypes.SAVE_SUCCESS,
            ListingActionTypes.CREATE_SUCCESS,
            RentalComparisonActionTypes.FETCH_SUCCESS,
            RentalComparisonActionTypes.SAVE_SUCCESS,
            RentalComparisonActionTypes.CREATE_SUCCESS,
            RentalComparisonActionTypes.FETCH_SCT_SUCCESS,
            CustomerListingActionTypes.FETCH_SUCCESS,
            SalesComparisonActionTypes.FETCH_SUCCESS,
            SalesComparisonActionTypes.SAVE_SUCCESS,
            SalesComparisonActionTypes.CREATE_SUCCESS,
            SalesComparisonActionTypes.FETCH_SCT_SUCCESS,
        ],
        handleApiSuccess,
    );
}

function* UISagas() {
    yield all([watchApiRequests(), watchApiSuccess()]);
}

export { UISagas };
