import { css } from "styled-components";

import { Theme } from "config/theme";

export const Heading1 = css`
    font-family: ${Theme.fontFamily};
    font-weight: 500;
    font-size: 60px;
    line-height: 60px;
    color: ${Theme.grey700};
`;

export const Heading2 = css`
    font-family: ${Theme.fontFamily};
    font-weight: 500;
    font-size: 26px;
    line-height: 28px;
    color: ${Theme.grey700};
`;

export const Heading3 = css`
    font-family: ${Theme.fontFamily};
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: ${Theme.grey700};
`;

export const Heading4 = css`
    font-family: ${Theme.fontFamily};
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    color: ${Theme.grey600};
`;

export const Body = css`
    font-family: ${Theme.fontFamily};
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: ${Theme.grey600};
`;
