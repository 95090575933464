import React, { Component } from "react";
import styled from "styled-components";
import { FastField, FastFieldProps, Formik } from "formik";

import { Listing } from "store/listing";
import { rentalModerationValidationSchema } from "./rental-moderation-validation-schema";
import { FormErrors } from "components/form/form-errors";
import { Label } from "components/form/label";
import { Input } from "components/form/input";
import { Button } from "components/button";
import { withTimeTracking, TimeTrackingProps } from "hoc/with-time-tracking";
import { checkIfStatusNeedsUpdating, statusIsMoreAdvanced } from "utils/status-check";
import { RentalComparison } from "store/rental-comparison/types";

export interface Props {
    className?: string;
    listingID: string;
    updateListing: (listing: Listing) => void;
    status: Listing["status"];
    handleSaveComps?: () => void;
    rentalComps: RentalComparison[];
}

export class RentalModerationForm extends Component<Props & TimeTrackingProps> {
    handleSubmit = (values: {
        moderatedMinMonthlyRent: number;
        moderatedMaxMonthlyRent: number;
        rentalModerationNotes: string;
        rankings: (number | null | undefined)[];
    }) => {
        const { listingID, getElapsedTaskTime, updateListing, status, handleSaveComps } = this.props;
        const { moderatedMinMonthlyRent, moderatedMaxMonthlyRent, rentalModerationNotes } = values;
        const elapsedTaskTime = getElapsedTaskTime();
        const rentalModerationData: Listing = {
            ID: listingID,
            status: checkIfStatusNeedsUpdating(status, "salesValuation"),
            moderatedMinMonthlyRent,
            moderatedMaxMonthlyRent,
            rentalModerationNotes,
        };

        statusIsMoreAdvanced(status, "salesValuation")
            ? (rentalModerationData.qaRentalModerationCompletedIn = elapsedTaskTime)
            : (rentalModerationData.rentalModerationCompletedIn = elapsedTaskTime);

        updateListing(rentalModerationData);
        handleSaveComps && handleSaveComps();
    };

    handleFieldChange = (
        setFieldValue: (field: string, value: (number | null | undefined)[], shouldValidate?: boolean) => void
    ) => {
        setFieldValue(
            "rankings",
            this.props.rentalComps.map((comp) => comp.ranking)
        );
    };
    initialRankings = this.props.rentalComps.map((comp) => comp.ranking);
    render() {
        const { className } = this.props;
        return (
            <Formik
                initialValues={{
                    moderatedMinMonthlyRent: 0,
                    moderatedMaxMonthlyRent: 0,
                    rentalModerationNotes: "",
                    rankings: this.initialRankings,
                }}
                validateOnChange={false}
                validateOnBlur={false}
                validationSchema={rentalModerationValidationSchema}
                onSubmit={this.handleSubmit}
            >
                {(props) => (
                    <FormWrapper className={className} onSubmit={props.handleSubmit}>
                        {Object.keys(props.errors).length > 0 && (
                            <FormErrors>
                                <ul>
                                    {Object.keys(props.errors).map((error, index) => (
                                        <ErrorList key={index}>{props.errors[error]}</ErrorList>
                                    ))}
                                </ul>
                            </FormErrors>
                        )}
                        <FormUpper>
                            <div>
                                <div>
                                    <StyledLabel>Min</StyledLabel>
                                    <FastField
                                        name="moderatedMinMonthlyRent"
                                        data-testid="moderatedMinMonthlyRent"
                                        render={({ field }: FastFieldProps) => (
                                            <StyledInput data-testid="moderatedMinMonthlyRent" {...field} />
                                        )}
                                    />
                                </div>
                                <div>
                                    <StyledLabel>Max</StyledLabel>
                                    <FastField
                                        name="moderatedMaxMonthlyRent"
                                        render={({ field }: FastFieldProps) => (
                                            <StyledInput data-testid="moderatedMaxMonthlyRent" {...field} />
                                        )}
                                    />
                                </div>
                            </div>
                            <div>
                                <FastField
                                    name="rentalModerationNotes"
                                    render={({ field }: FastFieldProps) => (
                                        <TextArea
                                            as="textarea"
                                            placeholder="Comments"
                                            data-testid="comments"
                                            {...field}
                                        />
                                    )}
                                />
                            </div>
                        </FormUpper>
                        <FormLower>
                            <SubmitButton type="submit" onClick={() => this.handleFieldChange(props.setFieldValue)}>
                                Save details
                            </SubmitButton>
                        </FormLower>
                    </FormWrapper>
                )}
            </Formik>
        );
    }
}

const FormWrapper = styled.form`
    width: 620px;
    padding: 8px;
    background: ${({ theme }) => theme.grey700};
`;

const FormUpper = styled.div`
    display: flex;
`;

const FormLower = styled.div`
    display: flex;
    justify-content: flex-end;
`;

const StyledLabel = styled(Label)`
    position: relative;
    display: inline-block;
    color: #ffffff;
    min-width: 35px;

    ::after {
        content: "£";
        position: absolute;
        right: -24px;
        top: 0;
        color: ${({ theme }) => theme.grey600};
        font-weight: 700;
        z-index: 1;
    }
`;

const StyledInput = styled(Input)`
    width: 105px;
    padding-left: 22px;
    margin-bottom: 4px;
`;

const SubmitButton = styled(Button)`
    background: ${({ theme }) => theme.red500};
    margin-top: 8px;
`;

const TextArea = styled(Input)`
    height: 88px;
    width: 450px;
    padding: 10px;
    margin-left: 8px;

    ::placeholder {
        color: ${({ theme }) => theme.grey500};
    }
`;

const ErrorList = styled.li`
    color: ${({ theme }) => theme.neutral0};
`;

export default withTimeTracking(RentalModerationForm);
