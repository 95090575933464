import React from "react";
import styled from "styled-components";

import { Heading3, Heading4 } from "typography";
import { Theme } from "config/theme";
import { routePaths } from "routes";
import { ImageCarousel } from "components/image-carousel";
import BathLogo from "components/media/svg/bath.svg";
import BedsLogo from "components/media/svg/beds.svg";
import { camelCaseToCapitalisedWords } from "utils/data-helpers";

export interface Props {
    className?: string;
    listingID: string;
    sourceID: string;
    imageUrls: string[] | null;
    address: string;
    postcode: string | null;
    propertyType: string;
    singleBedroom?: number;
    doubleBedroom?: number;
    bathrooms: number;
    askingPrice: number;
    predictedRent: number;
    buyOutMonth: string;
    ownershipPercentage: number;
    irrPercentage: number;
    yieldPercentage: number;
    status: string;
}

export const PropertyCard = ({
    className,
    listingID,
    sourceID,
    imageUrls,
    address,
    postcode,
    propertyType,
    singleBedroom,
    doubleBedroom,
    bathrooms,
    askingPrice,
    predictedRent,
    buyOutMonth,
    ownershipPercentage,
    irrPercentage,
    yieldPercentage,
    status
}: Props) => (
    <Wrapper>
        {imageUrls && <StyledImageCarousel images={imageUrls} />}
        <InfoWrapper>
            <Heading>
                <Link href={routePaths.greenCo.replace(":sourceID", sourceID)} target="_blank">
                    {address}, {postcode}
                </Link>
            </Heading>
            <PropertyType>{propertyType}</PropertyType>
            <PropertyRooms>
                {singleBedroom && (
                    <IconContainer>
                        <RoomContainer>{singleBedroom}</RoomContainer>
                        <img src={BedsLogo} alt="Bed Logo" />
                    </IconContainer>
                )}
                {doubleBedroom && (
                    <IconContainer>
                        <RoomContainer>{doubleBedroom}</RoomContainer>
                        <img src={BedsLogo} alt="Bed Logo" />
                    </IconContainer>
                )}
                <IconContainer>
                    <RoomContainer>{bathrooms}</RoomContainer>
                    <img src={BathLogo} alt="Bath Logo" />
                </IconContainer>
            </PropertyRooms>
            <PropertyInfo>
                <tbody>
                    <tr>
                        <TableProperty>Asking:</TableProperty>
                        <td>£{askingPrice}</td>
                    </tr>
                    <tr>
                        <TableProperty>Buy out:</TableProperty>
                        <td>{buyOutMonth}</td>
                        <TableProperty>Ownership:</TableProperty>
                        <td>{ownershipPercentage}%</td>
                    </tr>
                    <tr>
                        <TableProperty>IRR:</TableProperty>
                        <td>{irrPercentage}%</td>
                        <TableProperty>Yield:</TableProperty>
                        <td>{yieldPercentage}%</td>
                    </tr>
                    <tr>
                        <TableProperty>Status:</TableProperty>
                        <td>
                            <Link
                                href={
                                    routePaths[status] ? routePaths[status].replace(":listingID", listingID) : undefined
                                }
                                target="_blank"
                            >
                                {camelCaseToCapitalisedWords(status)}
                            </Link>
                        </td>
                    </tr>
                </tbody>
            </PropertyInfo>
        </InfoWrapper>
    </Wrapper>
);

const Wrapper = styled.div`
    width: 1136px;
    border: 1px solid ${(props) => props.theme.grey400};
    display: flex;
    border-radius: 10px;
    overflow: hidden;
    margin: 10px 0 20px;
`;

const StyledImageCarousel = styled(ImageCarousel)`
    width: 526px;
    background: ${(props) => props.theme.grey300};
`;

const InfoWrapper = styled.div`
    padding-top: 18px;
    width: 50%;
`;

const Heading = styled.p`
    ${Heading3}
    padding: 18px 5px 0 36px;
`;

const PropertyType = styled(Heading)`
    display: block;
    font-size: 16px;
    line-height: 20px;
`;

const PropertyRooms = styled.div`
    ${Heading4}
    align-items: baseline;
    line-height: 24px;
    display: flex;
    padding: 20px 0 0 36px;
    color: ${Theme.grey700};
`;

const PropertyInfo = styled.table`
    ${Heading3}
    font-size: 17px;
    margin: 30px 0 0 36px;
    width: 80%;

    tbody {
        margin: 0 0 46px auto;
    }

    tr {
        width: 100%;
    }

    td {
        height: 54px;
    }
`;

const TableProperty = styled.td`
    color: ${Theme.grey500};
`;

const IconContainer = styled.div`
    display: flex;
    align-items: center;
    margin-right: 14px;
`;

const RoomContainer = styled.p`
    margin-right: 8px;
`;

const Link = styled.a`
    color: ${(props) => props.theme.success};
`;
