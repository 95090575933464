import React from "react";
import styled from "styled-components";

import { ImageCarousel } from "components/comparable/image-carousel";

interface Props {
    children: React.ReactNode;
    imageUrls: string[];
    floorPlans: string[];
    listingURL: string;
}

export const EditCard = ({ children, imageUrls, floorPlans, listingURL }: Props) => {
    return (
        <Wrapper>
            <div>
                {imageUrls?.length ? (
                    <ImageCarousel images={imageUrls} className="edit-card-image-gallery" />
                ) : (
                    <NoAvailabilityText>NO IMAGE AVAILABLE</NoAvailabilityText>
                )}
                <FloorPlans>
                    {floorPlans?.length ? (
                        <ImageCarousel images={floorPlans} className="edit-card-floorplans-image-gallery" />
                    ) : (
                        <NoAvailabilityText>NO FLOOR PLAN AVAILABLE</NoAvailabilityText>
                    )}
                </FloorPlans>
            </div>
            <Content>{children}</Content>
            <ExternalLinksContainer>
                <ExternalLink href={listingURL} target="_blank" rel="noopener noreferrer">
                    Open Listing &#187;
                </ExternalLink>
            </ExternalLinksContainer>
        </Wrapper>
    );
};

const Wrapper = styled.div`
    position: relative;
    display: flex;
    border-radius: 4px;
    background: ${({ theme }) => theme.grey200};
`;

const FloorPlans = styled.div`
    margin: 20px 0;
`;

const Content = styled.div`
    width: 548px;
    padding: 16px;
`;

const ExternalLinksContainer = styled.div`
    position: absolute;
    display: flex;
    justify-content: space-around;
    right: 16px;
    top: 16px;
`;

const ExternalLink = styled.a`
    color: ${({ theme }) => theme.coral600};
    font-weight: 600;
    margin-right: 10px;
    text-decoration: none;
`;

const NoAvailabilityText = styled.p`
    display: block;
    width: 296px;
    text-align: center;
    color: ${({ theme }) => theme.failure};
    margin-top: 32px;
`;
