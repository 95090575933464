import { Reducer } from "redux";
import unique from "array-unique";
import { createSelector } from "reselect";

import { RentalComparison, RentalComparisonActionTypes, RentalComparisonState } from "./types";
import { dataToObjectByKey, getAllIDsFromData } from "utils/data-helpers";
import { globaliseSelectors } from "utils/globalise-selectors";

const initialState: RentalComparisonState = {
    byID: {},
    allIDs: [],
    totalCompsValTaskTime: 0,
    writeComplete: false,
};

const reducer: Reducer<RentalComparisonState> = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case RentalComparisonActionTypes.CREATE_REQUEST:
            return {
                ...state,
                writeComplete: false,
            };

        case RentalComparisonActionTypes.CREATE_SUCCESS:
            return {
                ...state,
                byID: {
                    ...state.byID,
                    [payload.ID]: payload,
                },
                allIDs: unique([...state.allIDs, payload.ID]),
                writeComplete: true,
            };

        case RentalComparisonActionTypes.SAVE_REQUEST:
            return {
                ...state,
                writeComplete: false,
            };

        case RentalComparisonActionTypes.SAVE_SUCCESS:
            return {
                ...state,
                writeComplete: true,
            };

        case RentalComparisonActionTypes.FETCH_SUCCESS:
            return {
                ...state,
                byID: {
                    ...dataToObjectByKey(payload, "ID"),
                },
                allIDs: [...getAllIDsFromData(payload, "ID")],
            };

        case RentalComparisonActionTypes.UPDATE:
            return state.byID[payload.ID]
                ? {
                      ...state,
                      byID: {
                          ...state.byID,
                          [payload.ID]: {
                              ...state.byID[payload.ID],
                              ...payload,
                          },
                      },
                  }
                : state;

        case RentalComparisonActionTypes.UPDATE_BY_SOURCE_ID:
            return {
                ...state,
                byID: {
                    ...dataToObjectByKey(
                        Object.values(state.byID).map((element) =>
                            element.sourceID === payload.sourceID
                                ? { ...element, isDismissed: payload.isDismissed }
                                : element
                        ),
                        "ID"
                    ),
                },
            };

        case RentalComparisonActionTypes.COMPLETED:
            return {
                ...state,
                totalCompsValTaskTime: payload.totalTaskTime,
            };

        default:
            return state;
    }
};

export const getAllRentalComparisons = (state: RentalComparisonState) => state.byID;

export const getAllRentalComparisonsAsArray = (state: RentalComparisonState): RentalComparison[] =>
    Object.keys(state.byID).map((key) => state.byID[key]);

export const getAllDismissed = (state: RentalComparisonState): RentalComparison[] =>
    Object.values(state.byID).filter((rc) => rc.isDismissed);

export const getAllNonDismissed = (state: RentalComparisonState): RentalComparison[] =>
    Object.values(state.byID).filter((rc) => !rc.isDismissed);

export const getAllCompleted = (state: RentalComparisonState): RentalComparison[] =>
    Object.values(state.byID).filter((rc) => rc.isComplete && !rc.isDismissed);

export const getRentalComparisonsByListingID = createSelector(
    (state: RentalComparisonState, valuationID: string) => valuationID,
    getAllRentalComparisons,
    (listingID, rentalComps) => {
        return Object.keys(rentalComps)
            .filter((key) => rentalComps[key].listingID === listingID)
            .map((key) => rentalComps[key]);
    }
);

export const getFirstRentalComparisonsByListingID = (
    state: RentalComparisonState,
    listingID: string,
    operatorID: string
) => {
    return Object.keys(state.byID)
        .filter((key) => state.byID[key].listingID === listingID)
        .filter((key) => state.byID[key].operatorID === operatorID)
        .map((key) => state.byID[key]);
};

export const getSecondRentalComparisonsByListingID = (
    state: RentalComparisonState,
    listingID: string,
    operatorID: string
) => {
    return Object.keys(state.byID)
        .filter((key) => state.byID[key].listingID === listingID)
        .filter((key) => state.byID[key].operatorID !== operatorID)
        .map((key) => state.byID[key]);
};

export const getRentalComparisonsByValuation = (
    state: RentalComparisonState,
    valuationOrderNumber: string,
    listingID: string,
    operatorID: string
) => {
    if (valuationOrderNumber === "first-incomplete") {
        return reducerMethods.getRentalComparisonsByListingID(state, listingID);
    } else if (valuationOrderNumber === "first") {
        return reducerMethods.getFirstRentalComparisonsByListingID(state, listingID, operatorID);
    } else if (valuationOrderNumber === "second") {
        return reducerMethods.getSecondRentalComparisonsByListingID(state, listingID, operatorID);
    } else {
        return [];
    }
};

export const getTotalCompValTaskTime = (state: RentalComparisonState) => state.totalCompsValTaskTime;

export const getWriteCompleteStatus = (state: RentalComparisonState) => state.writeComplete;

const reducerMethods = {
    getAllRentalComparisons,
    getAllRentalComparisonsAsArray,
    getAllDismissed,
    getAllNonDismissed,
    getAllCompleted,
    getRentalComparisonsByListingID,
    getFirstRentalComparisonsByListingID,
    getSecondRentalComparisonsByListingID,
    getRentalComparisonsByValuation,
    getTotalCompValTaskTime,
    getWriteCompleteStatus,
};

export default reducerMethods;

export const rentalComparisonSelectors = globaliseSelectors(reducerMethods, "rentalComparisons");

export { reducer as rentalComparisonReducer };
