import React from "react";
import styled from "styled-components";
import { NavLink } from "react-router-dom";

import { Body } from "typography";
import { StatusOrderType } from "config/status-order";

export interface Props {
    className?: string;
    listingID: string;
    listingStatus: string;
    statuses: StatusOrderType;
}

export const isStageActive = (isURLMatch: boolean, currentStatus: string, stageStatus: string) =>
    isURLMatch && currentStatus === stageStatus;

export const StatusStages = ({ className, listingID, listingStatus, statuses }: Props) => (
    <Wrapper className={className}>
        {Object.keys(statuses).map((orderNum) => {
            const pathname = `/for-review/${listingID}/${statuses[orderNum].slug}`;
            return (
                <StyledNavLink
                    key={orderNum}
                    to={pathname}
                    isActive={(match, location) =>
                        isStageActive(location.pathname === pathname, listingStatus, statuses[orderNum].status)
                    }
                >
                    <Numbering>{orderNum}</Numbering>
                    {statuses[orderNum].displayName}
                </StyledNavLink>
            );
        })}
    </Wrapper>
);

const Wrapper = styled.div`
    display: flex;
    align-items: center;
`;

const activeClassName = "active";
const StyledNavLink = styled(NavLink).attrs({ activeClassName })`
    ${Body};
    text-decoration: none;
    margin-right: 25px;
    color: ${(props) => props.theme.grey400};
    pointer-events: none;

    &.${activeClassName} {
        color: ${(props) => props.theme.grey700};
    }
`;

const Numbering = styled.span`
    ${Body};
    color: inherit;
    display: block;
    text-align: center;
`;
