import { ReactElement, useState } from "react";

interface Props {
    readComponent: (toggleEditMode: () => void) => ReactElement;
    editComponent: (toggleEditMode: () => void) => ReactElement;
}

export const Editable = ({ readComponent, editComponent }: Props) => {
    const [isEditing, setIsEditing] = useState(false);

    const toggleEditMode = () => setIsEditing((isEditing) => !isEditing);

    return isEditing ? editComponent(toggleEditMode) : readComponent(toggleEditMode);
};
