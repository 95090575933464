import React from "react";
import styled from "styled-components";

export interface Props {
    className?: string;
}

export const OperatorMessage = ({ className }: Props) => (
    <Wrapper data-testid="operator-message">
        <MessageText>You have already done rental valuation for this property!</MessageText>
    </Wrapper>
);

const Wrapper = styled.div`
    height: auto;
    max-width: 800px;
    border: 2px solid ${(props) => props.theme.failure};
    border-radius: 10px;
    padding: 20px 10px;
`;

const MessageText = styled.span`
    display: flex;
    justify-content: center;
    color: ${(props) => props.theme.failure};
    font-size: 24px;
`;
