import { get } from "lodash";

import { AppState } from "store";

type SelectorsObject<T> = { [key in keyof T]: any };

type StateKeys = keyof AppState;

export const globaliseSelectors = <T>(selectors: T, stateSlice: StateKeys): SelectorsObject<T> => {
    return Object.keys(selectors).reduce((accumulator, key) => {
        accumulator[key] = (state: AppState, ...args: any) => selectors[key](get(state, stateSlice), ...args);
        return accumulator;
    }, {}) as SelectorsObject<T>;
};
