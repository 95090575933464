import { number, object } from "yup";

const minMonthlyRent = 300;
const maxMonthlyRent = 3000;

export const rentalRangeOneValidationSchema = object({
    rentalValuationMinMonthlyRent: number()
        .min(minMonthlyRent, "The min rental has to be at least £300")
        .max(maxMonthlyRent - 1, "The min rental cannot be greater than £2999")
        .required("The min rental is required"),
    rentalValuationMaxMonthlyRent: number()
        .min(minMonthlyRent + 1, "The max rental cannot be less than £301")
        .max(maxMonthlyRent, "The max rental cannot be higher than £3000")
        .required("The max rental is required")
});
