import { number, object, string, array } from "yup";

const minMonthlyRent = 300;
const maxMonthlyRent = 3000;

export const rentalModerationValidationSchema = object({
    moderatedMinMonthlyRent: number()
        .min(minMonthlyRent, "The min rental has to be at least £300")
        .max(maxMonthlyRent - 1, "The min rental cannot be greater than £2999")
        .required("The min rental is required"),
    moderatedMaxMonthlyRent: number()
        .min(minMonthlyRent + 1, "The max rental cannot be less than £301")
        .max(maxMonthlyRent, "The max rental cannot be higher than £3000")
        .required("The max rental is required"),
    rentalModerationNotes: string()
        .min(50, "Your comment needs to be a minimum of 50 characters")
        .required("Please fill in the comments field"),

    rankings: array()
        .test(
            "hasRankings",
            "You must enter a rank for all non-dismissed comps",
            (value) => Array.isArray(value) && value.every(Boolean)
        )
        .test(
            "hasAtLeast3Comps",
            "Please select 3 comps to bring forward",
            (value) => Array.isArray(value) && value.length >= 3
        )
        .test(
            "hasNoMoreThan3Comps",
            "You can only select 3 comps to bring forward. Dismiss any unwanted comps",
            (value) => Array.isArray(value) && value.length <= 3
        ),
});
