import React from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import useDeepCompareEffect from "use-deep-compare-effect";

import {
    completeRentalValuation,
    fetchSCT,
    RentalComparison,
    rentalComparisonSelectors,
    SCTFetchParams,
    updateRentalComparison,
} from "store/rental-comparison";
import { fetchListingRequest, Listing, listingSelectors, PropertyType } from "store/listing";
import { AppState } from "store";
import { AnalysisLayout } from "components/analysis-layout";
import { EditCard, EvaluateCard, RentalInfo } from "components/comparable";
import { Editable } from "components/editable";
import { SecondaryButton } from "components/button";
import RentalComparisonForm from "./rental-comparison-form";
import { Heading2, Heading3 } from "typography";

const NUM_OF_REQUIRED_COMPS = 3;

export const getTotalRentalComparisonTaskTime = (rentalComparisons: RentalComparison[]): number =>
    rentalComparisons.reduce(
        (totalTime, rentalComp) =>
            rentalComp.rentalComparisonCompletedIn ? totalTime + rentalComp.rentalComparisonCompletedIn : totalTime,
        0
    );

export const RentalValuation: React.FC = () => {
    const dispatch = useDispatch();
    const { listingID } = useParams<{ listingID: string }>();
    const listing: Listing = useSelector((state: AppState) => listingSelectors.getListingByID(state, listingID));
    const comparables: RentalComparison[] = useSelector(rentalComparisonSelectors.getAllNonDismissed);
    const dismissed: RentalComparison[] = useSelector(rentalComparisonSelectors.getAllDismissed);
    const completed: RentalComparison[] = useSelector(rentalComparisonSelectors.getAllCompleted);

    useDeepCompareEffect(() => {
        !Object.keys(listing).length && listingID && dispatch(fetchListingRequest(listingID));
    }, [listingID, listing, dispatch]);

    useDeepCompareEffect(() => {
        Object.keys(listing).length &&
            dispatch(
                fetchSCT({
                    postcode: listing.postcode || "",
                    sourceID: listing.sourceID || "",
                    listingID: listing.ID,
                    numBeds:
                        listing.bedrooms || (listing.totalDoubleBedrooms || 0) + (listing.totalSingleBedrooms || 0),
                    rentalOrSales: "rental",
                    propertyType: (listing.propertyType?.toLowerCase() as PropertyType) || "house",
                    propertyStyle: listing.propertyStyle?.toLowerCase() || "unknown",
                } as SCTFetchParams)
            );
    }, [listing, dispatch]);

    return listing ? (
        <Wrapper data-testid="rental-valuation">
            <AnalysisLayout listing={listing}>
                <>
                    <ListHeading>
                        Comparables ({comparables.length})
                        {completed.length < NUM_OF_REQUIRED_COMPS ? (
                            <HintText>Evaluate {NUM_OF_REQUIRED_COMPS} comparables to proceed</HintText>
                        ) : (
                            <CompleteButton
                                type="button"
                                onClick={() =>
                                    dispatch(
                                        completeRentalValuation(listing.ID, getTotalRentalComparisonTaskTime(completed))
                                    )
                                }
                            >
                                Complete valuation
                            </CompleteButton>
                        )}
                    </ListHeading>
                    {comparables
                        .filter((comparable) => !comparable.isDismissed)
                        .map((comparable) => (
                            <Editable
                                key={comparable.ID}
                                readComponent={(toggleEdit) => (
                                    <EvaluateCard
                                        imageUrls={comparable.images?.length ? comparable.images : []}
                                        floorPlans={comparable.floorPlans?.length ? comparable.floorPlans : []}
                                        listingURL={comparable.sourceURL || ""}
                                        isComplete={comparable.isComplete}
                                        onEvaluate={toggleEdit}
                                        onDismiss={() =>
                                            dispatch(updateRentalComparison({ ID: comparable.ID, isDismissed: true }))
                                        }
                                    >
                                        <RentalInfo rentalComparison={comparable} listing={listing} />
                                    </EvaluateCard>
                                )}
                                editComponent={(toggleEdit) => (
                                    <EditCard
                                        imageUrls={comparable.images?.length ? comparable.images : []}
                                        floorPlans={comparable.floorPlans?.length ? comparable.floorPlans : []}
                                        listingURL={comparable.sourceURL || ""}
                                    >
                                        <RentalComparisonForm
                                            listing={listing}
                                            listingID={listing.ID}
                                            rentalComparison={comparable}
                                            saveRentalComparison={(rc) => {
                                                dispatch(updateRentalComparison(rc));
                                                toggleEdit();
                                            }}
                                            onDismiss={() => {
                                                dispatch(
                                                    updateRentalComparison({ ID: comparable.ID, isDismissed: true })
                                                );
                                            }}
                                            onCancel={toggleEdit}
                                        />
                                    </EditCard>
                                )}
                            />
                        ))}

                    <ListHeading>Dismissed ({dismissed.length})</ListHeading>
                    <DismissedFade>
                        {dismissed
                            .filter((comparable) => comparable.isDismissed)
                            .map((comparable) => (
                                <EvaluateCard
                                    key={comparable.ID}
                                    imageUrls={comparable.images?.length ? comparable.images : []}
                                    floorPlans={comparable.floorPlans?.length ? comparable.floorPlans : []}
                                    listingURL={comparable.sourceURL || ""}
                                    isComplete={comparable.isComplete}
                                    onReinstate={() =>
                                        dispatch(updateRentalComparison({ ID: comparable.ID, isDismissed: false }))
                                    }
                                >
                                    <RentalInfo rentalComparison={comparable} listing={listing} />
                                </EvaluateCard>
                            ))}
                    </DismissedFade>
                </>
            </AnalysisLayout>
        </Wrapper>
    ) : null;
};

const Wrapper = styled.div``;

const ListHeading = styled.h2`
    ${Heading2};
    display: block;
    margin: 48px 0 16px;
`;

const HintText = styled.span`
    ${Heading3};
    float: right;
`;

const CompleteButton = styled(SecondaryButton)`
    float: right;
`;

const DismissedFade = styled.div`
    opacity: 0.5;
`;

export default RentalValuation;
