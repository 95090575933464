import { number, object } from "yup";

export const salesRangeTwoValidationSchema = object({
    salesValuationTwoMinPrice: number()
        .min(75000, "Minimum price must be at least £75,000, but less than £750,000 or less than maximum price")
        .max(749999, "Minimum price must be at least £75,000, but less than £750,000 or less than maximum price")
        .required("The min sales price is required"),
    salesValuationTwoMaxPrice: number()
        .min(
            75001,
            "Maximum price must be greater than £75,000 or greater than minimum price, but not greater than £750,000"
        )
        .max(
            750000,
            "Maximum price must be greater than £75,000 or greater than minimum price, but not greater than £750,000"
        )
        .required("The max sales price is required")
});
