import { combineReducers } from "redux";
import { all, fork } from "redux-saga/effects";

import { listingReducer, listingSagas, ListingState } from "./listing";
import { rentalComparisonReducer, rentalComparisonSagas, RentalComparisonState } from "./rental-comparison";
import { UIReducer, UIState, UISagas } from "./ui";
import { customerListingReducer, customerListingSagas, CustomerListingState } from "./customer-listing";
import { connectRouter, RouterState } from "connected-react-router";
import { History } from "history";
import { SalesComparisonState, salesComparisonSagas, salesComparisonReducer } from "./sales-comparison";

export interface AppState {
    ui: UIState;
    listings: ListingState;
    customerListings: CustomerListingState;
    rentalComparisons: RentalComparisonState;
    salesComparisons: SalesComparisonState;
}

export const rootReducer = (history: History) =>
    combineReducers<{ router: RouterState } & AppState>({
        router: connectRouter(history),
        ui: UIReducer,
        listings: listingReducer,
        customerListings: customerListingReducer,
        rentalComparisons: rentalComparisonReducer,
        salesComparisons: salesComparisonReducer,
    });

export const getInitialState = (): AppState => ({
    ui: {
        isLoading: false,
        showApiError: false,
        apiErrorMessage: "",
    },
    listings: { byID: {}, allIDs: [] },
    customerListings: { byID: {}, allIDs: [] },
    rentalComparisons: { byID: {}, allIDs: [], totalCompsValTaskTime: 0 },
    salesComparisons: { byID: {}, allIDs: [], totalCompsValTaskTime: 0 },
});

export function* rootSaga() {
    yield all([
        fork(listingSagas),
        fork(customerListingSagas),
        fork(rentalComparisonSagas),
        fork(salesComparisonSagas),
        fork(UISagas),
    ]);
}
