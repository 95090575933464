import React from "react";
import styled from "styled-components";

import { InputWrapper, Label, HorizontalMultiChoiceButton, Input } from "components/form";
import { prefilteringFormData } from "../prefiltering-form/prefiltering-form-data";

export interface Props {
    onReject: (ID: string, currentValue: string) => void;
    setFieldValue: (field: string, value: string | number) => void;
    values: Values;
    submitForm: () => void;
}

interface State {
    currentIndex: number;
    currentValue: string;
}

interface Values {
    ID: string;
    isExteriorGood: string;
    isKitchenGood: string;
    isBathroomGood: string;
    isOverallGood: string;
    isFloorPlanGood: string;
    isLocationGood: string;
}

export class ReviewSection extends React.Component<Props, State> {
    objectKeys = [...Object.keys(prefilteringFormData), "predictedMonthlyRent"];

    state = {
        currentIndex: 0,
        currentValue: this.objectKeys[0]
    };

    componentDidMount() {
        window.addEventListener("keyup", this.handleKeyEvent);
    }

    componentWillUnmount() {
        window.removeEventListener("keyup", this.handleKeyEvent);
    }

    handleKeyEvent = (event: KeyboardEvent) => {
        this.handleKeyUp(event, this.state.currentValue, this.props.setFieldValue);
    };

    incrementQuestionIndex = () => {
        if (this.state.currentIndex < this.objectKeys.length - 1) {
            this.setState((prevState) => ({
                currentIndex: prevState.currentIndex + 1,
                currentValue: this.objectKeys[prevState.currentIndex + 1]
            }));
        }
    };

    arePropertyFieldsComplete = () => {
        const { values } = this.props;
        return (
            values.isExteriorGood !== "" &&
            values.isKitchenGood !== "" &&
            values.isBathroomGood !== "" &&
            values.isOverallGood !== "" &&
            values.isFloorPlanGood !== ""
        );
    };

    handleChange = (field: string, value: any, formikSetFieldFn: (field: string, value: any) => void) => {
        if (value === "true" || value === "unknown") {
            formikSetFieldFn(field, value);
            this.incrementQuestionIndex();
        } else {
            this.handleReject();
        }
    };

    handleReject = () => {
        const { ID } = this.props.values;
        const { onReject } = this.props;
        onReject(ID, this.state.currentValue);
    };

    getValueByKeyEvent = (value: string) => {
        switch (value) {
            case "1":
                return "true";
            case "2":
                return "false";
            case "3":
                return "unknown";
            default:
                return null;
        }
    };

    handleKeyUp = (event: KeyboardEvent, field: string, formikSetFieldFn: (field: string, value: any) => void) => {
        const { submitForm } = this.props;
        const value = this.getValueByKeyEvent(event.key);

        if (value !== null) {
            this.handleChange(field, value, formikSetFieldFn);
        }

        if (event.key === "Enter") {
            submitForm();
        }
    };

    render() {
        const { currentIndex } = this.state;
        const { setFieldValue, values } = this.props;

        return (
            <>
                {Object.keys(prefilteringFormData).map((key, index) => (
                    <StyledInputWrapper key={key} isHighlighted={currentIndex === index}>
                        <StyledLabel>{prefilteringFormData[key].label}</StyledLabel>
                        <HorizontalMultiChoiceButton
                            choices={prefilteringFormData[key].options}
                            onChange={(e: any) => {
                                this.handleChange(key, e.target.value, setFieldValue);
                            }}
                            selectedValue={values[key]}
                        />
                    </StyledInputWrapper>
                ))}
            </>
        );
    }
}

const StyledInputWrapper = styled(InputWrapper)<{ isHighlighted: boolean }>`
    background-color: ${(props) => (props.isHighlighted ? props.theme.coral300 : "transparent")};
    padding: 12px;
    border-radius: 4px;
`;

const StyledLabel = styled(Label)`
    margin: 10px 0;
    text-transform: none;
    color: ${(props) => props.theme.grey700};
`;

const StyledInput = styled(Input)`
    width: 250px;
`;

StyledInputWrapper.displayName = "StyledInputWrapper";

StyledInput.displayName = "StyledInput";
