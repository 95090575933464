import React from "react";
import styled from "styled-components";

interface Props {
    className?: string;
    title?: string;
}

export const Info: React.FC<Props> = ({ children, className, title }) => (
    <Wrapper className={className}>
        <InfoIcon />
        <Content>
            {title && <Title>{title}</Title>}
            {children}
        </Content>
    </Wrapper>
);

const Wrapper = styled.div`
    display: flex;
    margin: 16px 0;
    border: 1px solid ${({ theme }) => theme.grey400};
    border-radius: ${({ theme }) => theme.radius1}px;
    padding: 16px 16px 16px 8px;
    color: ${({ theme }) => theme.grey500};
    font-size: 14px;
`;

const Content = styled.div`
    margin-left: 8px;
`;

const Title = styled.h4`
    margin-bottom: 8px;
`;

const InfoIcon = styled.div`
    position: relative;
    border: 1px solid ${({ theme }) => theme.success};
    border-radius: 100%;
    width: 20px;
    height: 20px;

    ::before {
        content: "i";
        color: ${({ theme }) => theme.success};
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
`;
