import React from "react";
import { Redirect } from "react-router";

import { routePaths } from "routes";
import { Listing, ListingStatusTypes } from "store/listing";
import { getSlugFromListingStatus, statusSlugMap } from "utils/url-helpers";
import withListing from "hoc/with-listing";

interface Props {
    listing: Listing;
}

export const getStatusForRedirect = (status?: ListingStatusTypes): string => {
    let pathname = null;

    if (status) {
        pathname = getSlugFromListingStatus(status, statusSlugMap);
    }

    return pathname ? pathname : "prefilter";
};

export const StatusRedirect = ({ listing }: Props) => (
    <Redirect to={`${routePaths.listing.replace(":listingID", listing.ID)}/${getStatusForRedirect(listing.status)}`} />
);

export default withListing(StatusRedirect);
