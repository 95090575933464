import React, { ReactElement, useEffect, useState } from "react";
import { fetchWithAuth } from "utils/fetch-with-auth";

import { OperatorMessage } from "components/operator-message";
import { useParams } from "react-router";
import { useAuth } from "react-oidc-context";

type ValuationType = "rental" | "sales";

interface Props {
    valuationType: ValuationType;
    children: ReactElement;
}

export const filterResultsByType = (type: ValuationType, valuationData: Array<any>): Array<any> =>
    valuationData.filter((valuation) => valuation.comparisonType === type);

export const ValuationRedirect: React.FC<Props> = ({ valuationType, children }) => {
    const auth = useAuth();

    const { listingID } = useParams<{ listingID: string }>();
    const currentOperatorID = auth.user?.profile.sid || "";
    const [resultLength, updateResultLength] = useState<string | number>("");

    useEffect(() => {
        (async () => {
            const API_ENDPOINT = `${process.env.REACT_APP_API_ENDPOINT}/v1/rental-comparisons?listingID=${listingID}&operatorID=${currentOperatorID}`;
            const options = {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            };
            const res = (await fetchWithAuth(API_ENDPOINT, options)) || {};

            if (res.ok === true) {
                const resData = await res.json();
                updateResultLength(filterResultsByType(valuationType, resData.results).length);
            }
        })();
    });

    if (resultLength === "") {
        return null;
    } else {
        return typeof resultLength === "number" && resultLength > 0 ? <OperatorMessage /> : children;
    }
};
