import React, { Component } from "react";
import { Formik } from "formik";
import styled from "styled-components";
import { Button } from "components/button";

import { Listing } from "store/listing";
import { FormErrors } from "components/form";
import { ReviewSection } from "../review-section";
import { prefilteringFormValidationSchema } from "./prefiltering-form-validation-schema";
import { Heading2 } from "typography";
import { withTimeTracking, TimeTrackingProps } from "hoc/with-time-tracking";
import { checkIfStatusNeedsUpdating, statusIsMoreAdvanced } from "utils/status-check";

interface Props {
    listingID: string;
    onSubmit: (listing: Listing) => void;
    onReject: (listingID: string, field: string) => void;
    status: Listing["status"];
    isAutoAddressMatched: boolean;
}

interface FormProps {
    ID: string;
    isExteriorGood: string;
    isKitchenGood: string;
    isBathroomGood: string;
    isOverallGood: string;
    isFloorPlanGood: string;
    isLocationGood: string;
}

export class PrefilteringForm extends Component<Props & TimeTrackingProps> {
    convertValue = (value: string): boolean | null => {
        switch (value) {
            case "true":
                return true;
            case "unknown":
                return null;
            default:
                return false;
        }
    };

    handleSubmit = (formValues: FormProps) => {
        const elapsedTaskTime = this.props.getElapsedTaskTime();
        const prefilterData: Listing = {
            ID: formValues.ID,
            status: this.props.isAutoAddressMatched
                ? "basicAnalysis"
                : checkIfStatusNeedsUpdating(this.props.status, "addressMatch"),
            isExteriorGood: this.convertValue(formValues.isExteriorGood),
            isKitchenGood: this.convertValue(formValues.isKitchenGood),
            isBathroomGood: this.convertValue(formValues.isBathroomGood),
            isOverallGood: this.convertValue(formValues.isOverallGood),
            isFloorPlanGood: this.convertValue(formValues.isFloorPlanGood),
            isLocationGood: this.convertValue(formValues.isLocationGood),
        };

        statusIsMoreAdvanced(this.props.status, "addressMatch")
            ? (prefilterData.qaPrefilterCompletedIn = elapsedTaskTime)
            : (prefilterData.prefilterCompletedIn = elapsedTaskTime);

        this.props.onSubmit(prefilterData);
    };

    render() {
        const { listingID, onReject } = this.props;
        return (
            <Formik
                initialValues={{
                    ID: listingID,
                    isExteriorGood: "",
                    isKitchenGood: "",
                    isBathroomGood: "",
                    isOverallGood: "",
                    isFloorPlanGood: "",
                    isLocationGood: "",
                }}
                validateOnChange={false}
                validateOnBlur={false}
                validationSchema={prefilteringFormValidationSchema}
                onSubmit={this.handleSubmit}
            >
                {(props) => (
                    <>
                        <Heading>Review Property</Heading>
                        {Object.keys(props.errors).length > 0 && (
                            <FormErrors>
                                <ul>
                                    {Object.keys(props.errors).map((error, index) => (
                                        <li key={index}>{props.errors[error]}</li>
                                    ))}
                                </ul>
                            </FormErrors>
                        )}
                        <StyledForm onSubmit={props.handleSubmit}>
                            <ReviewSection
                                onReject={onReject}
                                setFieldValue={props.setFieldValue}
                                values={props.values}
                                submitForm={props.submitForm}
                            />
                            <StyledButton type="submit">Complete pre-filtering</StyledButton>
                        </StyledForm>
                    </>
                )}
            </Formik>
        );
    }
}

const StyledButton = styled(Button)`
    margin-top: 50px;
`;

const Heading = styled.h1`
    ${Heading2};
    margin: 50px 0 20px 0;
`;

const StyledForm = styled.form`
    max-width: 420px;
`;

export default withTimeTracking(PrefilteringForm);
