import React, { useEffect } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";

import withListing from "hoc/with-listing";
import { useCompsByOperator } from "hooks/use-comps-by-operator";
import { RentalComparison, rentalComparisonSelectors } from "store/rental-comparison";
import { Listing, saveListingRequest } from "store/listing";
import { AnalysisLayout } from "components/analysis-layout";
import { EvaluateCard } from "components/comparable/evaluate-card";
import { RentalInfo } from "components/comparable/rental-info";
import RentalRangeOneForm from "./rental-range-form/rental-range-one-form";
import RentalRangeTwoForm from "./rental-range-form/rental-range-two-form";
import { routePaths } from "routes";
import { useAuth } from "react-oidc-context";

export interface Props {
    listing: Listing;
    saveListing: typeof saveListingRequest;
}

export const getOperatorIDForRentalVal = (listing: Listing, currentOperatorID: string): string => {
    const { status, rentalValuationCompletedBy, rentalValuationTwoCompletedBy } = listing;

    if (status === "rentalValuation") {
        return rentalValuationCompletedBy ? rentalValuationCompletedBy : currentOperatorID;
    } else {
        return rentalValuationTwoCompletedBy ? rentalValuationTwoCompletedBy : currentOperatorID;
    }
};

export const getTotalRentalComparisonTaskTime = (rentalComparisons: RentalComparison[]): number => {
    return rentalComparisons.reduce((totalTime, rentalComp) => {
        return rentalComp.rentalComparisonCompletedIn ? totalTime + rentalComp.rentalComparisonCompletedIn : totalTime;
    }, 0);
};

export const RentalValuationSummary: React.FC<Props> = ({ listing, saveListing }) => {
    const auth = useAuth();

    const currentOperatorID = auth.user?.profile.sid || "";
    const totalCompsValTaskTime: number = useSelector(rentalComparisonSelectors.getTotalCompValTaskTime);
    const derivedOperatorID = getOperatorIDForRentalVal(listing, currentOperatorID);
    const comps = useCompsByOperator(listing.ID, "rental")[derivedOperatorID];
    const listingStatus = listing.status;

    useEffect(() => {
        const prefix = listingStatus === "rentalValuationTwo" ? "Second" : "";
        document.title = `${prefix} Rental Valuation Summary | Peanut`;
    }, [listingStatus]);

    return listing && comps?.length ? (
        <Wrapper>
            <AnalysisLayout listing={listing}>
                {comps?.map((comp) => (
                    <EvaluateCard
                        key={comp.ID}
                        imageUrls={comp.images?.length ? comp.images : []}
                        floorPlans={comp.floorPlans?.length ? comp.floorPlans : []}
                        listingURL={comp.sourceURL || routePaths.sctTool.replace(":sourceID", comp.sourceID || "")}
                    >
                        <RentalInfo rentalComparison={comp} listing={listing} />
                    </EvaluateCard>
                ))}
                {listingStatus === "rentalValuation" ? (
                    <StyledRentalRangeOneForm
                        listingID={listing.ID}
                        totalRentalComparisonTaskTime={totalCompsValTaskTime}
                        updateListing={saveListing}
                        status={listing.status}
                    />
                ) : (
                    <StyledRentalRangeTwoForm
                        listingID={listing.ID}
                        totalRentalComparisonTaskTime={totalCompsValTaskTime}
                        updateListing={saveListing}
                        status={listing.status}
                    />
                )}
            </AnalysisLayout>
        </Wrapper>
    ) : null;
};

const Wrapper = styled.div`
    width: 45%;
    min-width: 1050px;
`;

const StyledRentalRangeOneForm = styled(RentalRangeOneForm)`
    margin-top: 15px;
`;

const StyledRentalRangeTwoForm = styled(RentalRangeTwoForm)`
    margin-top: 15px;
`;

export default withListing(RentalValuationSummary);
