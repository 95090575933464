import React from "react";
import styled from "styled-components";

import { Body, Heading4 } from "typography";

export interface Props {
    className?: string;
    children: React.ReactNode;
}

export const FormErrors = ({ className, children }: Props) => (
    <Wrapper className={className}>
        <ErrorHeading>The following errors were encountered:</ErrorHeading>
        <ErrorsContainer>{children}</ErrorsContainer>
    </Wrapper>
);

const Wrapper = styled.div`
    margin: 15px 0;
    padding: 15px 15px 10px;
    border: 1px solid ${(props) => props.theme.failure};
    border-radius: 3px;
`;

const ErrorHeading = styled.h4`
    ${Heading4};
    color: ${(props) => props.theme.failure};
`;

const ErrorsContainer = styled.div`
    ${Body};
    padding: 15px 10px;

    ul {
        list-style: disc;
        margin-left: 10px;
    }

    li {
        padding: 2px 0;
    }
`;
