import React from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import useDeepCompareEffect from "use-deep-compare-effect";

import {
    completeSalesValuation,
    fetchSCT,
    SalesComparison,
    salesComparisonSelectors,
    SCTFetchParams,
    updateSalesComparison,
} from "store/sales-comparison";
import { AnalysisLayout } from "components/analysis-layout";
import SalesComparisonForm from "./sales-comparison-form";
import { AppState } from "store";
import { fetchListingRequest, Listing, listingSelectors, PropertyType } from "store/listing";
import { Editable } from "components/editable";
import { EvaluateCard, EditCard, SalesInfo } from "components/comparable";
import { Heading2, Heading3 } from "typography";
import { SecondaryButton } from "components/button";

const NUM_OF_REQUIRED_COMPS = 3;

export const getTotalSalesComparisonTaskTime = (comparisons: SalesComparison[]): number =>
    comparisons.reduce(
        (totalTime, comp) =>
            comp.salesComparisonCompletedIn ? totalTime + comp.salesComparisonCompletedIn : totalTime,
        0
    );

export const SalesValuation: React.FC = () => {
    const dispatch = useDispatch();
    const { listingID } = useParams<{ listingID: string }>();
    const listing: Listing = useSelector((state: AppState) => listingSelectors.getListingByID(state, listingID));
    const comparables: SalesComparison[] = useSelector(salesComparisonSelectors.getAllNonDismissed);
    const dismissed: SalesComparison[] = useSelector(salesComparisonSelectors.getAllDismissed);
    const completed: SalesComparison[] = useSelector(salesComparisonSelectors.getAllCompleted);

    useDeepCompareEffect(() => {
        !Object.keys(listing).length && listingID && dispatch(fetchListingRequest(listingID));
    }, [listingID, listing, dispatch]);

    useDeepCompareEffect(() => {
        Object.keys(listing).length &&
            dispatch(
                fetchSCT({
                    postcode: listing.postcode || "",
                    sourceID: listing.sourceID || "",
                    listingID: listing.ID,
                    numBeds:
                        listing.bedrooms || (listing.totalDoubleBedrooms || 0) + (listing.totalSingleBedrooms || 0),
                    rentalOrSales: "sales",
                    propertyType: (listing.propertyType?.toLowerCase() as PropertyType) || "house",
                    propertyStyle: listing.propertyStyle?.toLowerCase() || "unknown",
                } as SCTFetchParams)
            );
    }, [listing, dispatch]);

    return listing ? (
        <Wrapper data-testid="sales-valuation">
            <AnalysisLayout listing={listing}>
                <ListHeading>
                    Comparables ({comparables.length})
                    {completed.length < NUM_OF_REQUIRED_COMPS ? (
                        <HintText>Evaluate {NUM_OF_REQUIRED_COMPS} comparables to proceed</HintText>
                    ) : (
                        <CompleteButton
                            type="button"
                            onClick={() =>
                                dispatch(completeSalesValuation(listing.ID, getTotalSalesComparisonTaskTime(completed)))
                            }
                        >
                            Complete valuation
                        </CompleteButton>
                    )}
                </ListHeading>
                {comparables
                    .filter((comparable) => !comparable.isDismissed)
                    .map((comparable) => (
                        <Editable
                            key={comparable.ID}
                            readComponent={(toggleEdit) => (
                                <EvaluateCard
                                    imageUrls={comparable.images?.length ? comparable.images : []}
                                    floorPlans={comparable.floorPlans?.length ? comparable.floorPlans : []}
                                    listingURL={comparable.sourceURL || ""}
                                    isComplete={comparable.isComplete}
                                    onEvaluate={toggleEdit}
                                    onDismiss={() =>
                                        dispatch(updateSalesComparison({ ID: comparable.ID, isDismissed: true }))
                                    }
                                >
                                    <SalesInfo salesComparison={comparable} listing={listing} />
                                </EvaluateCard>
                            )}
                            editComponent={(toggleEdit) => (
                                <EditCard
                                    imageUrls={comparable.images?.length ? comparable.images : []}
                                    floorPlans={comparable.floorPlans?.length ? comparable.floorPlans : []}
                                    listingURL={comparable.sourceURL || ""}
                                >
                                    <SalesComparisonForm
                                        listing={listing}
                                        listingID={listing.ID}
                                        salesComparison={comparable}
                                        saveSalesComparison={(sc) => {
                                            dispatch(updateSalesComparison(sc));
                                            toggleEdit();
                                        }}
                                        onDismiss={() => {
                                            dispatch(updateSalesComparison({ ID: comparable.ID, isDismissed: true }));
                                        }}
                                        onCancel={toggleEdit}
                                    />
                                </EditCard>
                            )}
                        />
                    ))}

                <ListHeading>Dismissed ({dismissed.length})</ListHeading>
                <DismissedFade>
                    {dismissed
                        .filter((comparable) => comparable.isDismissed)
                        .map((comparable) => (
                            <EvaluateCard
                                key={comparable.ID}
                                imageUrls={comparable.images?.length ? comparable.images : []}
                                floorPlans={comparable.floorPlans?.length ? comparable.floorPlans : []}
                                listingURL={comparable.sourceURL || ""}
                                isComplete={comparable.isComplete}
                                onReinstate={() =>
                                    dispatch(updateSalesComparison({ ID: comparable.ID, isDismissed: false }))
                                }
                            >
                                <SalesInfo salesComparison={comparable} listing={listing} />
                            </EvaluateCard>
                        ))}
                </DismissedFade>
            </AnalysisLayout>
        </Wrapper>
    ) : null;
};

const Wrapper = styled.div``;

const ListHeading = styled.h2`
    ${Heading2};
    display: block;
    margin: 48px 0 16px;
`;

const HintText = styled.span`
    ${Heading3};
    float: right;
`;

const CompleteButton = styled(SecondaryButton)`
    float: right;
`;

const DismissedFade = styled.div`
    opacity: 0.5;
`;

export default SalesValuation;
