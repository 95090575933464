import React, { Component } from "react";
import styled from "styled-components";
import { FastField, FastFieldProps, Formik } from "formik";

import { Button } from "components/button";
import { Input } from "components/form/input";

import { rentalRangeTwoValidationSchema } from "./rental-range-two-validation-schema";
import { Listing } from "store/listing";
import { FormErrors, Label } from "components/form";
import { withTimeTracking, TimeTrackingProps } from "hoc/with-time-tracking";
import { checkIfStatusNeedsUpdating, statusIsMoreAdvanced } from "utils/status-check";

export interface Props {
    className?: string;
    listingID: string;
    totalRentalComparisonTaskTime: number;
    updateListing: (listing: Listing) => void;
    status: Listing["status"];
}

export class RentalRangeTwoForm extends Component<Props & TimeTrackingProps> {
    handleSubmit = (values: { rentalValuationTwoMinMonthlyRent: number; rentalValuationTwoMaxMonthlyRent: number }) => {
        const { listingID, updateListing, getElapsedTaskTime, totalRentalComparisonTaskTime, status } = this.props;
        const elapsedTaskTime = getElapsedTaskTime() + totalRentalComparisonTaskTime;
        const rentalValuationTwoData: Listing = {
            ID: listingID,
            status: checkIfStatusNeedsUpdating(status, "rentalModeration"),
            rentalValuationTwoMinMonthlyRent: values.rentalValuationTwoMinMonthlyRent,
            rentalValuationTwoMaxMonthlyRent: values.rentalValuationTwoMaxMonthlyRent
        };

        statusIsMoreAdvanced(status, "rentalModeration")
            ? (rentalValuationTwoData.qaRentalValuationTwoCompletedIn = elapsedTaskTime)
            : (rentalValuationTwoData.rentalValuationTwoCompletedIn = elapsedTaskTime);

        updateListing(rentalValuationTwoData);
    };

    render() {
        const { className } = this.props;

        return (
            <Formik
                initialValues={{
                    rentalValuationTwoMinMonthlyRent: 0,
                    rentalValuationTwoMaxMonthlyRent: 0
                }}
                validateOnChange={false}
                validateOnBlur={false}
                validationSchema={rentalRangeTwoValidationSchema}
                onSubmit={this.handleSubmit}
                render={(props) => (
                    <>
                        {Object.keys(props.errors).length > 0 && (
                            <FormErrors>
                                <ul>
                                    {Object.keys(props.errors).map((error, index) => (
                                        <li key={index}>{props.errors[error]}</li>
                                    ))}
                                </ul>
                            </FormErrors>
                        )}
                        <Wrapper className={className} onSubmit={props.handleSubmit}>
                            <HeadingText>Set the rental range</HeadingText>
                            <FlexContainer>
                                <InputWrapper>
                                    <Label>Minimum</Label>
                                    <FastField
                                        name="rentalValuationTwoMinMonthlyRent"
                                        render={({ field }: FastFieldProps) => <Input placeholder="£" {...field} />}
                                    />
                                </InputWrapper>
                                <InputWrapper>
                                    <Label>Maximum</Label>
                                    <FastField
                                        name="rentalValuationTwoMaxMonthlyRent"
                                        render={({ field }: FastFieldProps) => <Input placeholder="£" {...field} />}
                                    />
                                </InputWrapper>
                                <SubmitButton type="submit">Save details</SubmitButton>
                            </FlexContainer>
                        </Wrapper>
                    </>
                )}
            />
        );
    }
}

const Wrapper = styled.form`
    padding: 20px;
    border: 1px solid ${(props) => props.theme.grey400};
`;

const HeadingText = styled(Label)`
    color: ${(props) => props.theme.grey700};
`;

const FlexContainer = styled.div`
    display: flex;
    align-items: center;
`;

const SubmitButton = styled(Button)`
    margin-top: 24px;
`;

const InputWrapper = styled.div`
    position: relative;
    padding: 10px 25px 5px 0;
    margin-right: 20px;
    width: 200px;
`;

export default withTimeTracking(RentalRangeTwoForm);
