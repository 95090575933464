import { action } from "typesafe-actions";

import { RentalComparison, RentalComparisonActionTypes, SCTFetchParams } from "./types";

export const saveRentalComparison = (rentalComparison: RentalComparison) =>
    action(RentalComparisonActionTypes.SAVE_REQUEST, rentalComparison);

export const saveRentalComparisonSuccess = () => action(RentalComparisonActionTypes.SAVE_SUCCESS);

export const fetchRentalComparisonsByListingID = (listingID: string) =>
    action(RentalComparisonActionTypes.FETCH_BY_LISTING_REQUEST, listingID);

export const fetchRentalComparisonsSuccess = (rentalComparison: RentalComparison[]) =>
    action(RentalComparisonActionTypes.FETCH_SUCCESS, rentalComparison);

export const fetchSCT = (fetchParams: SCTFetchParams) => action(RentalComparisonActionTypes.FETCH_SCT, fetchParams);

export const updateRentalComparison = (rentalComparison: RentalComparison) =>
    action(RentalComparisonActionTypes.UPDATE, rentalComparison);

export const updateRentalComparisonBySourceID = (sourceID: string | undefined, isDismissed: boolean | undefined) =>
    action(RentalComparisonActionTypes.UPDATE_BY_SOURCE_ID, { sourceID, isDismissed });

export const completeRentalValuation = (listingID: string, totalTaskTime: number) =>
    action(RentalComparisonActionTypes.COMPLETED, { listingID, totalTaskTime });
