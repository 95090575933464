import { all, call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { push } from "connected-react-router";
import { captureException } from "@sentry/browser";

import {
    createListingSuccess,
    fetchListingSuccess,
    fetchAllListingsSuccess,
    fetchListingRequest,
    saveListingSuccess,
    createListingRequest,
    saveListingRequest,
    fetchAllListingsRequest,
    saveSelectedComps,
} from "./actions";
import { Listing, ListingActionTypes, ListingOptionalFields } from "./types";
import { routePaths } from "routes";
import { showApiError } from "store/ui";
import {
    decimalToPercentage,
    penceToPounds,
    poundsToPence,
    setUndefinedIfNull,
    setNullIfUnknown,
    setUnknownIfNull,
} from "utils/data-helpers";
import { fetchWithAuth } from "utils/fetch-with-auth";
import { objectToQueryString } from "utils/url-helpers";
import { RentalComparison } from "../rental-comparison/types";
import { SalesComparison } from "../sales-comparison/types";

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

type Comparison = RentalComparison & SalesComparison;

export const mapIncomingData = (data: any[]): Listing[] =>
    data.map((listing) => ({
        ID: listing.ID,
        sourceID: listing.sourceID,
        purplecoID: listing.purplecoID,
        greencoID: listing.greencoID,
        sourcePostcodeID: setUndefinedIfNull(listing.sourcePostcodeID),
        UDPRN: listing.UDPRN,
        UPRN: listing.UPRN,
        status: listing.status,
        title: listing.title,
        address: listing.address,
        postcode: listing.postcode,
        outcode: listing.outcode,
        mainImage: setUndefinedIfNull(listing.mainPhoto),
        longitude: listing.longitude ? listing.longitude.toString() : undefined,
        latitude: listing.latitude ? listing.latitude.toString() : undefined,
        propertyType: setUndefinedIfNull(listing.propertyType),
        propertyStyle: setUndefinedIfNull(listing.propertyStyle),
        propertyTenure: setUndefinedIfNull(listing.propertyTenureType),
        price: penceToPounds(listing.price),
        priceValuationNotes: setUndefinedIfNull(listing.priceValuationNotes),
        predictedMonthlyRent: penceToPounds(listing.predictedMonthlyRent),
        manualPredictedMonthlyRent: penceToPounds(listing.manualPredictedMonthlyRent),
        modelPredictedMonthlyRent: penceToPounds(listing.modelPredictedMonthlyRent),
        rentalValuationNotes: setUndefinedIfNull(listing.rentalValuationNotes),
        rejectReason: setUndefinedIfNull(listing.rejectReason),
        rejectionNotes: setUndefinedIfNull(listing.rejectionNotes),
        rejectionHierarchy: setUndefinedIfNull(listing.rejectionHierarchy),
        rejectedCompletedIn: setUndefinedIfNull(listing.rejectedCompletedIn),
        floorNumber: setUndefinedIfNull(listing.floorNumber),
        numberOfFloors: setUndefinedIfNull(listing.numberOfFloors),
        bedrooms: setUndefinedIfNull(listing.bedrooms),
        totalRooms: setUndefinedIfNull(listing.totalRooms),
        totalSingleBedrooms: setUndefinedIfNull(listing.totalSingleBedrooms),
        totalDoubleBedrooms: setUndefinedIfNull(listing.totalDoubleBedrooms),
        totalBathrooms: setUndefinedIfNull(listing.totalBathrooms),
        totalWCs: setUndefinedIfNull(listing.totalWCs),
        floorArea: setUnknownIfNull(listing.floorArea),
        floorAreaSource: setUndefinedIfNull(listing.floorAreaSource),
        hasOutsideSpace: setUnknownIfNull(listing.hasOutsideSpace),
        hasParking: setUnknownIfNull(listing.hasParking),
        councilTaxBand: setUndefinedIfNull(listing.councilTaxBand),
        councilTaxPrice:
            listing.councilTaxPrice === null
                ? setUnknownIfNull(listing.councilTaxPrice)
                : penceToPounds(listing.councilTaxPrice),
        age: setUndefinedIfNull(listing.age),
        EPCCurrent: setUnknownIfNull(listing.EPCCurrent),
        EPCPotential: setUnknownIfNull(listing.EPCPotential),
        conditionOverall: setUndefinedIfNull(listing.conditionOverall),
        conditionKitchen: setUndefinedIfNull(listing.conditionKitchen),
        conditionInterior: setUndefinedIfNull(listing.conditionInterior),
        conditionBathroom: setUndefinedIfNull(listing.conditionBathroom),
        conditionExterior: setUndefinedIfNull(listing.conditionExterior),
        conditionNotes: setUndefinedIfNull(listing.conditionNotes),
        isOverallGood: listing.prefilterOverall,
        isKitchenGood: listing.prefilterKitchen,
        isBathroomGood: listing.prefilterBathroom,
        isExteriorGood: listing.prefilterExterior,
        isLocationGood: listing.prefilterLocation,
        isFloorPlanGood: listing.prefilterFloorPlan,
        isRentSensible: listing.prefilterRentSensible,
        rentalYield: decimalToPercentage(listing.yearlyRentalYield),
        addressMatchCompletedIn: listing.addressMatchCompletedIn,
        rentalValuationCompletedBy: listing.rentalValuationCompletedBy,
        rentalValuationMinMonthlyRent: penceToPounds(listing.rentalValuationMinMonthlyRent),
        rentalValuationMaxMonthlyRent: penceToPounds(listing.rentalValuationMaxMonthlyRent),
        rentalValuationTwoCompletedBy: listing.rentalValuationTwoCompletedBy,
        rentalValuationTwoMinMonthlyRent: penceToPounds(listing.rentalValuationTwoMinMonthlyRent),
        rentalValuationTwoMaxMonthlyRent: penceToPounds(listing.rentalValuationTwoMaxMonthlyRent),
        moderatedMinMonthlyRent: penceToPounds(listing.moderatedMinMonthlyRent),
        moderatedMaxMonthlyRent: penceToPounds(listing.moderatedMaxMonthlyRent),
        salesValuationMinPrice: penceToPounds(listing.salesValuationMinPrice),
        salesValuationMaxPrice: penceToPounds(listing.salesValuationMaxPrice),
        salesValuationCompletedBy: listing.salesValuationCompletedBy,
        salesValuationTwoMinPrice: penceToPounds(listing.salesValuationTwoMinPrice),
        salesValuationTwoMaxPrice: penceToPounds(listing.salesValuationTwoMaxPrice),
        salesValuationTwoCompletedBy: listing.salesValuationTwoCompletedBy,
        moderatedMinPrice: penceToPounds(listing.moderatedMinPrice),
        moderatedMaxPrice: penceToPounds(listing.moderatedMaxPrice),
        serviceCharge: listing.serviceCharge
            ? penceToPounds(listing.serviceCharge)
            : setUnknownIfNull(listing.serviceCharge),
        leaseDate: setUnknownIfNull(listing.leaseDate),
        leaseLength: setUnknownIfNull(listing.leaseLength),
        preliminaryYearsRemaining: setUnknownIfNull(listing.preliminaryYearsRemaining),
        groundRent: listing.groundRent ? penceToPounds(listing.groundRent) : setUnknownIfNull(listing.groundRent),
    }));

export const mapOutgoingData = (listing: ListingOptionalFields | Listing) => {
    const data = {
        sourceID: listing.sourceID,
        greencoID: listing.greencoID,
        purplecoID: listing.purplecoID,
        UDPRN: listing.UDPRN,
        UPRN: listing.UPRN,
        status: listing.status,
        title: listing.title,
        address: listing.address,
        postcode: listing.postcode,
        propertyType: listing.propertyType,
        propertyStyle: listing.propertyStyle,
        propertyTenureType: listing.propertyTenure,
        price: listing.price && poundsToPence(listing.price),
        priceValuationNotes: listing.priceValuationNotes,
        manualPredictedMonthlyRent: listing.predictedMonthlyRent && poundsToPence(listing.predictedMonthlyRent),
        rentalValuationNotes: listing.rentalValuationNotes,
        rejectReason: listing.rejectReason,
        rejectionNotes: listing.rejectionNotes,
        rejectionHierarchy: listing.rejectionHierarchy,
        rejectedCompletedIn: listing.rejectedCompletedIn,
        floorNumber: listing.floorNumber,
        numberOfFloors: listing.numberOfFloors,
        totalRooms: listing.totalRooms,
        totalSingleBedrooms: listing.totalSingleBedrooms,
        totalDoubleBedrooms: listing.totalDoubleBedrooms,
        totalBathrooms: listing.totalBathrooms,
        totalWCs: listing.totalWCs,
        floorArea: setNullIfUnknown(listing.floorArea),
        floorAreaSource: listing.floorAreaSource,
        hasOutsideSpace: setNullIfUnknown(listing.hasOutsideSpace),
        hasParking: setNullIfUnknown(listing.hasParking),
        councilTaxBand: listing.councilTaxBand,
        councilTaxPrice:
            listing.councilTaxPrice === "unknown"
                ? setNullIfUnknown(listing.councilTaxPrice)
                : listing.councilTaxPrice && poundsToPence(listing.councilTaxPrice),
        age: listing.age,
        EPCCurrent: setNullIfUnknown(listing.EPCCurrent),
        EPCPotential: setNullIfUnknown(listing.EPCPotential),
        conditionOverall: listing.conditionOverall,
        conditionKitchen: listing.conditionKitchen,
        conditionInterior: listing.conditionInterior,
        conditionBathroom: listing.conditionBathroom,
        conditionExterior: listing.conditionExterior,
        conditionNotes: listing.conditionNotes,
        prefilterOverall: listing.isOverallGood,
        prefilterKitchen: listing.isKitchenGood,
        prefilterBathroom: listing.isBathroomGood,
        prefilterExterior: listing.isExteriorGood,
        prefilterLocation: listing.isLocationGood,
        prefilterFloorPlan: listing.isFloorPlanGood,
        prefilterRentSensible: listing.isRentSensible,
        prefilterCompletedIn: listing.prefilterCompletedIn,
        addressMatchCompletedIn: listing.addressMatchCompletedIn,
        basicAnalysisCompletedIn: listing.basicAnalysisCompletedIn,
        rentalValuationCompletedIn: listing.rentalValuationCompletedIn,
        rentalValuationMinMonthlyRent:
            listing.rentalValuationMinMonthlyRent && poundsToPence(listing.rentalValuationMinMonthlyRent),
        rentalValuationMaxMonthlyRent:
            listing.rentalValuationMaxMonthlyRent && poundsToPence(listing.rentalValuationMaxMonthlyRent),
        rentalValuationTwoCompletedIn: listing.rentalValuationTwoCompletedIn,
        rentalValuationTwoMinMonthlyRent:
            listing.rentalValuationTwoMinMonthlyRent && poundsToPence(listing.rentalValuationTwoMinMonthlyRent),
        rentalValuationTwoMaxMonthlyRent:
            listing.rentalValuationTwoMaxMonthlyRent && poundsToPence(listing.rentalValuationTwoMaxMonthlyRent),
        moderatedMinMonthlyRent: listing.moderatedMinMonthlyRent && poundsToPence(listing.moderatedMinMonthlyRent),
        moderatedMaxMonthlyRent: listing.moderatedMaxMonthlyRent && poundsToPence(listing.moderatedMaxMonthlyRent),
        rentalModerationCompletedIn: listing.rentalModerationCompletedIn,
        rentalModerationNotes: listing.rentalModerationNotes,
        salesValuationCompletedIn: listing.salesValuationCompletedIn,
        salesValuationMinPrice: listing.salesValuationMinPrice && poundsToPence(listing.salesValuationMinPrice),
        salesValuationMaxPrice: listing.salesValuationMaxPrice && poundsToPence(listing.salesValuationMaxPrice),
        salesValuationTwoMinPrice:
            listing.salesValuationTwoMinPrice && poundsToPence(listing.salesValuationTwoMinPrice),
        salesValuationTwoMaxPrice:
            listing.salesValuationTwoMaxPrice && poundsToPence(listing.salesValuationTwoMaxPrice),
        salesValuationTwoCompletedIn: listing.salesValuationTwoCompletedIn,
        moderatedMinPrice: listing.moderatedMinPrice && poundsToPence(listing.moderatedMinPrice),
        moderatedMaxPrice: listing.moderatedMaxPrice && poundsToPence(listing.moderatedMaxPrice),
        salesModerationCompletedIn: listing.salesModerationCompletedIn,
        salesModerationNotes: listing.salesModerationNotes,
        qaPrefilterCompletedIn: listing.qaPrefilterCompletedIn,
        qaAddressMatchCompletedIn: listing.qaAddressMatchCompletedIn,
        qaBasicAnalysisCompletedIn: listing.qaBasicAnalysisCompletedIn,
        qaRentalValuationCompletedIn: listing.qaRentalValuationCompletedIn,
        qaRentalValuationTwoCompletedIn: listing.qaRentalValuationTwoCompletedIn,
        qaRentalModerationCompletedIn: listing.qaRentalModerationCompletedIn,
        qaSalesValuationCompletedIn: listing.qaSalesValuationCompletedIn,
        qaSalesValuationTwoCompletedIn: listing.qaSalesValuationTwoCompletedIn,
        qaSalesModerationCompletedIn: listing.qaSalesModerationCompletedIn,
        serviceCharge: setCurrencyToNullWhenUnknown(listing.serviceCharge),
        leaseDate: setValueToNullWhenUnknown(listing.leaseDate),
        leaseLength: setValueToNullWhenUnknown(listing.leaseLength),
        preliminaryYearsRemaining: setValueToNullWhenUnknown(listing.preliminaryYearsRemaining),
        groundRent: setCurrencyToNullWhenUnknown(listing.groundRent),
    };
    Object.keys(data).map((key) => {
        if (data[key] === undefined) {
            delete data[key];
        }
        return false;
    });

    return data;
};

export const setCurrencyToNullWhenUnknown = (value?: string | number | null) => {
    if (value === "unknown" || value === null || value === "") {
        return null;
    }
    if (value === undefined) {
        return undefined;
    }
    return poundsToPence(Number(value));
};

export const setValueToNullWhenUnknown = (value?: string | number | null) => {
    if (value === "unknown" || value === "") {
        return null;
    }
    return value;
};

export function* handleFetchAll(action: ReturnType<typeof fetchAllListingsRequest>) {
    try {
        const queryString = action.payload ? objectToQueryString(action.payload) : "";
        const response = yield call(fetchWithAuth, `${API_ENDPOINT}/v1/listings${queryString}`);

        if (response.ok === true) {
            const body = yield response.json();

            if (body.results && body.results.length > 0) {
                const mappedData = mapIncomingData(body.results);
                yield put(fetchAllListingsSuccess(mappedData));
            }
        } else {
            captureException(JSON.stringify({ message: "failed to fetch all listings", status: response.status }));
            const body = yield response.json();

            yield put(showApiError(body.error));
        }
    } catch (error) {
        yield put(showApiError(error));
    }
}

export function* handleFetch(action: ReturnType<typeof fetchListingRequest>) {
    try {
        const response = yield call(fetchWithAuth, `${API_ENDPOINT}/v1/listings/${action.payload}`);
        if (response.ok === true) {
            const body = yield response.json();

            if (body) {
                const mappedData = mapIncomingData([body]);
                yield put(fetchListingSuccess(mappedData[0]));
            } else {
                yield put(showApiError("No data returned"));
            }
        } else {
            captureException(JSON.stringify({ message: "failed to fetch listing", status: response.status }));

            const body = yield response.json();
            yield put(showApiError(body.error));
        }
    } catch (error) {
        yield put(showApiError(error));
    }
}

export function* handleSaveSelectedComps(action: ReturnType<typeof saveSelectedComps>) {
    const comparisons: Comparison[] = action.payload;

    if (comparisons.length > 0) {
        try {
            yield all(
                comparisons
                    .filter((comp) => !comp.isDismissed)
                    .map(function* (comparison) {
                        const response = yield call(
                            fetchWithAuth,
                            `${API_ENDPOINT}/v1/rental-comparisons/${comparison.ID}`,
                            {
                                method: "PATCH",
                                body: JSON.stringify({ selected: true, ranking: comparison.ranking }),
                            },
                        );
                        if (!response.ok) {
                            captureException(
                                JSON.stringify({
                                    message: "failed to save selected comparisons",
                                    status: response.status,
                                }),
                            );

                            const body = yield response.json();
                            yield put(showApiError(body.error));
                        }
                    }),
            );
        } catch (error) {
            yield put(showApiError(error));
        }
    } else {
        yield put(showApiError("No rental comparisons were found"));
    }
}

export function* handleSave(action: ReturnType<typeof saveListingRequest>) {
    try {
        const mappedOutgoingData = mapOutgoingData(action.payload.data);
        const response = yield call(fetchWithAuth, `${API_ENDPOINT}/v1/listings/${action.payload.data.ID}`, {
            method: "PATCH",
            body: JSON.stringify(mappedOutgoingData),
        });
        if (response.ok === true) {
            const body = yield response.json();
            const mappedIncomingData = mapIncomingData([body]);
            yield put(saveListingSuccess(mappedIncomingData[0]));

            if (!action.payload.leaseholdInfoUpdate) {
                yield put(push(routePaths.review));
            }
        } else {
            captureException(JSON.stringify({ message: "failed to save listing", status: response.status }));
            const body = yield response.json();
            yield put(showApiError(body.error));
        }
    } catch (error) {
        yield put(showApiError(error));
    }
}

export function* handleCreate(action: ReturnType<typeof createListingRequest>) {
    try {
        const mappedOutgoingData = mapOutgoingData({
            predictedMonthlyRent: action.payload.rentalValuationMax,
            ...action.payload,
        });
        const response = yield call(fetchWithAuth, `${API_ENDPOINT}/v1/listings`, {
            method: "POST",
            body: JSON.stringify(mappedOutgoingData),
        });

        if (response.ok === true) {
            const data = yield response.json();
            const mappedData = mapIncomingData([data])[0];

            yield put(createListingSuccess(mappedData));
            yield put(push(routePaths.review));
        } else {
            captureException(JSON.stringify({ message: "failed to create listing", status: response.status }));
            const body = yield response.json();
            yield put(showApiError(body.error));
        }
    } catch (error) {
        yield put(showApiError(error));
    }
}

function* watchFetchListingRequest() {
    yield takeEvery(ListingActionTypes.FETCH_REQUEST, handleFetch);
}

function* watchFetchAllListingsRequest() {
    yield takeEvery(ListingActionTypes.FETCH_ALL_REQUEST, handleFetchAll);
}

function* watchCreateListingRequest() {
    yield takeLatest(ListingActionTypes.CREATE_REQUEST, handleCreate);
}

function* watchSaveListingRequest() {
    yield takeLatest(ListingActionTypes.SAVE_REQUEST, handleSave);
}

function* watchSaveSelectedCompsRequest() {
    yield takeEvery(ListingActionTypes.SAVE_SELECTED_COMPS, handleSaveSelectedComps);
}

function* listingSagas() {
    yield all([
        watchFetchListingRequest(),
        watchFetchAllListingsRequest(),
        watchSaveListingRequest(),
        watchCreateListingRequest(),
        watchSaveSelectedCompsRequest(),
    ]);
}

export { listingSagas };
