import React, { Component, ComponentType } from "react";
import { compose, Dispatch } from "redux";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";

import { AppState } from "store";
import { fetchListingRequest, saveListingRequest } from "store/listing/actions";
import { listingSelectors } from "store/listing/reducer";
import { Listing } from "store/listing";

interface PropsFromState {
    listing: Listing;
}

interface PropsFromDispatch {
    fetchListings: typeof fetchListingRequest;
    saveListing: typeof saveListingRequest;
}

type MappedProps = PropsFromState & PropsFromDispatch;

interface RouteParams {
    listingID: string;
}

export const withListing = <WrappedComponentProps extends object>(WrappedComponent: ComponentType<any>) =>
    class WithListingHOC extends Component<WrappedComponentProps & RouteComponentProps<RouteParams> & MappedProps> {
        componentDidMount() {
            const { fetchListings } = this.props;
            const listingID = this.props.match.params.listingID;

            fetchListings(listingID);
        }

        render() {
            const { listing } = this.props;

            return listing && Object.keys(listing).length > 0 ? <WrappedComponent {...this.props} /> : null;
        }
    };

const mapStateToProps = (state: AppState, ownProps: RouteComponentProps<RouteParams>) => ({
    listing: listingSelectors.getListingByID(state, ownProps.match.params.listingID)
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    fetchListings: (listingID: string) => dispatch(fetchListingRequest(listingID)),
    saveListing: (listing: Listing) => dispatch(saveListingRequest(listing))
});

export default compose<ReturnType<typeof withListing>>(connect(mapStateToProps, mapDispatchToProps), withListing);
