import React, { useEffect, useState } from "react";
import { Listing } from "store/listing";
import styled from "styled-components";

import {
    CardContentWrapper,
    CardList,
    CardSubTitle,
    CardTitle,
    CardNotes,
    MainWrapper,
    MainContentWrapper,
    NotesWrapper,
    NotesHeader,
} from "../helper-components";
import { Button } from "components/button";
import { routePaths } from "routes";
import { editLeaseholdFields, readOnlyLeaseholdFields } from "utils/styles";
export interface IMainListingInfoCardProps {
    /** The listing to display */
    listing: Listing;
    /** Whether or not the listing should be automatically opened */
    autoOpenListing?: boolean;
    /** make PATCH request to update listing info with leasehold info */
    updateListing: (values: Listing, leaseholdInfoUpdate?: boolean) => void;
}

export const MainListingInfoCard: React.FC<IMainListingInfoCardProps> = ({
    listing,
    autoOpenListing = true,
    updateListing,
}) => {
    const [viewingLeaseholdDetails, setViewingLeaseholdDetails] = useState({ show: false, edit: false });
    const { purplecoID, greencoID } = listing;
    const sourceID = purplecoID || greencoID || "";
    const listingPath = purplecoID ? routePaths.purpleCo : routePaths.greenCo;
    const windowRef = "listingURL";
    const URL = listingPath.replace(":sourceID", sourceID);

    useEffect(() => {
        if (sourceID && URL && autoOpenListing) {
            window.open(URL, windowRef);
        }
    }, [sourceID, URL, autoOpenListing]);

    useEffect(() => {
        const onSalesValuation = window.location.pathname.includes("sales-valuation");
        const onRentalModeration = window.location.pathname.includes("rental-moderation");
        const tenureIsShareOfFreeholdOrLeasehold =
            listing.propertyTenure === "share-of-freehold" || listing.propertyTenure === "leasehold";

        if (tenureIsShareOfFreeholdOrLeasehold) {
            onSalesValuation && setViewingLeaseholdDetails({ show: true, edit: false });
            onRentalModeration && setViewingLeaseholdDetails({ show: true, edit: true });
        }
    }, [listing]);

    const handleOnClick = () => {
        window.open(URL, "_blank");
    };

    return (
        <MainWrapper>
            <LeftContainer>
                <Image src={listing.mainImage} />
                <ButtonContainer>
                    <Button type="button" onClick={handleOnClick}>
                        Manually Open Listing
                    </Button>
                </ButtonContainer>
            </LeftContainer>
            <MainContentWrapper>
                <CardTitle>
                    {listing.postcode} ({listing.address})
                </CardTitle>
                <CardContentWrapper>
                    <CardList>
                        <li>
                            <CardSubTitle>Rooms</CardSubTitle>
                        </li>
                        <List>
                            <span>Single:</span> <span>{listing.totalSingleBedrooms}</span>
                        </List>
                        <List>
                            <span>Double:</span> <span>{listing.totalDoubleBedrooms}</span>
                        </List>
                        <List>
                            <span>Bathrooms:</span> <span>{listing.totalBathrooms}</span>
                        </List>
                        <List>
                            <span>WCs:</span> <span>{listing.totalWCs}</span>
                        </List>
                        <List>
                            <span>Total Rooms:</span> <span>{listing.totalRooms}</span>
                        </List>
                    </CardList>
                    <CardList>
                        <li>
                            <CardSubTitle>Area & Condition</CardSubTitle>
                        </li>
                        <List>
                            <span>Total Area:</span>{" "}
                            <span>
                                {listing.floorArea !== "unknown" ? (
                                    <>
                                        {listing.floorArea}m<Sup>2</Sup>
                                    </>
                                ) : (
                                    listing.floorArea
                                )}
                            </span>
                        </List>
                        <List>
                            <span>Overall:</span> <span>{listing.conditionOverall || "unknown"}</span>
                        </List>
                        <List>
                            <span>Exterior:</span> <span>{listing.conditionExterior || "unknown"}</span>
                        </List>
                        <List>
                            <span>Interior:</span> <span>{listing.conditionInterior || "unknown"}</span>
                        </List>
                        <List>
                            <span>Kitchen:</span> <span>{listing.conditionKitchen || "unknown"}</span>
                        </List>
                        <List>
                            <span>Bathroom:</span> <span>{listing.conditionBathroom || "unknown"}</span>
                        </List>
                    </CardList>
                </CardContentWrapper>
                <NotesHeader>Notes from Analysis</NotesHeader>
                <NotesWrapper>
                    <CardNotes>{listing.conditionNotes}</CardNotes>
                </NotesWrapper>

                {viewingLeaseholdDetails.show && (
                    <>
                        <NotesHeader>Leasehold Information</NotesHeader>

                        {viewingLeaseholdDetails.edit
                            ? editLeaseholdFields(listing, updateListing)
                            : readOnlyLeaseholdFields(listing)}
                    </>
                )}
            </MainContentWrapper>
        </MainWrapper>
    );
};

const List = styled.li`
    display: flex;
    justify-content: space-between;
    min-width: 150px;
    color: ${({ theme }) => theme.grey600};
`;

const Sup = styled.sup`
    font-size: 10px;
    vertical-align: top;
`;

const LeftContainer = styled.div`
    background: ${({ theme }) => theme.grey400};
`;

const Image = styled.img`
    object-fit: cover;
    width: 250px;
    height: 300px;
`;

const ButtonContainer = styled.div`
    display: flex;
    justify-content: center;
    padding: 16px;
`;
