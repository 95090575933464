import { Reducer } from "redux";
import { createSelector } from "reselect";
import unique from "array-unique";

import { ListingState, ListingActionTypes, ListingStatusTypes } from "./types";
import { getAllIDsFromData, dataToObjectByKey } from "utils/data-helpers";
import { globaliseSelectors } from "utils/globalise-selectors";

const initialState: ListingState = {
    byID: {},
    allIDs: []
};

const reducer: Reducer<ListingState> = (state = initialState, action) => {
    switch (action.type) {
        case ListingActionTypes.FETCH_SUCCESS:
        case ListingActionTypes.SAVE_SUCCESS:
        case ListingActionTypes.CREATE_SUCCESS:
            return {
                ...state,
                byID: {
                    ...state.byID,
                    [action.payload.ID]: action.payload
                },
                allIDs: unique([...state.allIDs, action.payload.ID])
            };

        case ListingActionTypes.FETCH_ALL_SUCCESS:
            return {
                ...state,
                byID: {
                    ...state.byID,
                    ...dataToObjectByKey(action.payload, "ID")
                },
                allIDs: unique([...state.allIDs, ...getAllIDsFromData(action.payload, "ID")])
            };

        default:
            return state;
    }
};

export { reducer as listingReducer };

export const getAllListings = (state: ListingState) => state.byID;

export const getListingByID = (state: ListingState, listingID: string) => state.byID[listingID] || {};

export const getAllListingIDs = (state: ListingState) => state.allIDs;

export const getAllListingsByStatus = createSelector(
    (state: ListingState, status: ListingStatusTypes) => status,
    getAllListings,
    (status, listings) => {
        const match = Object.keys(listings).filter((item) => listings[item].status === status);
        return match.map((item) => listings[item]);
    }
);

export const listingSelectors = globaliseSelectors(
    {
        getAllListings,
        getListingByID,
        getAllListingIDs,
        getAllListingsByStatus
    },
    "listings"
);
