import { all, takeEvery, call, put } from "redux-saga/effects";

import { fetchCustomerListings, fetchCustomerListingsSuccess } from "store/customer-listing/actions";
import { showApiError } from "store/ui";
import { CustomerListing, CustomerListingActionTypes } from "store/customer-listing/types";
import { penceToPounds, poundsToPence, decimalToPercentage, stringToKebabCase } from "utils/data-helpers";
import { fetchWithAuth } from "utils/fetch-with-auth";

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

export const mapIncomingData = (data: any[]): CustomerListing[] => {
    return data.map((listing) => ({
        ID: listing.ID,
        sourceID: listing.sourceID,
        title: listing.title,
        address: listing.address,
        postcode: listing.postcode,
        mainImage: listing.mainPhoto,
        imageUrls: [],
        price: penceToPounds(listing.price),
        totalSingleBedrooms: listing.totalSingleBedrooms || 0,
        totalDoubleBedrooms: listing.totalDoubleBedrooms || 0,
        totalWCs: listing.totalWCs || 0,
        totalBathrooms: listing.totalBathrooms || 0,
        predictedMonthlyRent: penceToPounds(listing.predictedMonthlyRent),
        ownershipPercentage: 0,
        buyOutMonth: listing.customerBuyoutMonths || 0,
        IRR: decimalToPercentage(listing.IRR),
        rentalYield: decimalToPercentage(listing.yearlyRentalYield),
        status: listing.status,
    }));
};

export function* handleFetch(action: ReturnType<typeof fetchCustomerListings>) {
    try {
        const {
            launchArea,
            searchType,
            numOfAdults,
            postcode,
            income1,
            income2,
            distanceRadiusMetres,
            deposit,
            children0to1,
            children2to4,
            children5to11,
            children12to16,
        } = action.payload;
        const income1InPence = poundsToPence(income1);
        const depositInPence = poundsToPence(deposit);
        const encodedPostcode = encodeURIComponent(postcode || "");
        const kebabedLaunchArea = stringToKebabCase(launchArea || "");
        let url = `${API_ENDPOINT}/v1/listings?adults=${numOfAdults}&income=${income1InPence}&deposit=${depositInPence}&babies=${children0to1}&preSchoolChildren=${children2to4}&primarySchoolChildren=${children5to11}&secondarySchoolChildren=${children12to16}`;
        url +=
            searchType === "prefilter"
                ? "&status=prefilter"
                : "&status=addressMatch&status=basicAnalysis&status=rentalValuation&status=reviewed&enableIRR=1";
        url += income2 ? `&income=${poundsToPence(income2)}` : "";
        url += postcode
            ? `&postcode=${encodedPostcode}&radius=${distanceRadiusMetres}`
            : `&launchArea=${kebabedLaunchArea}`;

        const response = yield call(fetchWithAuth, url, { method: "GET" });

        if (response.ok === true) {
            const body = yield response.json();

            if (body.results && body.results.length > 0) {
                const mappedData = mapIncomingData(body.results);
                yield put(fetchCustomerListingsSuccess(mappedData));
            } else {
                yield put(showApiError("No results found"));
            }
        } else {
            const body = yield response.json();
            yield put(showApiError(body.error));
        }
    } catch (error) {
        yield put(showApiError(error));
    }
}

function* watchFetchRequest() {
    yield takeEvery(CustomerListingActionTypes.FETCH_BY_CUSTOMER_DETAILS, handleFetch);
}

function* customerListingSagas() {
    yield all([watchFetchRequest()]);
}

export { customerListingSagas };
