import React, { Component } from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import styled from "styled-components";

import { fetchCustomerListings } from "store/customer-listing/actions";
import { CustomerListingFilter } from "store/customer-listing";
import CustomerListingContainer from "containers/customer-listing-container";
import { ListingSearchForm, FormProps } from "./listing-search-form";
import { PropertyCard } from "components/property-card";
import { Heading3, Body } from "typography";

interface PropsFromDispatch {
    searchCustomerListings: typeof fetchCustomerListings;
}

export class CustomerListingSearch extends Component<PropsFromDispatch> {
    handleSearch = (values: FormProps) => {
        this.props.searchCustomerListings({
            launchArea: values.launchArea,
            searchType: values.searchType,
            income1: values.income1,
            income2: values.income2,
            postcode: values.postcode,
            distanceRadiusMetres: values.radius,
            deposit: values.deposit,
            numOfAdults: values.numOfAdults,
            children0to1: values.children0to1,
            children2to4: values.children2to4,
            children5to11: values.children5to11,
            children12to16: values.children12to16
        });
    };

    render() {
        return (
            <Wrapper>
                <FormSection>
                    <ListingSearchForm onSubmit={this.handleSearch} />
                </FormSection>
                <ResultsSection>
                    <CustomerListingContainer>
                        {({ customerListings }) =>
                            customerListings.length > 0 ? (
                                <>
                                    <ListingHeading>{customerListings.length} Listing Result(s)</ListingHeading>
                                    {customerListings.map((customerListing) => (
                                        <PropertyCard
                                            key={customerListing.ID}
                                            listingID={customerListing.ID}
                                            sourceID={customerListing.sourceID}
                                            imageUrls={[customerListing.mainImage]}
                                            address={customerListing.address}
                                            postcode={customerListing.postcode}
                                            propertyType={customerListing.title}
                                            singleBedroom={customerListing.totalSingleBedrooms}
                                            doubleBedroom={customerListing.totalDoubleBedrooms}
                                            bathrooms={customerListing.totalBathrooms}
                                            askingPrice={customerListing.price}
                                            predictedRent={customerListing.predictedMonthlyRent}
                                            buyOutMonth={`Month ${customerListing.buyOutMonth}`}
                                            ownershipPercentage={customerListing.ownershipPercentage}
                                            irrPercentage={customerListing.IRR}
                                            yieldPercentage={customerListing.rentalYield}
                                            status={customerListing.status}
                                        />
                                    ))}
                                </>
                            ) : (
                                <NoResultsMessage>No results</NoResultsMessage>
                            )
                        }
                    </CustomerListingContainer>
                </ResultsSection>
            </Wrapper>
        );
    }
}

const Wrapper = styled.div`
    display: flex;
`;

const FormSection = styled.div`
    width: 420px;
    padding: 20px;
`;

const ResultsSection = styled.div`
    padding: 20px;
    border-left: 1px solid ${(props) => props.theme.grey400};
`;

const ListingHeading = styled.h2`
    ${Heading3};
    margin-bottom: 30px;
`;

const NoResultsMessage = styled.p`
    ${Body};
`;

const mapDispatchToProps = (dispatch: Dispatch) => ({
    searchCustomerListings: (filters: CustomerListingFilter) => dispatch(fetchCustomerListings(filters))
});

export default connect(null, mapDispatchToProps)(CustomerListingSearch);
