export const firstLevelOptions = [
    {
        displayName: "Property",
        name: "level1",
        value: "property",
    },
    {
        displayName: "Location",
        name: "level1",
        value: "location",
    },
    {
        displayName: "Can't complete",
        name: "level1",
        value: "can't complete",
    },
    {
        displayName: "Flag for moderator",
        name: "level1",
        value: "flag for moderator",
    },
];

export const getSecondLevel = (level1: string) => {
    switch (level1) {
        case "property":
            return secondLevelPropertyOptions;
        case "location":
            return secondLevelLocationOptions;
        case "can't complete":
            return secondLevelCannotComplete;
        default:
            return [];
    }
};

export const getThirdLevel = (level2: string) => {
    switch (level2) {
        case "property type":
            return thirdLevelPropertyTypeOptions;
        case "layout/floorplan":
            return thirdLevelLayoutOptions;
        case "epc":
            return thirdLevelEPCOptions;
        case "interior":
            return thirdLevelInteriorOptions;
        case "exterior":
            return thirdLevelExteriorOptions;
        case "environmental":
            return thirdLevelEnvironmentalOptions;
        case "transport infrastructure":
            return thirdLevelTransportInfrastructureOptions;
        case "bad neighbours":
            return thirdLevelBadNeighboursOptions;
        case "energy infrastructure":
            return thirdLevelEnergyInfrastructure;
        case "prefilter":
            return thirdLevelPrefilter;
        case "address match":
            return thirdLevelAddressMatch;
        case "basic analysis":
            return thirdLevelBasicAnalysis;
        case "rental vals":
            return thirdLevelRentalVals;
        case "rental moderation":
            return thirdLevelRentalModeration;
        case "sales vals":
            return thirdLevelSalesVal;
        case "sales moderation":
            return thirdLevelSalesModeration;
        default:
            return [];
    }
};

const secondLevelPropertyOptions = [
    {
        displayName: "Property type",
        name: "level2",
        value: "property type",
    },
    {
        displayName: "Layout/ floorplan",
        name: "level2",
        value: "layout/floorplan",
    },
    {
        displayName: "EPC",
        name: "level2",
        value: "epc",
    },
    {
        displayName: "Interior",
        name: "level2",
        value: "interior",
    },
    {
        displayName: "Exterior",
        name: "level2",
        value: "exterior",
    },
];

const secondLevelLocationOptions = [
    {
        displayName: "Environmental",
        name: "level2",
        value: "environmental",
    },
    {
        displayName: "Transport infrastructure",
        name: "level2",
        value: "transport infrastructure",
    },
    {
        displayName: "Bad neighbours",
        name: "level2",
        value: "bad neighbours",
    },
    {
        displayName: "Energy infrastructure",
        name: "level2",
        value: "energy infrastructure",
    },
];

const secondLevelCannotComplete = [
    {
        displayName: "Prefilter",
        name: "level2",
        value: "prefilter",
    },
    {
        displayName: "Address match",
        name: "level2",
        value: "address match",
    },
    {
        displayName: "Basic analysis",
        name: "level2",
        value: "basic analysis",
    },
    {
        displayName: "Rental vals",
        name: "level2",
        value: "rental vals",
    },
    {
        displayName: "Rental moderation",
        name: "level2",
        value: "rental moderation",
    },
    {
        displayName: "Sales vals",
        name: "level2",
        value: "sales vals",
    },
    {
        displayName: "Sales moderation",
        name: "level2",
        value: "sales moderation",
    },
];

const thirdLevelPropertyTypeOptions = [
    {
        displayName: "Leasehold",
        name: "level3",
        value: "leasehold",
    },
    {
        displayName: "Ex-Council",
        name: "level3",
        value: "ex-council",
    },
    {
        displayName: "High rise",
        name: "level3",
        value: "high rise",
    },
    {
        displayName: "Above commercial",
        name: "level3",
        value: "above commercial",
    },
    {
        displayName: "New build",
        name: "level3",
        value: "new build",
    },
    {
        displayName: "Other",
        name: "level3",
        value: "other",
    },
];

const thirdLevelLayoutOptions = [
    {
        displayName: "Only bathroom on ground floor",
        name: "level3",
        value: "only bathroom on ground floor",
    },
    {
        displayName: "Bathroom off main kitchen",
        name: "level3",
        value: "bathroom off main kitchen",
    },
    {
        displayName: "Bedroom access through another room",
        name: "level3",
        value: "bedroom access through another room",
    },
    {
        displayName: "Inverted Layout",
        name: "level3",
        value: "inverted layout",
    },
    {
        displayName: "Underground living area >50%",
        name: "level3",
        value: "underground living area >50%",
    },
    {
        displayName: "Min. size of property",
        name: "level3",
        value: "min size of property",
    },
    {
        displayName: "Other",
        name: "level3",
        value: "other",
    },
];

const thirdLevelEPCOptions = [
    {
        displayName: "Less than 45",
        name: "level3",
        value: "less than 45",
    },
    {
        displayName: "Other",
        name: "level3",
        value: "other",
    },
];

const thirdLevelInteriorOptions = [
    {
        displayName: "WC",
        name: "level3",
        value: "wc",
    },
    {
        displayName: "Bathroom",
        name: "level3",
        value: "bathroom",
    },
    {
        displayName: "Kitchen",
        name: "level3",
        value: "kitchen",
    },
    {
        displayName: "Stairs",
        name: "level3",
        value: "stairs",
    },
    {
        displayName: "Living Room",
        name: "level3",
        value: "living room",
    },
    {
        displayName: "Loft conversion",
        name: "level3",
        value: "loft conversion",
    },
    {
        displayName: "Hallway/ landing",
        name: "level3",
        value: "hallway/ landing",
    },
    {
        displayName: "Utility room",
        name: "level3",
        value: "utility room",
    },
    {
        displayName: "Basement",
        name: "level3",
        value: "basement",
    },
    {
        displayName: "Bedroom",
        name: "level3",
        value: "bedroom",
    },
    {
        displayName: "Other",
        name: "level3",
        value: "other",
    },
];

const thirdLevelExteriorOptions = [
    {
        displayName: "Roof",
        name: "level3",
        value: "roof",
    },
    {
        displayName: "Glazing/ windows",
        name: "level3",
        value: "glazing/ windows",
    },
    {
        displayName: "Outside space",
        name: "level3",
        value: "outside space",
    },
    {
        displayName: "Walls",
        name: "level3",
        value: "walls",
    },
    {
        displayName: "Other",
        name: "level3",
        value: "other",
    },
];

const thirdLevelEnvironmentalOptions = [
    {
        displayName: "Tree",
        name: "level3",
        value: "tree",
    },
    {
        displayName: "Cliff edge",
        name: "level3",
        value: "cliff edge",
    },
    {
        displayName: "Sea",
        name: "level3",
        value: "sea",
    },
    {
        displayName: "River",
        name: "level3",
        value: "river",
    },
    {
        displayName: "Other",
        name: "level3",
        value: "other",
    },
];

const thirdLevelTransportInfrastructureOptions = [
    {
        displayName: "Airport",
        name: "level3",
        value: "airport",
    },
    {
        displayName: "Rail",
        name: "level3",
        value: "rail",
    },
    {
        displayName: "Road",
        name: "level3",
        value: "road",
    },
    {
        displayName: "Other",
        name: "level3",
        value: "other",
    },
];

const thirdLevelBadNeighboursOptions = [
    {
        displayName: "Public venue",
        name: "level3",
        value: "public venue",
    },
    {
        displayName: "Petrol station",
        name: "level3",
        value: "petrol station",
    },
    {
        displayName: "Commercial area",
        name: "level3",
        value: "commerical area",
    },
    {
        displayName: "Hospital",
        name: "level3",
        value: "hospital",
    },
    {
        displayName: "School",
        name: "level3",
        value: "school",
    },
    {
        displayName: "Graveyard",
        name: "level3",
        value: "graveyard",
    },

    {
        displayName: "Prison",
        name: "level3",
        value: "prison",
    },
    {
        displayName: "Industrial estate",
        name: "level3",
        value: "industrial estate",
    },
    {
        displayName: "Council estate",
        name: "level3",
        value: "council estate",
    },
    {
        displayName: "Other",
        name: "level3",
        value: "other",
    },
];

const thirdLevelEnergyInfrastructure = [
    {
        displayName: "Power station",
        name: "level3",
        value: "power station",
    },
    {
        displayName: "Pylons",
        name: "level3",
        value: "pylons",
    },
    {
        displayName: "Waste incinerator",
        name: "level3",
        value: "waste incinerator",
    },
    {
        displayName: "Other",
        name: "level3",
        value: "other",
    },
];

const thirdLevelPrefilter = [
    {
        displayName: "Too many unknowns",
        name: "level3",
        value: "too many unknowns",
    },
    {
        displayName: "Other",
        name: "level3",
        value: "other",
    },
];

const thirdLevelAddressMatch = [
    {
        displayName: "Not possible",
        name: "level3",
        value: "not possible",
    },
    {
        displayName: "Other",
        name: "level3",
        value: "other",
    },
];

const thirdLevelBasicAnalysis = [
    {
        displayName: "Not possible",
        name: "level3",
        value: "not possible",
    },
    {
        displayName: "Other",
        name: "level3",
        value: "other",
    },
];

const thirdLevelRentalVals = [
    {
        displayName: "Insufficent Comps",
        name: "level3",
        value: "insufficent comps",
    },
    {
        displayName: "Other",
        name: "level3",
        value: "other",
    },
];

const thirdLevelRentalModeration = [
    {
        displayName: "Low quality comps",
        name: "level3",
        value: "low quality comps",
    },
    {
        displayName: "Other",
        name: "level3",
        value: "other",
    },
];

const thirdLevelSalesVal = [
    {
        displayName: "Insufficent comps",
        name: "level3",
        value: "insufficent comps",
    },
    {
        displayName: "Other",
        name: "level3",
        value: "other",
    },
];

const thirdLevelSalesModeration = [
    {
        displayName: "Low quality comps",
        name: "level3",
        value: "Low quality comps",
    },
    {
        displayName: "Other",
        name: "level3",
        value: "other",
    },
];

// onclick get second options
// switch case 'property' return ... 'secondprop'options return nul

// onclick get thrid options
// switch case 'property' return ... 'thirdprop'options return null
