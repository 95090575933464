import React from "react";
import styled from "styled-components/macro";

import { Button } from "components/button";

export interface Props {
    className?: string;
    onClick?: () => void;
}

export const RejectButton = ({ className, onClick }: Props) => (
    <StyledButton className={className} onClick={onClick}>
        Reject Listing
    </StyledButton>
);

const StyledButton = styled(Button)`
    background-color: ${(props) => props.theme.red500};
    color: #fff;
`;
