import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useDeepCompareEffect from "use-deep-compare-effect";

import { AppState } from "store";
import { fetchRentalComparisonsByListingID } from "store/rental-comparison/actions";
import { RentalComparison } from "store/rental-comparison/types";
import { rentalComparisonSelectors } from "store/rental-comparison/reducer";
import { fetchSalesComparisonsByListingID } from "store/sales-comparison/actions";
import { SalesComparison } from "store/sales-comparison/types";
import { salesComparisonSelectors } from "store/sales-comparison/reducer";

type CompsByOperator<T> = { [selectedBy: string]: Array<Comp<T>> };
type Comp<T> = T extends "rental" ? RentalComparison : SalesComparison;

export const useCompsByOperator = <T1, T2>(
    forListingID: string,
    compsType: "rental" | "sales"
): CompsByOperator<T2> => {
    const selector =
        compsType === "rental"
            ? rentalComparisonSelectors.getRentalComparisonsByListingID
            : salesComparisonSelectors.getSalesComparisonsByListingID;
    const [comps, setComps] = useState<CompsByOperator<T2>>({});
    const compsFromStore: Array<Comp<T2>> = useSelector((state: AppState) => selector(state, forListingID));
    const dispatch = useDispatch();

    useEffect(() => {
        compsType === "rental"
            ? dispatch(fetchRentalComparisonsByListingID(forListingID))
            : dispatch(fetchSalesComparisonsByListingID(forListingID));
    }, [dispatch, compsType, forListingID]);

    useDeepCompareEffect(() => {
        if (compsFromStore.length) {
            const compsGroupedByOperator = compsFromStore.reduce((allComps, currentComp) => {
                const { selectedBy } = currentComp;
                if (selectedBy) {
                    if (!allComps.hasOwnProperty(selectedBy)) {
                        allComps[selectedBy] = [];
                    }
                    allComps[selectedBy].push(currentComp);
                }
                return allComps;
            }, {});

            setComps(compsGroupedByOperator);
        }
    }, [compsFromStore, forListingID]);

    return comps;
};
