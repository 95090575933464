import React, { Component } from "react";
import { Formik } from "formik";
import styled from "styled-components";
import { Button } from "components/button";
import { Input, Label } from "components/form";

import { addListingValidationSchema } from "./add-listing-validation-schema";
import { FormErrors } from "components/form";

export interface Props {
    onSubmit: (sourceID: string, postcode: string, rentalValuationMax: number) => void;
}

export class AddListingForm extends Component<Props> {
    handleSubmit = (values: { sourceID: string; postcode: string; rentalValuationMax: number }) => {
        const { onSubmit } = this.props;
        const { sourceID, postcode, rentalValuationMax } = values;

        onSubmit(sourceID, postcode, rentalValuationMax);
    };

    render() {
        return (
            <Formik
                initialValues={{
                    sourceID: "",
                    postcode: "",
                    rentalValuationMax: 0
                }}
                onSubmit={this.handleSubmit}
                validationSchema={addListingValidationSchema}
                validateOnBlur={false}
                validateOnChange={false}
                render={(props) => (
                    <>
                        {Object.keys(props.errors).length > 0 && (
                            <FormErrors>
                                <ul>
                                    {Object.keys(props.errors).map((error, index) => (
                                        <li key={index}>{props.errors[error]}</li>
                                    ))}
                                </ul>
                            </FormErrors>
                        )}
                        <FormContainer onSubmit={props.handleSubmit}>
                            <InputWrapper>
                                <Label>Listing Identifier</Label>
                                <Input name="sourceID" onChange={props.handleChange} />
                            </InputWrapper>
                            <InputWrapper>
                                <Label>Postcode</Label>
                                <Input name="postcode" onChange={props.handleChange} />
                            </InputWrapper>
                            <InputWrapper>
                                <Label>Predicted Rent</Label>
                                <Input name="rentalValuationMax" onChange={props.handleChange} />
                            </InputWrapper>
                            <Button type="submit">Add listing</Button>
                        </FormContainer>
                    </>
                )}
            />
        );
    }
}

const FormContainer = styled.form`
    display: flex;
    align-items: baseline;
    margin-bottom: 15px;
`;

const InputWrapper = styled.div`
    margin: 5px 15px 5px 0;
`;
