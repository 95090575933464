import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useDeepCompareEffect from "use-deep-compare-effect";

import { AppState } from "store";
import { fetchRentalComparisonsByListingID } from "store/rental-comparison/actions";
import { RentalComparison } from "store/rental-comparison/types";
import { rentalComparisonSelectors } from "store/rental-comparison/reducer";
import { fetchSalesComparisonsByListingID } from "store/sales-comparison/actions";
import { SalesComparison } from "store/sales-comparison/types";
import { salesComparisonSelectors } from "store/sales-comparison/reducer";

type TValuationIndicator = {
    selectedByValuation1: boolean;
    selectedByValuation2: boolean;
};

type TComp = (RentalComparison & TValuationIndicator) & (SalesComparison & TValuationIndicator);

export type TCompsForModeration = Array<TComp>;

export type TSortOptions =
    | "monthlyRent"
    | "price"
    | "distance"
    | "totalBedrooms"
    | "floorArea"
    | "selectedBy"
    | "rentalDate"
    | "priceDate";

export type TSortOrder = "asc" | "desc";

const sortComps = (
    comps: Array<(RentalComparison & TValuationIndicator) | (SalesComparison & TValuationIndicator)>,
    sortBy: TSortOptions,
    sortOrder: TSortOrder = "asc"
) => {
    return comps.sort((comp1, comp2) => {
        if (comp1[sortBy] > comp2[sortBy]) {
            return sortOrder === "asc" ? 1 : -1;
        } else if (comp1[sortBy] < comp2[sortBy]) {
            return sortOrder === "asc" ? -1 : 1;
        } else {
            return 0;
        }
    });
};

export const useCompsForModeration = <T1, T2>(
    forListingID: string,
    compsType: "rental" | "sales",
    sortBy?: TSortOptions,
    sortOrder?: TSortOrder
): { dedupedComps: TCompsForModeration; allComps: TCompsForModeration } => {
    const selector =
        compsType === "rental"
            ? rentalComparisonSelectors.getRentalComparisonsByListingID
            : salesComparisonSelectors.getSalesComparisonsByListingID;
    const [dedupedComps, setDedupedComps] = useState<TCompsForModeration>([]);
    const [allComps, setAllComps] = useState<TCompsForModeration>([]);
    const compsFromStore: Array<TComp> = useSelector((state: AppState) => selector(state, forListingID));
    const dispatch = useDispatch();

    useEffect(() => {
        compsType === "rental"
            ? dispatch(fetchRentalComparisonsByListingID(forListingID))
            : dispatch(fetchSalesComparisonsByListingID(forListingID));
    }, [dispatch, compsType, forListingID]);

    useDeepCompareEffect(() => {
        const dedupeComps = () => {
            const comps = sortComps(compsFromStore, "selectedBy");
            let dedupedResult: TCompsForModeration = [];
            let currentOperator = comps[0].selectedBy;

            comps.forEach((comp) => {
                const duplicateIndex = getDuplicateIndex(comp, dedupedResult);
                const valuationNumber = currentOperator === comp.selectedBy ? 1 : 2;

                if (duplicateIndex !== null) {
                    dedupedResult[duplicateIndex].selectedByValuation2 = true;
                    dedupedResult[duplicateIndex].valuation2Notes = comp.notes;
                    dedupedResult[duplicateIndex].valuation2ConditionOverall = comp.conditionOverall;
                    dedupedResult[duplicateIndex].valuation2ConditionBathroom = comp.conditionBathroom;
                    dedupedResult[duplicateIndex].valuation2ConditionKitchen = comp.conditionKitchen;
                } else {
                    dedupedResult.push({
                        ...comp,
                        selectedByValuation1: valuationNumber === 1,
                        selectedByValuation2: valuationNumber === 2,
                    });
                }
            });

            return dedupedResult;
        };

        if (compsFromStore.length) {
            const dedupedResult = dedupeComps();
            setDedupedComps(dedupedResult);

            const allCompsResult = sortComps(compsFromStore, "selectedBy");
            setAllComps(allCompsResult);
        }
    }, [compsFromStore, forListingID]);

    useDeepCompareEffect(() => {
        if (dedupedComps.length && sortBy) {
            const sortedResults = sortComps(dedupedComps, sortBy, sortOrder);

            setDedupedComps([...sortedResults]);
        }

        if (allComps.length && sortBy) {
            const sortedResults = sortComps(allComps, sortBy, sortOrder);

            setAllComps([...sortedResults]);
        }
    }, [dedupedComps, allComps, sortBy, sortOrder]);

    return { dedupedComps, allComps };
};

const getDuplicateIndex = (comp: RentalComparison | SalesComparison, accumulatedComps: TCompsForModeration) => {
    for (let i = 0; i < accumulatedComps.length; i++) {
        if (comp.sourceID === accumulatedComps[i].sourceID) {
            return i;
        }
    }
    return null;
};
