import React, { Component } from "react";

import { HomeWelcome } from "./home-welcome";

export class Home extends Component {
    componentDidMount() {
        document.title = "Home | Peanut";
    }

    render() {
        return <HomeWelcome />;
    }
}
