import { object, string, number } from "yup";

const isEmpty = (val: string | null) => {
    return !val;
};

export const listingSearchFormValidationSchema = object().shape({
    launchArea: string(),
    searchType: string(),
    postcode: string().when("launchArea", {
        is: isEmpty,
        then: string().required("Postcode or Launch Area is required")
    }),
    radius: number()
        .typeError("Distance must be a number")
        .when("launchArea", {
            is: isEmpty,
            then: number()
                .typeError("Distance must be a number")
                .min(1, "Distance must be greater than or equal to 1")
                .required("Distance is required when searching by postcode")
        }),
    income1: number()
        .min(1, "Income 1 must be greater than or equal to 1")
        .typeError("Income 1 must be a number")
        .required("Income 1 is required"),
    income2: number()
        .when("numOfAdults", {
            is: 1,
            then: number().max(0, "Income 2 cannot be filled in when status is single")
        })
        .min(0, "Income 2 must be greater than or equal to 0")
        .typeError("Income 2 must be a number")
        .notRequired(),
    numOfAdults: number()
        .min(0, "Status is required")
        .required("Status is required"),
    deposit: number()
        .min(1, "Deposit must be greater than or equal to 1")
        .typeError("Deposit must be a number")
        .required("Deposit is required"),
    children0to1: number()
        .min(0, "Children aged 0 - 1 must be greater than or equal to 1")
        .typeError("Children aged 0 - 1 must be a number")
        .notRequired(),
    children2to4: number()
        .min(0, "Children aged 2 - 4 must be greater than or equal to 1")
        .typeError("Children aged 2 - 4 must be a number")
        .notRequired(),
    children5to11: number()
        .min(0, "Children aged 5 - 11 must be greater than or equal to 1")
        .typeError("Children aged 5 - 11 must be a number")
        .notRequired(),
    children12to16: number()
        .min(0, "Children aged 12 - 16 must be greater than or equal to 1")
        .typeError("Children aged 12 - 16 must be a number")
        .notRequired()
});
