export const fetchWithAuth = (url: string, options?: {}): ReturnType<typeof fetch> => {
    const authStr =
        localStorage.getItem(
            `oidc.user:${process.env.REACT_APP_OIDC_AUTHORITY}:${process.env.REACT_APP_OIDC_CLIENT_ID}`,
        ) || "";
    const auth = JSON.parse(authStr);
    const authOptions = {
        headers: { Authorization: `Bearer ${auth.access_token}` },
    };

    const fetchOptions = options
        ? {
              ...options,
              ...authOptions,
          }
        : authOptions;

    return fetch(url, fetchOptions);
};
