import { ListingStatusTypes } from "store/listing";

export interface StatusOrderType {
    [order: number]: {
        status: ListingStatusTypes;
        slug: string;
        displayName: string;
    };
}

export const statusOrder: StatusOrderType = {
    1: { status: "addressMatch", slug: "address-match", displayName: "Address Match" },
    2: { status: "basicAnalysis", slug: "basic-analysis", displayName: "Basic Analysis" },
    3: { status: "rentalValuation", slug: "rental-valuation", displayName: "Rental Val" },
    4: { status: "rentalValuationTwo", slug: "rental-valuation", displayName: "2nd Rental Val" },
    5: { status: "rentalModeration", slug: "rental-moderation", displayName: "Rental Mod" },
    6: { status: "salesValuation", slug: "sales-valuation", displayName: "Sales Val" },
    7: { status: "salesValuationTwo", slug: "sales-valuation", displayName: "2nd Sales Val" },
    8: { status: "salesModeration", slug: "sales-moderation", displayName: "Sales Mod" }
};
